import Chart from 'react-apexcharts';
import DashCard from '../../components/Institute Dashboard/DashCard';



const VisitSeparation = ({ovdBackets,ovdCount,ovdPercentage}) => {

  console.log(ovdPercentage,'ovdPercentage')

  const visitorData = ovdBackets.map((bucket, index) => ({
    id: index + 1, 
    title: bucket,
    subtitle: new Intl.NumberFormat('en-IN', { 
      style: 'currency', 
      currency: 'INR', 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0 
    }).format(ovdPercentage[index]), 
  }));


console.log(visitorData,'visitorData')


  const optionsvisitors = {
    labels:ovdBackets ,
    chart: {
      fontFamily: "'Montserrat', sans-serif",
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: 'transparent',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
        },
      },
    },
    stroke: {
      width: 1,
      colors: 'transparent',
    },
    legend: {
      show: false,
    },
    colors: ['#63aef7', '#66ddeb', '#f4f6f8', '#a38efb'],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };

  const seriesvisitors = ovdCount;
  return (
   <div
  title="Buckets"
  style={{
    overflowY: 'auto', // Corrected the casing
    maxHeight: '520px',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginBottom:'20px',
    backgroundColor:'white'
  }}
>
  <h4 class="text-muted">Buckets</h4>
  <div className="mt-4 position-relative" style={{ height: '200px'}}>
    <Chart options={optionsvisitors} series={seriesvisitors} type="donut" height="180px" />
  </div>
  <div className="mb-4" > {/* Corrected the casing here as well */}
    {visitorData.map((vdata) => (
      <div className="d-flex align-items-center border-bottom p-3" key={vdata.id}>
        <span class="text-muted">{vdata.title}</span>
        <div className="ms-auto">
          <span class="text-muted">{vdata.subtitle}</span>
        </div>
      </div>
    ))}
  </div>
  </div>
  );
};

export default VisitSeparation;
