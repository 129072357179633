import { Row, Col } from 'reactstrap';

import TopCardsData from './TopCardsData';
import icon1 from '../../assets/images/institute Dashboard Icons/income-w.png';
import icon2 from '../../assets/images/institute Dashboard Icons/expense-w.png';
import icon3 from '../../assets/images/institute Dashboard Icons/assets-w.png';
import icon4 from '../../assets/images/institute Dashboard Icons/staff-w.png';

const TopCards = ({instituteDetails,ovdCount,ovdPercentage},{id}) => {

  const ovdCountSum = ovdCount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const ovdPercentageSum = ovdPercentage.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  console.log(ovdPercentageSum,'hgfujbghj')

  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }

  return (
    <>
    {instituteDetails.length > 0 ?(
instituteDetails?.map((item) => 
(
  <Row key={item.id}> 
   
  <Col sm="6" lg="3">
  <div className="custom-col-wrapper" style={{ backgroundColor: " #3e7bf0 ", padding: "1rem", borderRadius:'8px',border:'1px solid #d4d6d5'}}>
  <div style={{ display: "flex", alignItems: "center" }}>
    <img src={icon1} alt="icon" style={{ marginRight: "10px" }} />
    <div>
    <p style={{ margin: 0, color: "#fff" }}>POS</p>
      <h4 style={{ margin: 0,color: "#fff" }}>
        {item?.Active_POS 
          ? <>₹{formatNumberWithCommas(Math.round(item?.Active_POS))}</>
          : '-'}
      </h4>
      
    </div>
  </div>
</div>
  </Col>

    <Col sm="6" lg="3">
    <div className="custom-col-wrapper" style={{ backgroundColor: " #40d89f", padding: "1rem", borderRadius: "8px",border:'1px solid #d4d6d5' }}>
  <div style={{ display: "flex", alignItems: "center" }}>
    <img src={icon2} alt="icon" style={{ marginRight: "10px" }} />
    <div>
    <p style={{ margin: 0, color: "#fff" }}>Total Loans</p>
      <h4 style={{ margin: 0 ,  color: "#fff" }}>
        {item?.loanidCount 
          ? <>{Math.round(item?.loanidCount)}</>
          : '-'}
      </h4>
      
    </div>
  </div>
</div>

    </Col>
    <Col sm="6" lg="3">
    <div className="custom-col-wrapper" style={{ backgroundColor: "#7857f0", padding: "1rem", borderRadius: "8px", color: "#fff",border:'1px solid #d4d6d5' }}>
  <div style={{ display: "flex", alignItems: "center" }}>
    <img src={icon3} alt="icon" style={{ marginRight: "10px" }} />
    <div>
    <p style={{ margin: 0 }}>Total OverdueAmount</p>
      <h4 style={{ margin: 0 }}>
        {item?.overdueAmount 
          ? <>₹{formatNumberWithCommas(Math.round(ovdPercentageSum))}</>
          : '-'}
      </h4>
     
    </div>
  </div>
</div>
    </Col>
    <Col sm="6" lg="3">
    <div className="custom-col-wrapper" style={{ backgroundColor: "#E7514C", padding: "1rem", borderRadius: "8px", color: "#fff",border:'1px solid #d4d6d5' }}>
  <div style={{ display: "flex", alignItems: "center" }}>
    <img src={icon4} alt="icon" style={{ marginRight: "10px" }} />
    <div>
    <p style={{ margin: 0 }}>Total OvdCount</p>
      <h4 style={{ margin: 0 }}>
        {item?.overduecount 
          ? <>{Math.round(ovdCountSum)}</>
          : '-'}
      </h4>
     
    </div>
  </div>
</div>
    </Col>
  </Row>
)
)
    ):null}
    </>
  
);
 
};

export default TopCards;
