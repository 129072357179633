import React  from 'react';
import { Routes, Route } from "react-router-dom"
import {Provider} from "react-redux"
import Login from "./components/login"
import {store} from "./helpers/store"
import PrivateRoutes from './PrivateRoutes';
import Dashboard from './components/dashboard';
import ProjectTables from './components/status/ProjectTable/ProjectTables';
import ProcessingTable from './components/status/ProcessingTable/ProcessingTable';
import Roles from './components/Roles/roles';
import SanctionTable from './components/status/SanctionTable/SanctionTable';
import RejectedTable  from './components/status/RejectedTable/RejectedTable';
import HoldTable from './components/status/OnHoldTable/OnHoldTable';
import DroppedTable from './components/status/DroppedTable/DroppedTable';
import DisbursedTable from './components/status/DisbursedTable/DisbursedTable';
import Activity from './components/Activity/Activity';
import ReadyToDisburseTable from './components/status/ReadyToDisburse/ReadyToDisburse';
import ForgotPassword from './components/ForgotPassword';
import Button from '@mui/material/Button';
import Analytics from './components/Analytics/Analytics';
import Modal from '@mui/material/Modal';
import ReviewTable from './components/status/ReviewTable/ReviewTable';
import SearchTable from './components/UserSearch/UserSearch';
import IndividualProfile from './components/IndividualProfile/IndividualProfile'
import ApplicationProfile from './components/status/ApplicationProfile';
import Bureau from './components/status/Bureau';
import Products from './components/Products/Products';
import PendingTable from './components/status/PendingTable/PendingTable'
import LoanTable from './components/Loans/Loans';
import EmiTable from './components/Emis/Emis';
import RepaymentTable from './components/Repayments/Repayments';
import LoanProfile from './components/status/LoanProfile/LoanProfile';
import {useNavigate,useLocation} from 'react-router-dom'
import Reports from './components/Reports/Reports';
import InstituteTables from './components/ManageInstitue/InstituteTables'
import Applications from './components/Applications/Applications';
import OverduetTable from './components/Overdue/Overdue';
import DemandReports from './components/DemandReport/DemandReport';
import Monthlydisbursements from './components/Monthlydisbursements/Monthlydisbursements';
import Portfoliocuts from './components/Portfoliocuts/Portfoliocuts';
import MonthlyPAR from './components/MonthlyPAR/MonthlyPAR';
import ActiveBank from './components/ActiveBank/ActiveBank';
import CollectionPerformance from './components/CollectionPerformance/CollectionPerformance';
import InstRepaymentTable from './components/InstRepayments/InstRepayments';
import InstEmiTable from './components/InstEmis/InsEmis';
import Closedloans from './components/Closedloans/Closedloans';
import Legalreport from './components/Legalreport/Legalreport';
import Mandatepausedreport from './components/Mandatepausedreport/Mandatepausedreport';
import MCIsearch from './components/MCIsearch/MCIsearch';
import PoolTagging from './components/PoolTagging/index';
import PoolTagedreport from './components/PoolTagedreport/PoolTagedreport';
import Demographical from './components/Institute Dashboard/Demographical';
//import ResendConsent from './components/status/ResendConsent';
// import { AuthContextProvider } from './AuthContext';
import './App.css'
import Rules from './components/Rules/Rules';
import HomePage from './components/Home/Home';



function App() {

  const navigate=useNavigate()
  const location=useLocation()
   let output = document.getElementById("App");
    const [expire,setExpire]=React.useState(false)
     let timer;
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];
// this function sets the timer that logs out the user after 10 secs
const handleLogoutTimer = () => {
  
  if(location.pathname!=="/")
  {

    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
       
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      sessionStorage.clear();
      navigate('/')
          setExpire(true)
    }, 14400000);  
  }
};

// this resets the timer if it exists.
const resetTimer = () => {
  if (timer) 
  {
    clearTimeout(timer);
    
  }
};
  

React.useEffect(() => {
  Object.values(events).forEach((item) => {
    window.addEventListener(item, () => {
      resetTimer();
      handleLogoutTimer();
      
    });
  });

  
}, []);
    

      const session=()=>{
              navigate('/')
              sessionStorage.clear();
              window.location.reload();
              setExpire(false)
      }

      
  return (
    // <AuthContextProvider>
    <Provider store={store}>
        <Modal style={{margin:'30%',marginTop:'20%',borderRadius:'10px'}} open={expire}>
          <div style={{backgroundColor:'#fff'}}>
            <p style={{ backgroundColor:'#F3f3f3',padding:'1em',textAlign:'center',fontWeight:'bold',borderBottom:'2px solid #d32028'}}>Session Expired</p>
            <div style={{padding:'1em'}}>
             <p style={{textAlign:'center',fontWeight:'bold'}}><span style={{fontSize:'18px',}}> Re-Login to access Dashboard</span><br/><small><i> Session Expires after every 4 hours</i></small></p>

              <Button style={{  marginLeft:'85%',border:'2px solid #D0D0D0'}} onClick={()=>session()}>OK</Button>
            </div>
          </div>
        </Modal>
    <div id="App" className="App" style={{height:"100%"}}>
    
        
     <Routes>
        
        <Route  element={<PrivateRoutes />}>
            {/* <Route path='/Home' element={<HomePage/>}/> */}
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/status/Submission" element={<ProjectTables/>} />
            <Route path="/status/Process" element={<ProcessingTable/>} />
            <Route path="/status/Sanction" element={<SanctionTable/>} />
            <Route path="/status/Rejected" element={<RejectedTable/>} />
            <Route path="/status/Hold" element={<HoldTable/>} />
            <Route path="/status/Dropped" element={<DroppedTable/>} />
            <Route path="/status/Disbursed" element={<DisbursedTable/>} />
            <Route path="/roles" element={<Roles/>} />
            <Route path="/rules" element={<Rules/>} />
            <Route path="/status/Disbursement" element={<ReadyToDisburseTable/>} />
            <Route path="/status/Pending" element={<PendingTable/>} />
            <Route path="/status/Review" element={<ReviewTable/>} />
            <Route path="/usersearch" element={<SearchTable/>} />
            <Route path="/applications" element={<Applications/>} />
            <Route path="/Loans" element={<LoanTable/>} />
            <Route path="/Institute" element={<InstituteTables/>} />
            <Route path="/Repayments" element={<RepaymentTable/>} />
            <Route path="/Repayment" element={<InstRepaymentTable/>} />
            <Route path="/Emis" element={<EmiTable/>} />
            <Route path="/Emi" element={<InstEmiTable/>} />
            <Route path="/Overdue" element={<OverduetTable/>} />
            <Route path="/Loanprofile" element={<LoanProfile/>} />
            <Route path="/reports" element={<Reports/>} />
            <Route path="/forgotPassword" element={<ForgotPassword/>} />
            <Route path="/analytics" element={<Analytics/>} />
            <Route path="/status/disbursed/profile" element={<IndividualProfile/>}/>
            <Route path="/status/disbursement/profile" element={<IndividualProfile/>}/>
            <Route path="/status/submission/profile" element={<IndividualProfile/>}/>
            <Route path="/status/Review/profile" element={<IndividualProfile/>}/>
            <Route path="/status/Process/profile" element={<IndividualProfile/>}/>
            <Route path="/status/Pending/profile" element={<IndividualProfile/>}/>
            <Route path="/status/onHold/profile" element={<IndividualProfile/>}/>
            <Route path="/status/dropped/profile" element={<IndividualProfile/>}/>
            <Route path="/status/rejected/profile" element={<IndividualProfile/>}/>
            <Route path="/status/sanction/profile" element={<IndividualProfile/>}/>
            <Route path="/status/disbursed/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/disbursement/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/submission/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/Review/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/Process/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/Pending/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/onHold/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/dropped/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/rejected/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/status/sanction/appProfile" element={<ApplicationProfile/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/DemandReport" element={<DemandReports/>}/>
            <Route path="/Monthlydisbursements" element={<Monthlydisbursements/>}/>
            <Route path="/Portfoliocuts" element={<Portfoliocuts/>}/>
            <Route path="/MonthlyPAR" element={<MonthlyPAR/>}/>
            <Route path="/Activebanks" element={<ActiveBank/>}/>
            <Route path="/CollectionPerformance" element={<CollectionPerformance/>}/>
            <Route path="/Closedloans" element={<Closedloans/>}/>
            <Route path="/Legalreport" element={<Legalreport/>}/>
            <Route path="/Mandatepausedreport" element={<Mandatepausedreport/>}/>
            <Route path="/MCIsearch" element={<MCIsearch/>}/>
            <Route path="/PoolTagging" element={<PoolTagging/>}/>
            <Route path="/PoolTagedreport" element={<PoolTagedreport/>}/>
            <Route path="/status/profile/activity" element={<Activity/>}/>
            <Route path="/Demographical" element={<Demographical/>}/>
            

        </Route>
      
        <Route path="/" element={ <Login /> }  />
        <Route path="/bureau" element={ <Bureau /> }  />
  
  </Routes>
  </div>
 {/* </AuthContextProvider> */}
  </Provider>
  );
}

export default App;
