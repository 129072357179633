import { ButtonGroup, Button } from 'reactstrap';
import Chart from 'react-apexcharts';
import DashCard from '../../components/Institute Dashboard/Graphcard';
import { Background } from 'react-flow-renderer';

const WebsiteVisit = ({applCount,applmonth,applDisb,applRate}) => {

  const sanitizedMonths = applmonth.map((item) => {
    if (!item || typeof item !== 'string' || !item.includes('-')) {
        return "NullMonth";
    }
    return item.split('-')[1];
});

const applRateSafe = Array.isArray(applRate) ? applRate : [];


console.log(sanitizedMonths,"sanitizedMonths")

  const optionsnewslatter = {
    chart: {
      fontFamily: "'Montserrat', sans-serif",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: 'rgba(0, 0, 0, .2)',
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [3, 0], // Markers visible for Applications only
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ['#06d79c', '#398bf7'],
    fill: {
      type: 'gradient',
      opacity: ['0.1', '0.1'],
    },
    xaxis: {
      categories: sanitizedMonths,
      labels: {
        show: true,
        style: {
          colors: '#99abb4',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: '#99abb4',
          fontSize: '12px',
        },
      },
    },
    markers: {
      size: 3,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      theme: 'dark',
    },
    legend: {
      show: false,
    },
  };






  const seriesnewslatter = [
    {
      name: 'Applications',
      data: applCount,
    },
    {
      name: 'Disburements',
      data: applDisb,
    },

  
    
  ];
  return (
    <DashCard
      title="Applications Visits"
      style={{ marginTop: '200px !important', border: '1px solid red' ,backgroundColor: 'red',}}
      actions={
        <div className="hstack gap-2">
          <div className="d-flex align-items-center text-success fs-6">
            <i className="bi bi-circle-fill fs-7 me-2"></i>Total Application
          </div>
          <div className="d-flex align-items-center text-info fs-6">
            <i className="bi bi-circle-fill fs-7 me-2 "></i>Disburements
          </div>
        </div>
      }
    >
      

      <div style={{ height: '280px',marginTop:'10px' }}>
        <Chart options={optionsnewslatter} series={seriesnewslatter} type="area" height="280px" />
      </div>
    </DashCard>
  );
};

export default WebsiteVisit;
