import { userConstants } from "./../constants/user.constants";
import {userService} from "../services/user.services";


export const userActions = {
  
    login,
    loginOtp,
    sendOtp,
    fetchInprogressUsers,
    fetchInsubmissionUsers,
    fetchIndisbursedUsers,
    fetchInpendingdisbursment,
    fetchInsanction,
    fetchInrejected,
    fetchInreview,
    fetchDropped,
    fetchReadytodisburse,
    fetchOnholdusers,
    fetchDatefilter,
    fetchHandleuser,
    
    fetchHandlcoappuser,
    fetchEditformdetails,
    fetchDisbursedstatuschange,
    fetchDroppedstatuschange,
    fetchUpdateStatusUrl,
    fetchReviewstatuschange,
    updateEditformdetails,
    handleApproveFormdetails,
    fetchhandleApproveapi,
    fetchColenderFundSource,
    fetchfundcode,
    fetchUrlapproveapifund,
    getAadharfront,
    getAadharback,
    getPandetails,
    addCoApplicant,
    fetchUrlforbankselfie,
    fetchUrlforApplicationdetails,
    fetchEmiUrl,
    getBankSelfieDetails,
    getCkycDetails,
    getDigiLocker,
    getPanStatus,
    getPanProStatus,
    getProfileDetails,
    getUploadedFiles,
    getCibilDetails,
    getActivity,
    submitActivity,
    fetchSoftPullDetails,
    fetchLoans,
    fetchRepayments,

    postIncomePdf,
    getIncomeProfileAnalytics,
    postFoirDetails,
    getFoirDetails,
    getInstituteInfo,
    createProduct,
    getProductList,
    updatingBankDetails,
    updateBankdetails,
    updateInstitutedetails,
    callBankdetails,
    callbankdetails,
    callRegdetails,
    createInstitute,
    userSearch,
    userSearchdefault,
    userSearchInst,
    fetchLoanDetails,
    fetchLoansCount,
    fetchCallDetails,
    generateManualPaymentReceipt,
    getNotifications,
    createReminder,
    getEmiHistory,
    uploadFile,
    getReminder,
    reportdetails,

    fetchInstitutes,
    deleteReminder,
    fetchProducts,
    fetchfiltereddata,
    createApplication,
    createBijayApplication,
    openCustomerDashboard
    
  };
  

  function login(emailId) {

    return (dispatch) => {
        dispatch(request({ emailId }))

        userService.login(emailId).then(
            (user) => {

              console.log(user, "inside login function")
              dispatch(success(user));
              
            //   history.push("/");
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
        )
    }

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
      }
      function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
      }
      function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
      }
}
  function loginOtp(email, otp) {

    return (dispatch) => {
        dispatch(request({ email }))

        userService.loginOtp(email, otp).then(
            (user) => {

              console.log(user, "inside login with otp function")
              dispatch(success(user));
              
            //   history.push("/");
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
        )
    }

    function request(user) {
        return { type: userConstants.LOGIN_OTP_REQUEST, user };
      }
      function success(user) {
        return { type: userConstants.LOGIN_OTP_SUCCESS, user };
      }
      function failure(error) {
        return { type: userConstants.LOGIN_OTP_FAILURE, error };
      }
}
  function sendOtp(mobile) {

    return (dispatch) => {
        dispatch(request({ mobile }))

        userService.sendOtp(mobile).then(
            (user) => {

              console.log(user, "inside login with otp function")
              dispatch(success(user));
              
            //   history.push("/");
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
        )
    }

    function request(user) {
        return { type: userConstants.SEND_OTP_REQUEST, user };
      }
      function success(user) {
        return { type: userConstants.SEND_OTP_SUCCESS, user };
      }
      function failure(error) {
        return { type: userConstants.SEND_OTP_FAILURE, error };
      }
}

function fetchInprogressUsers(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInprogressUsers(url, user).then(
          (res) => {
            dispatch(success(res));

          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.INPROGRESS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INPROGRESS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INPROGRESS_FAILURE, error };
    }
}

function fetchInsubmissionUsers(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInsubmissionUsers(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.INSUBMISSION_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INSUBMISSION_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INSUBMISSION_FAILURE, error };
    }
}

function fetchIndisbursedUsers(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchIndisbursedUsers(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.INDISBURSED_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INDISBURSED_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INDISBURSED_FAILURE, error };
    }
}


function fetchInpendingdisbursment(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInpendingdisbursment(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.PENDINGINDISBURSMENT_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.PENDINGINDISBURSMENT_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.PENDINGINDISBURSMENT_FAILURE, error };
    }
}

function fetchInsanction(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInsanction(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.INSANCTION_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INSANCTION_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INSANCTION_FAILURE, error };
    }
}

function fetchInrejected(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInrejected(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.INREJECTED_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INREJECTED_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INREJECTED_FAILURE, error };
    }
}

function fetchInreview(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInreview(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.INREVIEW_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INREVIEW_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INREVIEW_FAILURE, error };
    }
}

function fetchDropped(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchDropped(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.DROPPED_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.DROPPED_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.DROPPED_FAILURE, error };
    }
}

function fetchReadytodisburse(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchReadytodisburse(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.READYTODISBURSE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.READYTODISBURSE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.READYTODISBURSE_FAILURE, error };
    }
}

function fetchOnholdusers(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchOnholdusers(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.ONHOLD_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.ONHOLD_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.ONHOLD_FAILURE, error };
    }
}

function fetchDatefilter(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchDatefilter(url, user).then(
          (res) => {
             
            dispatch(success(res));
      //       setTableData(datestate.applicantDetails);
      //   setFilterTableData(datestate.applicantDetails);
      //   setcurrentPage(datestate.pagination.currentPage);

      //   setTotalFetchRows(datestate.pagination.totalRows);
      // setrowsPerPage(datestate.pagination.perPage)
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.DATEFILTER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.DATEFILTER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.DATEFILTER_FAILURE, error };
    }
}
function fetchHandleuser(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchHandleuser(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.HANDLEUSER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.HANDLEUSER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.HANDLEUSER_FAILURE, error };
    }
}

function fetchHandlcoappuser(url , user,navigate,Id,applId) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchHandlcoappuser(url, user).then(
          (res) => {
         
            dispatch(success(res));
            
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.HANDLECOAPPUSER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.HANDLECOAPPUSER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.HANDLECOAPPUSER_FAILURE, error };
    }
}



function fetchEditformdetails(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchEditformdetails(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.EDITFORMDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.EDITFORMDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.EDITFORMDETAILS_FAILURE, error };
    }
}

function fetchDisbursedstatuschange(url ,data, user) {
  

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchDisbursedstatuschange(url,data, user).then(
          (res) => {
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.DISBURSERDSTATUSCHANGE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.DISBURSERDSTATUSCHANGE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.DISBURSERDSTATUSCHANGE_FAILURE, error };
    }
}
function fetchDroppedstatuschange(url ,data, user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchDroppedstatuschange(url,data, user).then(
          (res) => {
            console.log("result in action",res)
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.DROPPEDSTATUSCHANGE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.DROPPEDSTATUSCHANGE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.DROPPEDSTATUSCHANGE_FAILURE, error };
    }
}

function fetchUpdateStatusUrl(url ,data, user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchUpdateStatusUrl(url,data, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPDATESTATUSCHANGE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPDATESTATUSCHANGE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPDATESTATUSCHANGE_FAILURE, error };
    }
}

function fetchReviewstatuschange(url ,data, user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchReviewstatuschange(url,data, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.REVIEWSTATUSCHANGE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.REVIEWSTATUSCHANGE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.REVIEWSTATUSCHANGE_FAILURE, error };
    }
}

function updateEditformdetails(url ,data, user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.updateEditformdetails(url,data, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPDATEEDITFORM_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPDATEEDITFORM_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPDATEEDITFORM_FAILURE, error };
    }
}

function handleApproveFormdetails(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.handleApproveFormdetails(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.HANDLEAPPROVEFORM_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.HANDLEAPPROVEFORM_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.HANDLEAPPROVEFORM_FAILURE, error };
    }
}

function fetchhandleApproveapi(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchhandleApproveapi(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.HANDLEAPPROVEAPI_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.HANDLEAPPROVEAPI_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.HANDLEAPPROVEAPI_FAILURE, error };
    }
}

function fetchColenderFundSource(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchColenderFundSource(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.HANDLECOLENDERFUND_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.HANDLECOLENDERFUND_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.HANDLECOLENDERFUND_FAILURE, error };
    }
}

function fetchfundcode(url,user){
  return (dispatch) => {
    dispatch(request({ user }))

    userService.fetchfundcode(url, user).then(
        (res) => {
          
          dispatch(success(res));
          
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
    )
}

function request(user) {
    return { type: userConstants.FUNDCODE_REQUEST, user };
  }
  function success(res) {
    return { type: userConstants.FUNDCODE_SUCCESS, res };
  }
  function failure(error) {
    return { type: userConstants.FUNDCODE_FAILURE, error };
  }
}

function fetchUrlapproveapifund(url,user){
  return (dispatch) => {
    dispatch(request({ user }))

    userService.fetchUrlapproveapifund(url, user).then(
        (res) => {
          
          dispatch(success(res));
          
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
    )
}

function request(user) {
    return { type: userConstants.HANDLEAPPROVEAPIFUND_REQUEST, user };
  }
  function success(res) {
    return { type: userConstants.HANDLEAPPROVEAPIFUND_SUCCESS, res };
  }
  function failure(error) {
    return { type: userConstants.HANDLEAPPROVEAPIFUND_FAILURE, error };
  }
}

function  getAadharfront(url,data,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.getAadharfront(url, data).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.GETAADHARFRONT_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.GETAADHARFRONT_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.GETAADHARFRONT_FAILURE,error };
  }
};

function  getAadharback(url,data,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.getAadharback(url, data).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.GETAADHARBACK_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.GETAADHARBACK_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.GETAADHARBACK_FAILURE,error };
  }
};

function  getPandetails(url,data,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.getPandetails(url, data).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.PAN_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.PAN_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.PAN_FAILURE,error };
  }
};

function  addCoApplicant(url,data,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.addCoApplicant(url, data,user).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.ADDCOAPPLICANT_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.ADDCOAPPLICANT_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.ADDCOAPPLICANT_FAILURE,error };
  }
};

function  fetchUrlforbankselfie(url,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.fetchUrlforbankselfie(url,user).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.BANKSELFIE_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.BANKSELFIE_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.BANKSELFIE_FAILURE,error };
  }
};


function  fetchUrlforApplicationdetails(url,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.fetchUrlforApplicationdetails(url,user).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.APPLICATIONDETAILS_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.APPLICATIONDETAILS_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.APPLICATIONDETAILS_FAILURE,error };
  }
};

function  fetchEmiUrl(url,user){
  
  return (dispatch) => {
    dispatch(request(user));

    userService.fetchEmiUrl(url,user).then(
      (res) => {
       
        dispatch(success(res))
      },   
      (error) => {
        dispatch(failure(error.toString()));
      }
    
  )
}
  function request(user) {
    return { type: userConstants.EMIURL_REQUEST,user };
  }

  function success(res) {
    return { type: userConstants.EMIURL_SUCCESS,res };
  }

  function failure(error) {
    return { type: userConstants.EMIURL_FAILURE,error };
  }
};

//individual profile apis 
function getCkycDetails(url , user) {

  return (dispatch) => {
      dispatch(requestCkyc({ user }))

      userService.getCkycDetails(url, user).then(
          (res) => {
            
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error)=>{
            dispatch(failure(error.toString()));
          }
      )
        }
         function requestCkyc(user) {
      return { type: userConstants.CKYC_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CKYC_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CKYC_FAILURE, error };
    }

  }

  

 
function getDigiLocker(url , user) {

   return (dispatch) => {
      dispatch(request({ user }))

      userService.getDigiLocker(url, user).then(
              (res) => {
                
                dispatch(success(res));
                // navigate('/dashboard')
              //   history.push("/");
              },
              (error) => {
                dispatch(failure(error.toString()));
              }
          )
   }
  function request(user) {
      return { type: userConstants.DIGILOCKER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.DIGILOCKER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.DIGILOCKER_FAILURE, error };
    }
 
}

function getPanStatus(url , user) {

  return (dispatch) => {

      userService.getPanStatus(url, user).then(
          (res) => {
            
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error) => {
                dispatch(failure(error.toString()));
              }
          )
  }

  function request(user) {
      return { type: userConstants.PAN_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.PAN_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.PAN_FAILURE, error };
    }
}
function getPanProStatus(url , data,user) {

  return (dispatch) => {

      userService.getPanProStatus(url,data, user).then(
          (res) => {
            
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          console.log("prooooo",res)

          },
          (error) => {
                dispatch(failure(error.toString()));
              }
          )
  }

  function request(user) {
      return { type: userConstants.PANPRO_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.PANPRO_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.PANPRO_FAILURE, error };
    }
}

function getBankSelfieDetails(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.getBankSelfieDetails(url, user).then(
          (res) => {
          
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.BANK_SELFIE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.BANK_SELFIE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.BANK_SELFIE_FAILURE, error };
    }
}


function getProfileDetails(url,user) {
console.log("GOT Profile Details")
  return (dispatch) => {
     
      dispatch(request({ user }))

      userService.getProfileDetails(url,user).then(
          (res) => {
          
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.PROFILE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.PROFILE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.PROFILE_FAILURE, error };
    }
}



function getUploadedFiles(url,user) {
console.log("GOT cibil Profile Details")
  return (dispatch) => {
      dispatch(request({ user }))
     

      userService.getUploadedFiles(url,user).then(
          (res) => {
          
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPLOADEDFILES_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPLOADEDFILES_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPLOADEDFILES_FAILURE, error };
    }
}


function getCibilDetails(url) {
console.log("GOT Profile Details")
  return (dispatch) => {
     
     

      userService.getCibilDetails(url).then(
          (res) => {
          
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.CIBIL_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CIBIL_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CIBIL_FAILURE, error };
    }
}
function fetchSoftPullDetails(url) {
  console.log("GOT Profile Details")
    return (dispatch) => {
       
       
  
        userService.fetchSoftPullDetails(url).then(
            (res) => {
            
              dispatch(success(res));
              // navigate('/dashboard')
            //   history.push("/");
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
        )
    }
  
    function request(user) {
        return { type: userConstants.CIBILSOFT_REQUEST, user };
      }
      function success(res) {
        return { type: userConstants.CIBILSOFT_SUCCESS, res };
      }
      function failure(error) {
        return { type: userConstants.CIBILSOFT_FAILURE, error };
      }
  }

function getActivity(url) {
console.log("GOT Activity Details")
  return (dispatch) => {
     
     

      userService.getActivity(url).then(
          (res) => {
          
            dispatch(success(res));
            // navigate('/dashboard')
          //   history.push("/");
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.ACTIVITY_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.ACTIVITY_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.ACTIVITY_FAILURE, error };
    }
}

      function submitActivity(url,body,user) {
        
      console.log("Post Activity Details")
        return (dispatch) => {
          dispatch(request({user}))
            
          userService.submitActivity(url,body,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString()));
          })
          
        
        }

  function request(user) {
      return { type: userConstants.ACTIVITY_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.ACTIVITY_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.ACTIVITY_FAILURE, error };
    }
}


function fetchLoans(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchLoans(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.LOANS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.LOANS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.LOANS_FAILURE, error };
    }
}
function fetchRepayments(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchRepayments(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.REPAYMENTS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.REPAYMENTS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.REPAYMENTS_FAILURE, error };
    }
}


function postIncomePdf(url,body,user){


   console.log("Post INCOME PROFILE Details")
        return (dispatch) => {
          dispatch(request({user}))
            
          userService.postIncomePdf(url,body,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.POST_INCOMEPROFILE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.POST_INCOMEPROFILE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.POST_INCOMEPROFILE_FAILURE, error };
    }

}
function getIncomeProfileAnalytics(url,user){


   console.log("get INCOME PROFILE Details")
        return (dispatch) => {
          dispatch(request({user}))
            
          userService.getIncomeProfileAnalytics(url,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.GET_INCOMEPROFILE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_INCOMEPROFILE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_INCOMEPROFILE_FAILURE, error };
    }

}
function postFoirDetails(url,body,user){


   console.log("get INCOME PROFILE Details")
        return (dispatch) => {
          dispatch(request({user}))
            
          userService.postFoirDetails(url,body,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.POSTFOIR_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.POSTFOIR_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.POSTFOIR_FAILURE, error };
    }

}
function getFoirDetails(url,user){


   console.log("get INCOME PROFILE Details")
        return (dispatch) => {
          dispatch(request({user}))
            
          userService.getFoirDetails(url,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.GETFOIR_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GETFOIR_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GETFOIR_FAILURE, error };
    }

}

function getInstituteInfo(url,user){


   console.log("get institute Details")
        return (dispatch) => {
          dispatch(request({user}))
            
          userService.getInstituteInfo(url,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.GET_INSTITUTE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_INSTITUTE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_INSTITUTE_FAILURE, error };
    }

}
function createProduct(url,body){


   console.log("post product Details")
        return (dispatch) => {
          
            
          userService.createProduct(url,body)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.POST_INSTITUTE_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.POST_INSTITUTE_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.POST_INSTITUTE_FAILURE, error };
    }

}
function getProductList(url,user){


   console.log("get product Details")
        return (dispatch) => {
          
            
          userService.getProductList(url,user)
          .then((res)=>{
            dispatch(success(res));
          })
          .catch((err)=>{
          dispatch(failure(err.toString())); 
          })
          
        
        }


   function request(user) {
      return { type: userConstants.GET_PRODUCTS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_PRODUCTS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_PRODUCTS_FAILURE, error };
    }

}
function updatingBankDetails(url,obj4,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.updatingBankDetails(url, obj4,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPDATEBANKDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPDATEBANKDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPDATEBANKDETAILS_FAILURE, error };
    }
}
function updateBankdetails(url,obj3,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.updateBankdetails(url, obj3,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPDATINGBANKDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPDATINGBANKDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPDATINGBANKDETAILS_FAILURE, error };
    }
}
function updateInstitutedetails(updateInstituteDetailsUrl,updateObj,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.updateInstitutedetails(updateInstituteDetailsUrl, updateObj,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPDATEINSTITUTEDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPDATEINSTITUTEDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPDATEINSTITUTEDETAILS_FAILURE, error };
    }
}
function callBankdetails(saveSqlBankDetailsUrl,obj4,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.callBankdetails(saveSqlBankDetailsUrl, obj4,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.CALLBANKDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CALLBANKDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CALLBANKDETAILS_FAILURE, error };
    }
}
function callbankdetails(saveSqlBankDetailsUrl,obj4,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.callbankdetails(saveSqlBankDetailsUrl, obj4,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.CALLINGBANKDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CALLINGBANKDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CALLINGBANKDETAILS_FAILURE, error };
    }
}

function callRegdetails(b2bsignupURL,obj,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.callRegdetails(b2bsignupURL, obj,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.CALLREG_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CALLREG_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CALLREG_FAILURE, error };
    }
}
function createInstitute(createUrl,obj2,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.createInstitute(createUrl, obj2,user).then(
          (res) => {
            
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.CREATEINST_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CREATEINST_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CREATEINST_FAILURE, error };
    }
}
function userSearch(sanctionUrl,navigate,path,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.userSearch(sanctionUrl,navigate,path,user).then(
          (res) => {
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.USERSEARCH_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.USERSEARCH_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.USERSEARCH_FAILURE, error };
    }
}

function userSearchdefault(getAppUrl,status,navigate,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.userSearchdefault(getAppUrl,status,navigate,user).then(
          (res) => {
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.USERSEARCHDEF_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.USERSEARCHDEF_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.USERSEARCHDEF_FAILURE, error };
    }
}
function userSearchInst(urlUsers,status,navigate,user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.userSearchInst(urlUsers,status,navigate,user).then(
          (res) => {
            dispatch(success(res));
           
          },
          (error) => {
            dispatch(failure(error.toString()));
            
          }
      )
  }

  function request(user) {
      return { type: userConstants.USERSEARCHINS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.USERSEARCHINS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.USERSEARCHINS_FAILURE, error };
    }
}
function fetchLoanDetails(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchLoanDetails(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.LOANDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.LOANDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.LOANDETAILS_FAILURE, error };
    }
}
function fetchLoansCount(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchLoansCount(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.LOANCOUNT_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.LOANCOUNT_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.LOANCOUNT_FAILURE, error };
    }
}

function fetchCallDetails(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchCallDetails(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.CALLDETAILS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CALLDETAILS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CALLDETAILS_FAILURE, error };
    }
}

function getNotifications(url ) {

  return (dispatch) => {
 

      userService.getNotifications(url).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.GET_NOTIFICATIONS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_NOTIFICATIONS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_NOTIFICATIONS_FAILURE, error };
    }
}
function generateManualPaymentReceipt(url ,user,body) {

  return (dispatch) => {
      

      userService.generateManualPaymentReceipt(url,user, body).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.POST_GENERATE_RECEIPT_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.POST_GENERATE_RECEIPT_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.POST_GENERATE_RECEIPT_FAILURE, error };
    }
}
function createReminder(url ,body,user) {

  return (dispatch) => {
      
    dispatch(request({user}))
      userService.createReminder(url, body,user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.POST_REMINDER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.POST_REMINDER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.POST_REMINDER_FAILURE, error };
    }
}
function getEmiHistory(url ,user) {

  return (dispatch) => {
      
    dispatch(request({user}))
      userService.getEmiHistory(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.GET_EMIHISTORY_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_EMIHISTORY_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_EMIHISTORY_FAILURE, error };
    }
}
function uploadFile(url ,body) {

  return (dispatch) => {
      
   
      userService.uploadFile(url,body).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.UPLOAD_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.UPLOAD_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.UPLOAD_FAILURE, error };
    }
}


function getReminder(url ) {

  return (dispatch) => {
      
   
      userService.getReminder(url).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.GET_REMINDER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_REMINDER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_REMINDER_FAILURE, error };
    }
}
function reportdetails(urlUsers,data, user ) {

  return (dispatch) => {
      
   
      userService.reportdetails(urlUsers,data, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.GET_REPORTS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.GET_REPORTS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.GET_REPORTS_FAILURE, error };
    }
}

function fetchInstitutes(getinst , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchInstitutes(getinst, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {

      return { type: userConstants.INSTITUTES_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.INSTITUTES_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.INSTITUTES_FAILURE, error };
    }
}

function deleteReminder(url,data, user ) {

  return (dispatch) => {
      
   
      userService.deleteReminder(url,data, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.DELETE_REMINDER_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.DELETE_REMINDER_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.DELETE_REMINDER_FAILURE, error };
    }
}
 
function fetchProducts(getinst , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchProducts(getinst, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {

      return { type: userConstants.PRODUCTS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.PRODUCTS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.PRODUCTS_FAILURE, error };
    }
}

function fetchfiltereddata(url , user) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.fetchfiltereddata(url, user).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.FILTEREDUSERS_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.FILTEREDUSERS_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.FILTEREDUSERS_FAILURE, error };
    }
}
function createApplication(url ,body, user,arr) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.createApplication(url,body, user,arr).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.CREATE_APPLICATION_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CREATE_APPLICATION_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CREATE_APPLICATION_FAILURE, error };
    }
}


function createBijayApplication(url ,body, user,arr) {

  return (dispatch) => {
      dispatch(request({ user }))

      userService.createBijayApplication(url,body, user,arr).then(
          (res) => {
            
            dispatch(success(res));
            
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
      )
  }

  function request(user) {
      return { type: userConstants.CREATE_BIJAY_APPLICATION_REQUEST, user };
    }
    function success(res) {
      return { type: userConstants.CREATE_BIJAY_APPLICATION_SUCCESS, res };
    }
    function failure(error) {
      return { type: userConstants.CREATE_BIJAY_APPLICATION_FAILURE, error };
    }
}

function openCustomerDashboard(url, user, mob) {
  console.log("get Customer Dashboard Details");
  return (dispatch) => {
    dispatch(request({ user }));

    userService
      .getCustomerDashboard(url, user, mob)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failure(err.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.OPEN_CUSTOMER_DASHBOARD_REQUEST, user };
  }

  function success(res) {
    window.open(
      `https://feemonk.com/login/admin?mob=${res.mobile}&data=${res.data}`
    );
    return { type: userConstants.OPEN_CUSTOMER_DASHBOARD_SUCCESS, res };
  }

  function failure(error) {
    return { type: userConstants.OPEN_CUSTOMER_DASHBOARD_FAILURE, error };
  }
}