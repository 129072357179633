import React,{useRef,useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {userActions} from '../../../actions/user.actions'
import 'moment-timezone';
import moment from 'moment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
 import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import createpayment from '../../../assets/images/createmanual.png'
import file_upload from '../../../assets/images/file_upload.png'
import './index.css'
import close from "../../../assets/images/close.png"
import Datetime from 'react-datetime'; 
import {Input} from 'reactstrap'

const style = {
   
   
  justifyContent: 'center',
  width: 500,
  bgcolor: 'background.paper',
  height:'auto',
  overflowY: 'scroll',
  boxShadow: 20,
  borderRadius:2,
  marginTop:'2em',
  marginBottom:'2em'
   
  
};



export default function ManualPayment({totalEmiAmount,selectedItems,chequeBounceCharges,overdueCharges,remainingInterest,userId}) {
  const [open, setOpen] = React.useState(false);
  const user=sessionStorage.getItem('user')
  const uploadState=useSelector((state)=>state?.uploadFile?.res?.data?.data)
  
  React.useEffect(() => {
    
    if(uploadState)
    {
      setProof(uploadState?.s3Url)
    }
  
    
  }, [uploadState])
  
  const handleOpenDialog=()=>{
      setOpen(true)
      
      
  }
  
    const dispatch=useDispatch();

  const uploadRef=useRef()
  const openProofUpload=()=>{
  uploadRef.current.click()
  
}

const [valueRadio, setValueRadio] = React.useState('A/C Transfer');

  const handleChange = (event) => {
    setValueRadio(event.target.value);
  };
const [proof,setProof]=React.useState(null)

const handleProofUpload=(event)=>{
   
 const body=new FormData();
 body.append("file",event.target.files[0]);
 body.append("type",'transaction_proof');
     
  
  const urlUpload='/file/upload'
  
  dispatch(userActions.uploadFile(urlUpload,body))
  


}

 
const [transactionId,setTransactionId]=React.useState(null)
const [receivedAmount,setReceivedAmount]=React.useState(0)
const [adjustmentAmount,setAdjustmentAmount]=React.useState(null)
const [selectedDate,setSelectedDate]=React.useState(null)
const [reasonCode,setReasonCode]=React.useState("")

const handleClose = () => {
  setOpen(false);
  setReceivedAmount(0);
  setAdjustmentAmount(null);
  setTransactionId(null);
  setSelectedDate(null);
  setReasonCode("");
  setValueRadio('A/C Transfer');
  setProof(null);
};
    const formattedDate = selectedDate?.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
var date = new Date(formattedDate);
var year = date.getFullYear();
var month = ('0' + (date.getMonth() + 1)).slice(-2); 
var day = ('0' + date.getDate()).slice(-2);

var date = year + '-' + month + '-' + day;
console.log("data",date)

const generateReceipt=()=>{
  const urlGenerateReceipt='/loan-repayment/manual-repayment'
  const body={
    userId:userId,
    loanId: sessionStorage.getItem('loanid'),
    legacyLoanId: sessionStorage.getItem('legacyLoanId'),
    transactionId: transactionId?.trim(),
    // transactionProofUrl: proof,
    repaymentId: "string",
    paymentAmount: Number(receivedAmount),
    paymentMode: valueRadio==="A/C Transfer"?3:valueRadio==="UPI"?2:1,
    paidAt: date ,
    manualPaymentReason:Number(reasonCode),
    discountAmount: Number(adjustmentAmount),  
    // overdueCharges: 0,
    // checkBounceCharges: valueRadio==="Cheque"?chequeBounceCharges:0
  }
  console.log("dataaaaaaaaaa",body)
  dispatch(userActions.generateManualPaymentReceipt(urlGenerateReceipt,user,body))
}

function formatNumberWithCommas(number) {
  return number?.toLocaleString('en-IN'); 
}
const [error, setError] = useState("");

const maxDiscount = parseFloat(remainingInterest) + parseFloat(overdueCharges) + parseFloat(chequeBounceCharges);

const handleInputChange = (e) => {
  const value = e.target.value;
  setAdjustmentAmount(value);

  if (parseFloat(value) > maxDiscount) {
    setError(`Discount amount should not exceed ${formatNumberWithCommas(maxDiscount)}.`);
  } else {
    setError(""); 
  }
};

// const isSpanClickable = selectedItems.length>=1;
  return (
    <>
         <span onClick={() => handleOpenDialog()} style={{cursor: 'pointer',border:'1px solid #D0D0D0',borderRadius: '10px', paddingRight:'10px',boxShadow:'0px 0px 1px 1px #F0F0F0',fontWeight:'bold',color:'#344054'}}>
           <img src={createpayment}  style={{paddingLeft:'15px'}}/> Create Manual Payment
        </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display:'flex',justifyContent:'center'}}
      >
        <Box sx={style}>
          <Typography style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px',backgroundColor:'#F0F0F0' ,borderBottom:'1px solid #D0D0D0'}}      >
           <p style={{ textAlign:'center',fontSize:'20px',paddingTop:'10px',fontWeight:'bold'}}> Manual Payment Update <img src={close} style={{marginLeft:'35%',cursor:'pointer'}} onClick={() => {setOpen(!open)}}/></p>
          
          </Typography>
         <div style={{padding:'1em'}}>
          <div style={{display:'block'}}>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginBottom:'-1px'}}>Total Remaining Amount To Be Paid</p>
          <input disabled value={formatNumberWithCommas(totalEmiAmount)} type="text" style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'5px',padding:'8px',height:'3em'}}  placeholder="&#8377;"/>
          </div>
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <div style={{display:'block' ,marginTop:'15px'}}>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginBottom:'-1px'}}>Category</p>
          <select onChange={(e)=>setReasonCode(e.target.value)} value={reasonCode}  style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'5px',padding:'8px',height:'3em'}}>
            <option value="" disabled>Select</option>
            <option value="1">Preclosure</option>
            <option value="2">Overdue</option>
            <option value="3">Single EMI payment</option>
            <option value="4">Multiple EMIs</option>
            <option value="5">Settlement</option>
            <option value="6">Refund By Institute</option>
          </select>
          </div>
          <div style={{display:'block',marginTop:'15px'}}>
          <p  style={{fontFamily:'Inter-Medium',fontSize:'14px',marginBottom:'-1px'}}>Paid Amount</p>
          <input  onChange={(e)=>setReceivedAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'5px',padding:'8px',height:'3em'}} type="number" placeholder="&#8377;"/>
          </div>
          </div>
          <div style={{display:'block',marginTop:'1em'}}>
          <p  style={{marginBottom:'-1px',fontFamily:'Inter-Medium',fontSize:'14px'}}>Discount <span style={{fontFamily:'Inter-Medium',fontSize:'14px',fontWeight:'bold'}}>(Max. Discount : {formatNumberWithCommas(remainingInterest)} + {formatNumberWithCommas(overdueCharges)} + {formatNumberWithCommas(chequeBounceCharges)} = {formatNumberWithCommas(parseFloat(remainingInterest) + parseFloat(overdueCharges) + parseFloat(chequeBounceCharges))})</span></p>
          <input onWheel={(e) => e.target.blur()} onChange={handleInputChange} value={adjustmentAmount} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'5px',padding:'8px',height:'3em'}} type="number" placeholder="&#8377;"/>
          {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>}
          </div>
          <div style={{display:'block',marginTop:'1em',marginBottom:'1em'}}>
          <RadioGroup  
          style={{display:'flex',flexDirection: 'row',justifyContent: 'space-between',}}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={valueRadio}
              onChange={handleChange}
            >
              <FormControlLabel value="A/C Transfer" control={<Radio />} label={<span style={{ fontFamily: 'Inter-Medium', fontSize: '14px' }}>A/C Transfer</span>} />
              <FormControlLabel value="Cheque" control={<Radio />} label= {<span style={{ fontFamily: 'Inter-Medium', fontSize: '14px' }}>Cheque</span>}/>
              <FormControlLabel value="UPI" control={<Radio />} label= {<span style={{ fontFamily: 'Inter-Medium', fontSize: '14px' }}>UPI</span>} />
           </RadioGroup>
           
          </div>
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <div style={{marginTop:'1em'}}>
            <p style={{ position: 'relative',fontFamily:'Inter-Medium',fontSize:'14px',lineHeight:'1em' }}>Paid Date</p>
             <Datetime
              id="fromDate"
              dateFormat="YYYY/MM/DD"
              timeFormat={false}
              onChange={(date) => setSelectedDate(moment.isMoment(date) ? date.format('YYYY/MM/DD') : null)}
              locale="en-gb"
              closeOnSelect={true}
              inputProps={{ placeholder: 'Date',style:{fontFamily:'Inter-Medium',fontSize:'14px',height:'3.6em'} }}
            />
          </div>
          <div style={{display:'block',marginTop:'1em'}}>
          <p style={{lineHeight:'1em'}}>Transaction Id / Cheque No.</p>
          <Input  onChange={(e)=>setTransactionId(e.target.value)} style={{border:'1px solid #D0D0D0',width:'90%',borderRadius:'5px',padding:'8px',height:'3em'}} type="text"/>
          </div> 
          </div>
         
          {/* <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Upload Proof</p>
          <img onClick={()=>openProofUpload()}style={{cursor:'pointer',width:'100%'}} src={file_upload}/>
          <input ref={uploadRef} onChange={(e)=>handleProofUpload(e)} style={{visibility:'hidden'}} type="file" />
          <p style={{textAlign:'center',backgroundColor:'#FFCCCB',boxShadow:'0px 0px 2px 2px #F0F0F0'}}>{proof?<u>{proof.name}</u>:""}</p>
          </div> */}
          {/* <div style={{display:'block'}}>
          <p  style={{fontWeight:'bold'}}>Received Amount</p>
          <input  onChange={(e)=>setReceivedAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          <div style={{display:'block'}}>
          <p  style={{fontWeight:'bold'}}>Adjustment Amount</p>
          <input value={adjustmentAmount}  onChange={(e)=>setAdjustmentAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div> */}
          
        <div style={{display:'flex', justifyContent: 'center',backgroundColor:'#FFF2EA',marginTop:'3em',flexDirection:'column'}}>
            <p style={{fontFamily:'Inter-Medium',color:'#d32028',textAlign:'center'}}>Net Amount</p>
            <p style={{fontFamily:'Inter-Medium',color:'#0D9857',textAlign:'center'}}>  {receivedAmount?receivedAmount:0}</p>
            <button onClick={()=>generateReceipt()} style={{width:'20%',borderRadius:'5px',backgroundColor:'#D32028',color:'white',marginBottom:'1em',display:'flex',justifyContent:'center',alignItems:'center',margin:'0 auto 1em'}}>Submit</button>
        </div>
        </div>
        </Box>
      </Modal>
    </>
  );
}