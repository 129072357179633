import React, { useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import close from "../../assets/images/close.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import plus from "../../assets/images/plus_icon.png"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input, FormText,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { startTransition } from 'react';
import axiosInstance from '../../helpers/axios';
import { Box } from '@mui/material';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MuiButton from '@mui/material/Button';

export default function AddInstitute({ onAddInstitute }) {
   
  //const state = useSelector((state) => state?.Indropped?.res);
    const editstate = useSelector((state) => state.Editform?.res);
    const savedstate = useSelector((state)=> state.Updateeditform?.res)
    const [formOpen, setFormOpen] = useState(false);
    const [instituteCode, setInstituteCode] = useState('');
    const [insLogo, setInstituteLogo] = useState('');
    const [insName, setInstituteName] = useState('');
    const [insEmail, setInstituteEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [insAddress, setInstituteAddress] = useState('');
    const [insCity, setInstituteCity] = useState('');
    const [insState, setInstituteState] = useState('');
    const [insPincode, setInstitutePincode] = useState('');
    const [number, setInstituteNumber] = useState('');
    const [insType, setInstituteType] = useState('');
    const [gstApplicableCheck, setGSTApplicableCheck] = useState(false);
    const [gstNumber, setGSTNumber] = useState('');
    const [enableNext,setEnableNext]=useState(false)
    const [fileEntries, setFileEntries] = useState([{ fileName: '', file: null, url:null }])
    const dispatch = useDispatch();

  const location = useLocation();
  

  //const { user } = useUserAuth();
  
  const user = sessionStorage.getItem("user")
  
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const createInstituteBranchApi = async (payLoad) => {
    try {
    const response = await axiosInstance.post(`/institute-branches/info/create`,payLoad,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
  }
  catch(e) {
    console.error(e)
  }
  }


  const b2bsignupURL="https://institute-apis.feemonk.com/auth/register"
  const callRegistration=()=>{

if(insLogo){
  const file=new FormData();
  file.append('file',insLogo)
  file.append('type',"logo")
  axiosInstance.post(`/file/upload`,file)
  .then((res)=>{ 
   //  const obj=new FormData();  
    
   //    obj.append('instituteName',insName);
   //    obj.append('instituteType',insType);
   //    obj.append('name',instituteCode)
   //    obj.append('phoneNumber',number)
   //    obj.append('password',12345678)
   //    obj.append('email',insEmail)
   //    obj.append('designation',"institute_user")
   //    obj.append('file',insLogo)
   //    obj.append('gst',gstNumber)
   //    obj.append('isVerified',false)
 //  axios.post(b2bsignupURL,obj,{
 //    headers:{
 //      Authorization:`Bearer ${user}`
 //    }
 //  }).then((resp)=>console.log("Uploaded"))
 //  .catch((err)=>alert("Error ! Check Fields"));

  const obj2={
    code: instituteCode,
    name: insName,
    email: insEmail,
    contact: number,
    logo: res.data.data.s3Url,
    address: insAddress,
    city: insCity,
    state: insState,
    gstApplicable:gstApplicableCheck,
    gstNumber:gstNumber,
    pincode: insPincode,
    salesOwner:'string',
    website:'string',
    mouFiles:  JSON.stringify(fileEntries)
  }
  axiosInstance.post(`/institute/info/create`,obj2,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((resp)=>{
    sessionStorage.setItem("insId",resp.data.data)
    setEnableNext(true)
   alert("Institute Details added")
   setTimeout(() => window.location.reload(true), 2000);

   const instituteId = resp.data.data
   const instituteBranchInfo = {
    "instituteId": instituteId,
    "branchName": insName,
    "address": insAddress,
    "city": insCity,
    "state": insState,
    "pincode": insPincode,
  }

    createInstituteBranchApi(instituteBranchInfo)
  })
  .catch((err)=>alert(err?.response?.data?.message))
  })   

  const newInstitute = {
   code: instituteCode,
    name: insName,
    email: insEmail,
    contact: number,
    
    address: insAddress,
    city: insCity,
    state: insState,
    gstApplicable:gstApplicableCheck,
    gstNumber:gstNumber,
    pincode: insPincode,
    salesOwner:'string',
    website:'string',
    mouFiles:  JSON.stringify(fileEntries)
 };

 onAddInstitute(newInstitute);
}else{
  const obj2={
    code: instituteCode,
    name: insName,
    email: insEmail,
    contact: number,
   
    address: insAddress,
    city: insCity,
    state: insState,
    gstApplicable:gstApplicableCheck,
    gstNumber:gstNumber,
    pincode: insPincode,
    salesOwner:'string',
    website:'string',
    mouFiles:  JSON.stringify(fileEntries)
  }

  
  axiosInstance.post(`/institute/info/create`,obj2,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((resp)=>{
    sessionStorage.setItem("insId",resp.data.data)
    setEnableNext(true)
   alert("Institute Details added")
   setTimeout(() => window.location.reload(true), 2000);

   const instituteId = resp.data.data
   const instituteBranchInfo = {
    "instituteId": instituteId,
    "branchName": insName,
    "address": insAddress,
    "city": insCity,
    "state": insState,
    "pincode": insPincode,
  }

    createInstituteBranchApi(instituteBranchInfo)
  })
  .catch((err)=>alert('Error ! Check Fields'))
  const newInstitute = {
    code: instituteCode,
     name: insName,
     email: insEmail,
     contact: number,
     
     address: insAddress,
     city: insCity,
     state: insState,
     gstApplicable:gstApplicableCheck,
     gstNumber:gstNumber,
     pincode: insPincode,
     salesOwner:'string',
     website:'string',
     mouFiles: JSON.stringify(fileEntries)
    
  };
 
  onAddInstitute(newInstitute);
}


   
 }

  const popUpForm = () => {
    setFormOpen(true);
  }

  const getS3FileUrl = async (newFile) => {
    const file=new FormData();
    file.append('file',newFile)
    file.append('type',"MOU")
    try {
        const response = await axiosInstance.post(`/file/upload`,file)
        const s3Url = await response.data.data.s3Url
        return s3Url

        
    } catch (error) {
        alert(`${error}, Please Reupload MOU`)
    }
  }
  

  const handleFileNameChange = (index, value) => {
    setFileEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, fileName: value } : entry
      )
    );
  };

  const handleAddMoreFiles = () => {
    setFileEntries([...fileEntries, { fileName: '', file: null, url:null }]);

  }

  const handleRemoveFiles =(index) => {
    setFileEntries((prevEntries) => prevEntries.filter((_, i) => i !== index));
  }

  const handleFileChange = async (index, file) => {
    const url =  await getS3FileUrl(file)
    setFileEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, file, url } : entry
      )
    );
  };

  const hanldeClearFile = () => {
    setFileEntries([{ fileName: '', file: null, url:null }])
  }

  return (
    <div>
     <p style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'500', textTransform: 'capitalize',cursor:'pointer',width:'180px',height:'34px',fontFamily:'Inter-Medium',fontSize:'16px',backgroundColor:'#D22129',display:'flex',alignItems:'center',justifyContent:'center',color:'white',borderRadius:'6px'}} onClick={() => popUpForm()}><img src={plus} style={{marginRight:'10px'}}/>Add Institute</p>
    
      {formOpen && (
      <>
     <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal style={{ top: '5%', backgroundColor: 'white',maxWidth: '80%',}} size="lg" className='edit-form ' isOpen={formOpen}>
          
          <div style={{borderTopLeftRadius:'10px',borderTopRightRadius:"10px",display:"flex",flexDirection:'row',padding:"20px",justifyContent:"space-between",backgroundColor:"#E9E9E9",height:"50px",alignItems:"center"}}><p className='heading'>Add Institute</p>
          <img style={{height:'1.5em',width:'1.5em'}} className="image1" onClick={()=>setFormOpen(!formOpen)} src={close}/>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            <Row>
              <Col >
                <Row>
                  <div title="Add Institute Details">
                    <Form >
                    <div>
                      <div style={{display:'flex',justifyContent: 'space-between',}}>
                      <FormGroup  style={{marginRight:'10px'}}>
                        <Label htmlFor=""><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Institute Code</span> </Label>
                        <Input 
                          type="text"
                          name="code"
                          id="code"
                          placeholder="3 letter institute code"
                          onChange={(e) => setInstituteCode(e.target.value)}
                         
                        />
                      </FormGroup>
                      <FormGroup style={{marginRight:'10px'}}>
                        <Label htmlFor="instituteName"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Institute Name</span><span style={{color:'red'}}>*</span></Label>
                        <Input 
                          type="name"
                          name="name"
                          id="name"
                          placeholder="Institute Name Here"
                          onChange={(e) => setInstituteName(e.target.value)}
                        />
                      </FormGroup>
                       <FormGroup style={{marginRight:'10px'}}>
                        <Label htmlFor=""><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Email Address</span><span style={{color:'red'}}>*</span></Label>
                        <Input 
                          valid={validateEmail(insEmail)}
                          type="email"
                          name="email"
                          id="Email"
                          placeholder="Email address Here"
                          onChange={(e) => setInstituteEmail(e.target.value)}
                        />
                        <FormText color="muted">
                          We&apos;ll never share your email with anyone else.
                        </FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="phoneNumber"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Contact</span><span style={{color:'red'}}>*</span></Label>
                        <Input 
                          type="tel"
                          name="phonenumber"
                          id="phonenumber"
                          placeholder="Institute Contact here"
                          onChange={(e) => setInstituteNumber(e.target.value)}
                        />
                      </FormGroup>
                      </div>
                      </div>
                      <div>
                      <div style={{display:'flex',justifyContent: 'space-between', flexWrap:'wrap'}}>
                      <FormGroup>
                        <Label htmlFor="city"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>City</span></Label>

                        <Input 
                          style={{width:'20em'}}
                          type="text"
                          name="city"
                          id="city"
                          placeholder="City "
                          onChange={(e) => setInstituteCity(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="state"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>State</span></Label>

                        <Input 
                          style={{width:'20em'}}
                          type="text"
                          name="state"
                          id="state"
                          placeholder="State"
                          onChange={(e) => setInstituteState(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="pincode"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Pincode</span></Label>

                        <Input 
                          style={{width:'20em'}}
                          type="text"
                          name="pincode"
                          id="pincode"
                          placeholder="Pincode"
                          onChange={(e) => setInstitutePincode(e.target.value)}
                        />
                      </FormGroup>
                        </div>
                        <div>
                      </div>
                      </div>
                      <div>
                      <div style={{display:'flex',justifyContent: 'space-between', flexWrap:'wrap'}}>
                      <FormGroup>
                        <Label htmlFor="address"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Address</span><span style={{color:'red'}}>*</span></Label>

                        <Input 
                          style={{width:"20em",height:'2em'}}
                          type="textarea"
                          name="Address"
                          id="address"
                          placeholder="Institute Address"
                          onChange={(e) => setInstituteAddress(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="logo"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Institute Type</span></Label>
                        <br/>
                         <select   id="instituteType"  name="instituteType"  onChange={(e)=>setInstituteType(e.target.value)} style={{width:'20em',height:'2.5em',padding:'0.6em',border:'1px solid #D0D0D0',borderRadius:'5px'}}  >
                          <option value="Select">Select</option>
                          <option value="Education Trust">Education Trust</option>
                          <option value="Education Society">Education Society</option>
                          <option value="Private Limited">Private Limited</option>
                          <option value="Limited Liabilty">Limited Liability</option>
                          <option value="Partnership Firm">Partnership Firm</option>
                          <option value="Proprietorship">Proprietorship</option>
                          <option value="Others">Others</option>
                      </select> 
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="logo"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Logo</span></Label>

                        <Input 
                          style={{width:'20em'}}
                          type="file"
                          name="logo"
                          id="logo"
                          placeholder="Institute Contact here"
                          onChange={(e) => setInstituteLogo(e.target.files[0])}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="logo"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>MOU</span></Label>
                        <MuiButton variant='outlined' sx={{textTransform:'none', ml:3}} size='small' color='secondary' onClick={hanldeClearFile}>Clear All</MuiButton>
                        <>
                        {fileEntries?.map((entry, index) => {
                          return (
                            <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                              <Input
                                type="text"
                                placeholder="Enter File Name"
                                value={entry.fileName}
                                onChange={(e) => handleFileNameChange(index, e.target.value)}
                                style={{ maxWidth: 230, marginRight: '10px' }}
                              />
                              <Input 
                                type="file"
                                name="file1"
                                id="logo"
                                placeholder="Institute Contact here"
                                onChange={(e) => handleFileChange(index, e.target.files[0])}
                                style={{ marginLeft:'5px',width: 300,}}
                                
                              />
                              {index !==0 && <RemoveCircleOutlineIcon sx={{cursor:'pointer'}} onClick={() => handleRemoveFiles(index)}  fontSize='small'/>}
                              <AddCircleIcon sx={{cursor:'pointer'}} onClick={handleAddMoreFiles} fontSize='small'/>
                            </Box>
                          )
                        })}
                        </>
                      </FormGroup>
                        </div>
                        <div>
                      </div>
                      </div>
                      <div>
                      <FormGroup>
                        <div style={{display:'flex'}}>
                        <p style={{marginTop: '1em',}}><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>GST Applicable</span><span style={{color:'red'}}>*</span></p>
                        <input style={{width:'4em',height:'2em',marginTop: '1.5em',}}type="checkbox" onChange={(e)=>setGSTApplicableCheck(e.target.checked)}/>
                      </div>
                       { gstApplicableCheck?<Input style={{width:'20em'}} onChange={(e)=>setGSTNumber(e.target.value)} type="text" placeholder="GST Number"/>:null}
                      </FormGroup>
                      </div>
                      <Button style={{marginLeft:'40%',width:'10em',backgroundColor:'#D32028',marginTop:'20px',border:'none'}}
                        onClick={() => callRegistration()}
                        color="primary"
                      >
                     <span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'500', textTransform: 'capitalize',color:'white'}}>Save Details</span>
                      </Button>
                     
                    </Form>

                  </div>
                </Row>
              </Col>
            </Row>
            </FormGroup>
          </Form>
        </ModalBody>
       
        
      </Modal>
      </>
       )}
    </div>
  );
}


