import { Card, CardBody, CardTitle, Input } from 'reactstrap';
import Chart from 'react-apexcharts';

const SalesOverview = ({totalSales,thisMonthSales,thisWeekSales,months,disbursementAmounts}) => {
  
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }
 
  const sanitizedMonths = months.map((month) => {
    return month === null || month === undefined ? "NullMonth" : month.split(' ')[1];
});



  const optionscampaign = {
    chart: {
      id: 'basic-bar',
      animations: {
        easing: 'easeinout',
      },
      fontFamily: "'Montserrat', sans-serif",
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: sanitizedMonths,
      labels: {
        show: true,
        style: {
          colors: '#99abb4',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: '#99abb4',
          fontSize: '12px',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    colors: ['#398bf7'],
    stroke: {
      curve: 'straight',
      width: '3',
    },
    grid: {
      show: true,
      borderColor: 'rgba(0, 0, 0, .2)',
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      theme: 'dark',
    },
  };
  const seriescampaign = [
    {
      name: 'Disbursement',
      data: disbursementAmounts,
    },
  ];





  return (
    <Card  style={{marginBottom:'20px'}}>
      <span className="lstick bg-info" ></span>
      <CardBody>
        <div class="text-muted">
          <CardTitle tag="h4">Disburements (12 months)</CardTitle>
        </div>
      </CardBody>
      <div  style={{backgroundColor:'#4c8cf4',color:'white'}}>
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <div className="p-3 w-100 text-truncate">
              <h6 className="text-dark-white">Total Loan amount</h6>
              <h3 className="text-dark-white m-b-0">₹{formatNumberWithCommas(Math.round(totalSales))}</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4" >
            <div className="p-3 w-100 text-truncate">
              <h6 className="text-dark-white">This Month</h6>
              <h3 className="text-dark-white m-b-0">₹{formatNumberWithCommas(Math.round(thisMonthSales))}</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="p-3 w-100 text-truncate">
              <h6 className="text-dark-white">This Week</h6>
              <h3 className="text-dark-white m-b-0">₹{formatNumberWithCommas(Math.round(thisWeekSales))}</h3>
            </div>
          </div>
        </div>
      </div>
      <CardBody>
        <div className="">
          <div className="">
          <Chart options={optionscampaign} series={seriescampaign} type="line" height={300} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SalesOverview;
