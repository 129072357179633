import React,{useRef,useState,useEffect} from 'react'
import './index.css'
import Switch from '@mui/material/Switch';
import * as moment from 'moment'
  import Sidebar from '../Sidebar';
  import Files from './Files/files.js'
  import Topbar from '../Topbar';
  import KycProfile from './KycProfile/kycProfile.js';
  import CreditProfile from './CreditProfile/CreditProfile.js';
  import IncomeProfile from './IncomeProfile/incomeProfile.js';
  import AccountAggregator from './IncomeProfile/accountAggregator.js';
  import LayoutFlow from '../MindMap/MindMap.js';
  import PreScreen from './Pre-Screen/preScreen.js';
  import jwtDecode from 'jwt-decode';
  import {
    ReactFlowProvider,
  } from 'reactflow';
  import {
    Col,
    Table,
    Card,
    Form,
    Button,
    Input,
    CardBody,
    CardTitle,
    CardSubtitle,
    Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from 'reactstrap';
  import {useLocation,useNavigate} from 'react-router-dom';
  import slider from '../../assets/images/slider.png'
  import {useSelector,useDispatch} from 'react-redux'
import {userActions} from '../../actions/user.actions';
import axiosInstance from '../../helpers/axios';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


  import activity from '../../assets/images/buttons/activity.png'

  import pencil from '../../assets/images/pencil.png'
  import swap from '../../assets/images/swap.png'
  import userSelfie from '../../assets/images/icons/userselfie.png'
  import loanAgreement from '../../assets/images/buttons/loanAgreement.png'
  import openMap from "../../assets/images/openMap.png"
import LoanDetails from './LoanDetails/LoanDetails.jsx';
  
 export default function IndividualProfile() {
  const location=useLocation();
  const navigate=useNavigate();
  
  const [checked, setChecked] = useState(location?.state?.clickEvent==="coapp"?false:true);
  console.log("loca",location?.state)

  const  curruserId=checked?location?.state?.applicantId?location?.state?.applicantId:sessionStorage.getItem('repaymentsUserId'):location?.state?.coapplicantId
  const viaCibil=location?.state?.viaCibil
  const viaPreScreen=location?.state?.viaPreScreen
  //console.log(location?.state?.clickEvent,"dyanmic userId")
  const  currapplicationId=location?.state?.applicationId?location?.state?.applicationId:sessionStorage.getItem('repaymentsAppId')
  const user=sessionStorage.getItem("user");
  const decode=jwtDecode(user)
  const [status1, setStatus1] = useState(sessionStorage.getItem('status'));
   const bankselfiedetails = useSelector((state)=>state.Bankselfiedetails?.res)
  const activityState=useSelector((state)=>state?.ACTIVITY?.activityData)
  const digiLockerState=useSelector((state)=>state?.DIGILOCKER?.digiLockerData);
  const cibilState=useSelector((state)=>state?.CIBIL?.cibilData);
  const cibilSoftState=useSelector((state)=>state?.CIBILSOFT?.cibilSoftData);
  console.log("inside induvidual softpull",cibilSoftState)
  const panState=useSelector((state)=>state?.PAN?.panStatus);
  const profileState=useSelector((state)=>state?.PROFILE?.profileDetails);
  const ckycState=useSelector((state)=>state?.CKYC?.ckycData);
  const bankSelfieState=useSelector((state)=>state?.BANKSELFIE?.bankSelfieData);
  const uploadedFileState=useSelector((state)=>state?.UPLOADEDFILES?.uploadedFilesDetails);
  
  
  const status = sessionStorage.getItem("status")
  const [bankSelfieData,setBankSelfieData]=useState(null)

  const [ckycDetails,setCkycDetails]=useState(null)
  const [activityData,setActivityDetails]=useState(null)
  const [cibilReport,setCibilReport]=useState(null)
  const [panDetails,setPanDetails]=useState(null)
  const [digiLockerDetails,setDigiLockerDetails]=useState(null)
  const [bankSelfieDetails,setBankSelfieDetails]=useState(null)
  const [uploadedFilesDetails,setUploadedFiles]=useState(null)
  const [profileDetails,setProfileDetails]=useState(null)
  const [cibilDetails,setCibilDetails]=useState(null)
  const [accountAggregatorDetails,setAccountAggregatorDetails]=useState(null)

  const incomeProfileState=useSelector((state)=>state?.GETINCOMEPROFILE?.getIncomePdfData)
  const getFoirState=useSelector((state)=>state?.GETFOIRDETAILS?.getFoirDetails)

  const [incomeProfileData,setIncomeProfileData]=useState(null)
    const ckycUrl=`/ckyc/details?userId=${curruserId}`;
  const profileUrl=checked?`/summary/user?id=${curruserId}&applicationId=${currapplicationId}&status=${location?.state?.dir?location?.state?.dir:"Disbursed"}`:`/summary/coapplicant?id=${curruserId}&applicationId=${currapplicationId}&status=${location?.state?.dir?location?.state?.dir:"Disbursed"}`;
  const digilockerUrl=`/digilocker/details?applicationId=${currapplicationId
  }&userId=${curruserId}`;
  const panUrl=`/application/pan-details?userId=${curruserId}&applicationId=${currapplicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${currapplicationId
  }&userId=${curruserId}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${curruserId}`
  const getActivityUrl=`/audit?applicationId=${currapplicationId
  }`
   const urlForBankSelfie=`/integrations/application-profile?applicationId=${currapplicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${curruserId}`
   const [foirRefData,setFoirRefData]=useState(null)
    
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${currapplicationId}`

  
  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${curruserId}&applicationId=${currapplicationId}`

  const preScreenUrl = `/experian/report?userId=${curruserId}&applicationId=${currapplicationId}`
  const accountAggregatorUrl = `/account-aggregator/admin-analytics-data/${curruserId}`

  const customerDashboardLoginUrl = `/login/admin`;

  const dispatch = useDispatch();
 
  useEffect(() => {
      if(viaCibil)
      {
        toggle('4')
      }
      if(viaPreScreen)
      {
        toggle('3')
      }
    
      if(profileState||uploadedFileState){
        
    //      axiosInstance.get(preScreenUrl, {
    //     headers: {
    //       Authorization: `Bearer ${user}`,
    //     },
    //   }).then((res=>{console.log("cibil soft indivi",res); setCibilDetails(res?.data?.data)}
    //  )).catch((error)=>(console.log("error",error)))
     
    //  axiosInstance.get(accountAggregatorUrl, {
    //   headers: {
    //     Authorization: `Bearer ${user}`,
    //   },
    // }).then((res) => {
    //   setAccountAggregatorDetails(res?.data?.data)
    // }).catch((error) => {
    //   console.log("Error", error);
    // });
        setBankSelfieData(bankselfiedetails)
        setActivityDetails(activityState?.res?.data?.data)
        setDigiLockerDetails(digiLockerState?.res)
        setCkycDetails(ckycState?.res)
        setPanDetails(panState?.res);
        setCibilReport(cibilState?.res?.data?.data?.summary)
        setBankSelfieDetails(bankSelfieState?.res)
        setProfileDetails(profileState?.res?.data)
        sessionStorage.setItem("courseFees",profileState?.res?.data?.courseFees)
        setUploadedFiles(uploadedFileState?.res?.data?.data)
        setIncomeProfileData(incomeProfileState?.res?.data?.data)
        setFoirRefData(getFoirState?.res?.data?.data)
    }
      else{
    //    axiosInstance.get(preScreenUrl, {
    //     headers: {
    //       Authorization: `Bearer ${user}`,
    //     },
    //   }).then((res=>{console.log("cibil soft indivi",res); setCibilDetails(res?.data?.data)}
    //  )).catch((error)=>(console.log("error",error)))
    //  axiosInstance.get(accountAggregatorUrl, {
    //   headers: {
    //     Authorization: `Bearer ${user}`,
    //   },
    //   }).then((res) => {
    //     setAccountAggregatorDetails(res?.data?.data)
    //   }).catch((error) => {
    //     console.log("Error", error);
    //   });
        dispatch(userActions.getCkycDetails(ckycUrl,user));
        dispatch(userActions.getCibilDetails(urlCIBIL));
        dispatch(userActions.getPanStatus(panUrl));
        dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
        dispatch(userActions.getDigiLocker(digilockerUrl,user));
        dispatch(userActions.getProfileDetails(profileUrl,user));
        dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
        dispatch(userActions.getActivity(getActivityUrl,user));
        dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
        dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])



      }
      
      
      

  }, [profileUrl,profileState,uploadedFileState,getFoirState,bankSelfieDetails])
  
  const statusIndex = {
    submission : 'In Submission',
    Review : 'In Review',
    Process : 'In Process',
    Pending :'Pending Disbursement',
    onHold: 'On Hold',
    dropped: 'Dropped',
    rejected:'Rejected',
    sanction:'In Sanction',
    disbursement:'Ready to Disburse',
    disbursed:'Disbursed'

  }
 
  const formattedStatus =  Object.keys(statusIndex).find(key => statusIndex[key] === status) || status

  const [activeTab, setActiveTab] = useState(decode?.clientId === '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? '0': '1');
   
  const handleChange = (event) => {
    setChecked(event.target.checked);
    dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])

  };
  
 const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleNavigateActivity=()=>{
    sessionStorage.setItem('activityUserId',curruserId)
    sessionStorage.setItem('activityAppId',currapplicationId)
    navigate('/status/profile/activity',{
      state:{
        activityDetails:activityData
      }
    })
  }

  const ref=useRef(null)
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }
  const prev = () => {
    requestAnimationFrame(() => {
      const scrollLeft = ref.current?.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(ref.current?.children[0]).width
      );
      ref.current.scrollLeft = scrollLeft - itemWidth * 0.3;
    });
  };

  const next = () => {
    requestAnimationFrame(() => {
      const scrollLeft = ref.current?.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(ref.current?.children[0]).width
      );
      ref.current.scrollLeft = scrollLeft + itemWidth * 0.3;
    });
  };

  const dateOfBirth = new Date(profileDetails?.dateOfBirth);
  const today = new Date();
  
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  
  if (today.getMonth() < dateOfBirth.getMonth() || (today.getMonth() === dateOfBirth.getMonth() && today.getDate() < dateOfBirth.getDate())) {
    age--; 
  }

  const handleApplicationProfile=(currApplicationId,currUserId)=>{
    console.log("clicked")
    sessionStorage.setItem("appId",currApplicationId)
    sessionStorage.setItem("userId",currUserId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${currapplicationId}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${currapplicationId}`
    const getEmiUrl=`/emis?applicationId=${currapplicationId}`
    const getActivityUrl=`/audit?applicationId=${currapplicationId}`
    
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate(`/status/${formattedStatus}/appProfile`)
    
  }



   const [editModal,setEditModal]=useState(false)
   const [addRef2,setAddRef2]=useState(false)
  const [addRef3,setAddRef3]=useState(false)
  const [refName,setRefName]=useState(null)
  const [ref2Name,setRef2Name]=useState(null)
  const [ref3Name,setRef3Name]=useState(null)
  const [relationship,setRelationship]=useState("")
  const [relationship2,setRelationship2]=useState("")
  const [relationship3,setRelationship3]=useState("")
  const [refMobile,setRefMobile]=useState(null)
  const [ref2Mobile,setRef2Mobile]=useState(null)
  const [ref3Mobile,setRef3Mobile]=useState(null)

  const postFoirRefUrl=`/admin/application/additional-info`
  const saveReferenceDetails=()=>{  


    const data={
      userId:curruserId,
      applicationId:currapplicationId,
      contact1: refMobile?refMobile.trim():foirRefData?.contact1?foirRefData?.contact1:'',
      contact1Relation:relationship?relationship.trim():foirRefData?.contact1Relation?foirRefData?.contact1Relation:'',
      contact1Name: refName?refName.trim():foirRefData?.contact1Name?foirRefData?.contact1Name:'',
      contact2: ref2Name?ref2Mobile.trim():foirRefData?.contact2?foirRefData?.contact2:'',
      contact2Relation: relationship2?relationship2.trim():foirRefData?.contact2Relation?foirRefData?.contact2Relation:'',
      contact2Name: ref2Name?ref2Name.trim():foirRefData?.contact2Name?foirRefData?.contact2Name:'',
      contact3: ref3Name?ref3Mobile.trim():foirRefData?.contact3?foirRefData?.contact3:'',
      contact3Relation: relationship3?relationship3.trim():foirRefData?.contact3Relation?foirRefData?.contact3Relation:'',
      contact3Name: ref3Name?ref3Name.trim():foirRefData?.contact3Name?foirRefData?.contact3Name:'',
    }
    dispatch(userActions.postFoirDetails(postFoirRefUrl,data,user))
    setEditModal(false)
    window.location.reload();

  }

  const [documentToggle,setDocumentToggle]=React.useState(false)
const handleDocToggle = () => {
    setDocumentToggle(!documentToggle);
  };
  const [isOpen,setIsOpen]=useState(true)
  const [selfiePath,setSelfiePath] = useState(null)

  useEffect(() => {
    if(bankSelfieDetails?.selfieDetails?.selfie){
      const url = `/file/url-content?path=${bankSelfieDetails?.selfieDetails?.selfie}`
      axiosInstance
          .get(url, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res)=>{
            setSelfiePath(res?.data?.data)
            }).catch((err)=>console.log(err))
    }
    
  }, [bankSelfieDetails]);

  const [modal, setModal] = useState(false);
  const [agreementUrl,setAgreementUrl] = useState(null)
  const toggle1 = () => {
    const url = `/file/url-content?path=${bankSelfieData?.agreementUrl}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setAgreementUrl(res?.data?.data)
          })
    setModal(!modal)
  };

  const [sanctionModal, setModalSanction] = useState(false);
  const [sanctionUrl,setSanctionUrl] = useState(null)

  const toggleSanction = () => {
    const url = `/file/url-content?path=${bankSelfieData?.sanctionLetter}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setSanctionUrl(res?.data?.data)
          })
    setModalSanction(!sanctionModal)
  };

  const openCustomerDashboard = (mobile) => {
    dispatch(
      userActions.openCustomerDashboard(customerDashboardLoginUrl, user, mobile)
    );
  };
   
  return (
  <>
   <Card className="card" style={{width:"100%"}} >
    
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
          <Topbar/>
           <Modal size="lg" isOpen={editModal}>
            <ModalHeader>Edit Details or Add Reference Contacts</ModalHeader>
            <ModalBody>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <div style={{display:'block'}}>
                <p style={{fontWeight:'bold',}}>Email Id</p>
                <input defaultValue={profileDetails?.email} style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="text" disabled/>
              </div>
              <div style={{display:'block'}}>
                <p style={{fontWeight:'bold',}}>Phone Number</p>
                <input defaultValue={profileDetails?.mobile}style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="text" disabled/>
              </div>
              <div style={{display:'block'}}>
                <p style={{fontWeight:'bold',}}>Date of Birth</p>
                <input defaultValue={profileDetails?.dateOfBirth}style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="text" disabled/>
              </div>
            </div>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <div style={{display:'block'}}>
                <p style={{fontWeight:'bold',}}>Current Address</p>
                <textarea defaultValue={profileDetails?.currentAddress} style={{borderRadius:'8px',width:'20em',border:'2px solid #F0F0F0'}} type="text" disabled/>
              </div>
              <div style={{display:'block'}}>
                <p style={{fontWeight:'bold',}}>Permanent Address</p>
                <textarea defaultValue={profileDetails?.permanentAddress} style={{borderRadius:'8px',width:'20em',border:'2px solid #F0F0F0'}} type="text" disabled/>
              </div>
             
            </div>
            <div style={{display:'flex',justifyContent:'space-between',borderTop:'2px dashed #D0D0D0',marginTop:'1em'}}>
              <div style={{display:'block'}}>
                 
                <p style={{fontWeight:'bold',}}>1st Reference Contact Name</p>
                <input defaultValue={foirRefData?.contact1Name} onChange={(e)=>setRefName(e.target.value)} style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="text" />
                <p style={{fontWeight:'bold',}}>Contact 1</p>
                <input defaultValue={foirRefData?.contact1} onChange={(e)=>setRefMobile(e.target.value)} style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="number" />
                <p style={{fontWeight:'bold',}}>Relationship</p>
                <select defaultValue={foirRefData?.contact1Relation?foirRefData?.contact1Relation:"Select Relation"} style={{borderRadius:'8px',padding:'5px',border:'2px solid #F0F0F0'}} onChange={(e)=>setRelationship(e.target.value)} >
                  <option value="Sibling/Relative">Sibling / Relative</option>
                  <option value="Parent/Guardian">Parent / Guardian</option>
                  <option value="Friend/Colleague">Friend / Colleague</option>
                  <option value="Spouse">Spouse</option>
                </select>
              </div>
              <div style={{display:'block'}}>
                

                <p style={{fontWeight:'bold',}}> 2nd Reference Contact Name</p>
                <input defaultValue={foirRefData?.contact2Name} onChange={(e)=>setRef2Name(e.target.value)} style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="text" />
                <p style={{fontWeight:'bold',}}>Contact 2</p>
                <input defaultValue={foirRefData?.contact2} onChange={(e)=>setRef2Mobile(e.target.value)}style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="number" />
                <p style={{fontWeight:'bold',}}>Relationship</p>
                <select defaultValue={foirRefData?.contact2Relation?foirRefData?.contact2Relation:"Select Relation"} onChange={(e)=>setRelationship2(e.target.value)}style={{borderRadius:'8px',padding:'5px',border:'2px solid #F0F0F0'}} type="text" >
                  <option value="Sibling/Relative">Sibling / Relative</option>
                  <option value="Parent/Guardian">Parent / Guardian</option>
                  <option value="Friend/Colleague">Friend / Colleague</option>
                  <option value="Spouse">Spouse</option>
                </select>
              </div>
              <div style={{display:'block'}}>
               

                <p style={{fontWeight:'bold',}}>3rd Reference Contact Name</p>
                <input defaultValue={foirRefData?.contact3Name} onChange={(e)=>setRef3Name(e.target.value)}style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="text" />
                <p style={{fontWeight:'bold',}}>Contact 3</p>
                <input defaultValue={foirRefData?.contact3} onChange={(e)=>setRef3Mobile(e.target.value)} style={{borderRadius:'8px',border:'2px solid #F0F0F0'}} type="number" />
                <p style={{fontWeight:'bold',}}>Relationship</p>
                <select defaultValue={foirRefData?.contact3Relation?foirRefData?.contact3Relation:"Select Relation"} onChange={(e)=>setRelationship3(e.target.value)} style={{borderRadius:'8px',padding:'5px',border:'2px solid #F0F0F0'}} type="text" >
                  <option value="Sibling/Relative">Sibling / Relative</option>
                  <option value="Parent/Guardian">Parent / Guardian</option>
                  <option value="Friend/Colleague">Friend / Colleague</option>
                  <option value="Spouse">Spouse</option>
                </select>
              </div>
             
            </div>

            <div>
            </div>
              </ModalBody>
              <ModalFooter>
              <Button onClick={()=>setEditModal(false)}>Close</Button>
              <Button style={{backgroundColor:'#d32028',border:'none'}}  onClick={()=>saveReferenceDetails()}>Save</Button>
              </ModalFooter>
          </Modal>

          <div className="profileHighlight">
            <div style={{display:'flex', margin:'1.5em',alignItems:'center'}}>
              <img style={{width:'7vw',height:'11vw',marginRight:'1em'}} src={selfiePath}/>
              <div style={{lineHeight:'21px',marginTop:'1em'}}>
                <p style={{fontSize:'25px',fontWeight:'600'}}>{profileDetails?.firstName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}<span> {profileDetails?.lastName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span></p>
                <p style={{fontSize:'18px',color:'#667085',fontWeight:'bold'}}>Application Id : <span style={{cursor:'pointer',color:'#D32028',textDecoration:'underline'}} onClick={()=>handleApplicationProfile(currapplicationId,curruserId)}>{currapplicationId}</span>
                
                <span
                    onClick={() => {
                      openCustomerDashboard(profileDetails?.mobile);
                    }}
                    className="customerDashboardButton"
                  >
                    [ Preview Customer Dashboard ] 
                  </span>
                </p>
               <div style={{display:'flex'}} >

               {location?.state?.coapplicantId?<> <span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',color:checked?'#D32028':'#B0B0B0', }}>Applicant</span>
               <div style={{transform:'rotate(180deg)',width:'4em',marginTop:'-0.3rem'}}>
                 <Switch
                  
                  checked={checked}
                  onChange={(e)=>handleChange(e)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
                <span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',color:!checked?'#D32028':'#B0B0B0'}}>Co-applicant</span>
                </>:null}
              

              </div>
                <p ><i style={{fontSize:'14px',color:'#667085',fontWeight:'bold'}}>Work : </i><i style={{color:'#D32028'}}> {profileDetails?.employerName}</i></p>
                <p  style={{color:'#667085',fontWeight:'bold'}}>Amount :<span style={{fontWeight:'bold',fontSize:'1vw',color:'#D32028',marginLeft:'1em'}}>{profileDetails && profileDetails.courseFees
        ? <>
            &#8377;{formatNumberWithCommas(profileDetails.courseFees)}
            <span style={{ fontWeight: 'bold', fontSize: '1vw', color: '#667085', marginLeft: '5em' }}>Tenure : <span style={{fontWeight:'bold',fontSize:'1vw',color:'#D32028'}}>{profileDetails && profileDetails.loanTenure? profileDetails && profileDetails.loanTenure :'-' }</span> </span> 
            <span style={{ fontWeight: 'bold', fontSize: '1vw', color: '#667085', marginLeft: '5em' }}>Dist. from CL :  <span style={{fontWeight:'bold',fontSize:'1vw',color:'#D32028'}}>{profileDetails?.location}</span> </span>
          </>
        : "NA"}</span>  
        
        </p>
              
              </div>
            </div>
            <Modal isOpen={modal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggle1}>Agreement</ModalHeader>
        <ModalBody >
        <iframe
            src={agreementUrl}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
        
      </Modal>
      <Modal isOpen={sanctionModal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggleSanction}>Sanction Letter</ModalHeader>
        <ModalBody >
        <iframe
            src={sanctionUrl}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
       
       
      </Modal>
            <div style={{display:'flex',justifyContent: 'space-evenly',width:'20em',height:'3em',marginTop:'2em'}}>
              <img style={{cursor:'pointer'}} onClick={()=>handleNavigateActivity()} src={activity}/>
              <Dropdown style={{marginBottom:'1em'}}  isOpen={documentToggle} toggle={() => handleDocToggle()}>
                   <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none',height:'2.8em'}}>
                          Documents
                  </DropdownToggle>
                       <DropdownMenu style={{}}>
                          <DropdownItem disabled={bankSelfieData?.agreementUrl?false:true}>
                          <div onClick={toggle1}>Agreement</div>
                      </DropdownItem>
                          <DropdownItem disabled={bankSelfieData?.sanctionLetter?false:true}>
                          <div  target="_blank" rel="noreferrer" onClick={toggleSanction}>Sanction Letter</div>
                      </DropdownItem>
                        </DropdownMenu>
                </Dropdown>
            
              
            </div>
            
          </div>
          <div style={{display:'block',backgroundColor:'#F8F8F8',border:'none',borderRadius:'1em',boxShadow:'0px 2px 2px 0px #C0C0C0',height:'100%',marginLeft:'1%',marginRight:'1%',padding:'2em'}}>
            <div style={{display:'flex',justifyContent: 'space-between'}}>
                <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p className='field-title'>Email Id</p>
                <p>{profileDetails?.email}</p>
              </div>
              <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Phone Number</p>
                <p>{profileDetails?.mobile}</p>
              </div>
              {decode?.clientId === '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Applicant Name</p>
                <p>{profileDetails?.firstName + " " + profileDetails?.lastName}</p>
              </div>:
              <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Student Name</p>
                <p>{profileDetails?.studentName}</p>
              </div>}
              <div style={{display:'block',lineHeight:'18px' ,width:'12em',height:'6em' }}>
                  <p  className='field-title'>Ref Phone Number</p>
                  <p>
             { foirRefData?.contact1?<><div style={{  marginLeft:'-1em'}}>
                  <img onClick={()=>prev()} className="sliderBack" src={slider}/>
              </div>
              <div ref={ref}  style={{marginLeft:'2%',scrollBehavior:'smooth',padding:'1em',overflowX: 'hidden',display:'flex',whiteSpace:'nowrap',width:'70%'}}>
                {foirRefData?.contact1?<div style={{lineHeight:'5px',marginTop:'-0.5em',width:'10em',marginRight:'1em'}}>
                    <p>{foirRefData?.contact1Name}</p>
                    <p><i>{foirRefData?.contact1Relation}</i></p>
                    <p>{foirRefData?.contact1}</p>
                  
                </div>:"--"}
                {foirRefData?.contact2?<div style={{marginTop:'-0.5em',width:'10em',marginLeft:'4em',lineHeight:'0.5em'}}>
                    <p>{foirRefData?.contact2Name}</p>
                    <p><i>{foirRefData?.contact2Relation}</i></p>
                    <p>{foirRefData?.contact2}</p>
                  
                </div>:"--"}
               { foirRefData?.contact3?<div style={{marginTop:'-0.5em',width:'10em',marginLeft:'7em',lineHeight:'0.5em'}}>
                    <p>{foirRefData?.contact3Name}</p>
                    <p><i>{foirRefData?.contact3Relation}</i></p>
                    <p>{foirRefData?.contact3}</p>
                  
                </div>:"--"}
                
                  
              </div>

            <div>
              <img onClick={()=>next()} style={{  marginLeft:'9em',marginTop:'-5.5em',position:'absolute',cursor:'pointer',}} src={slider}/>
            </div></>:"---"}
                </p>
              </div>
            </div>
            <div style={{display:'flex',justifyContent: 'space-between',paddingTop:'1.5em',paddingBottom:'1.5em'}}>
              <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Date of Birth</p>
                <p>{moment(profileDetails?.dateOfBirth).format('DD MMM YYYY')}({age}yrs)</p>
              </div>
              {
                decode?.clientId === '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? 
                    <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                    <p  className='field-title'>Product Type</p>
                    <p >{profileDetails?.instituteName}</p>
                  </div>:
                  <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                  <p  className='field-title'>Course</p>
                  <p >{profileDetails?.courseName}</p>
                </div>
              }
              {
                decode?.clientId === '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? 
                    <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                    <p  className='field-title'>Client</p>
                    <p >Not available</p>
                  </div>:
                  <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                  <p  className='field-title'>Institute</p>
                  <p>{profileDetails?.instituteName}</p>
                </div>
              }
              <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Permanent Address</p>
                <p>{profileDetails?.permanentAddress?.length>30?profileDetails?.permanentAddress?.substr(0,40)+"..." : profileDetails?.permanentAddress? profileDetails?.permanentAddress: "Not available"}</p>
              </div>
              
            
              
            </div>
            <div style={{display:'flex',justifyContent: 'space-between'}}>
            <div style={{display:'block',lineHeight:'18px',width:'36em'}}>
            <p><span style={{color:'#D32038',fontWeight:'bold'}}>Current Address</span>
           </p>
           <p> <span>{profileDetails?.currentAddress}</span></p>
            </div>

            <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Current City</p>
                <p>{profileDetails?.currentCity}</p>
              </div>
              <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Current State</p>
                <p>{profileDetails?.currentState}</p>
              </div>
              <div style={{display:'block',lineHeight:'18px',width:'12em'}}>
                <p  className='field-title'>Current Pincode</p>
                <p>{profileDetails?.currentPincode}</p>
              </div>
            </div>
            <div style={{display:'flex',justifyContent:'flex-end',lineHeight:'18px'}}>
              <img src={pencil} onClick={()=>setEditModal(true)} title="Edit" style={{width:'1.4rem',height:'1.3rem',cursor:'pointer',marginTop:'0.4rem'}}/>
              <a title='whatsApp' href={`https://wa.me/+91${profileDetails?.mobile}`} target="_blank" rel="noreferrer">
                        <WhatsAppIcon  style={{ color:"black",cursor:'pointer',marginLeft:'1em',height:'2rem'}}/>
                        </a>
              </div>
            
            {/* <div style={{lineHeight:'18px',width:'12em',paddingLeft:'1.5em'}}>

                <p  style={{color:'#D32038',fontWeight:'bold'}}>Current Address</p>
                <p title={profileDetails?.currentAddress} >{profileDetails?.currentAddress?.length>30?profileDetails?.currentAddress?.substr(0,40)+"...":profileDetails?.currentAddress}</p>
              </div> */}
           
           
          </div>
          <div style={{paddingLeft: '1em',paddingRight:'1em',marginTop:'1em'}}>
           <Nav tabs>
              {(decode?.clientId === '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ||profileDetails?.instituteName==='Mortgage Loan' ) && <NavItem style={{backgroundColor:activeTab === '0' ? '#fff2f2' : '#fff'}}>
                <NavLink
                 style={{textDecoration:'none',borderBottom: activeTab === '0' ? '2px solid #D32028' : ''}}
                  className={activeTab === '0' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('0');
                  }}
                >
                <span  className={activeTab === '0' ? 'tabsHeadingClicked' : 'tabsHeading'}>End Use</span>

                </NavLink>
              </NavItem>}
              <NavItem style={{backgroundColor:activeTab === '1' ? '#fff2f2' : '#fff'}}>
                <NavLink
                 style={{textDecoration:'none',borderBottom: activeTab === '1' ? '2px solid #D32028' : ''}}
                  className={activeTab === '1' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                <span  className={activeTab === '1' ? 'tabsHeadingClicked' : 'tabsHeading'}>Files</span>

                </NavLink>
              </NavItem>

              <NavItem style={{backgroundColor:activeTab === '2' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '2' ? '2px solid #D32028' : ''}}
                  className={activeTab === '2' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    
                    toggle('2');
                  }}>
                  <span  className={activeTab === '2' ? 'tabsHeadingClicked' : 'tabsHeading'} >KYC Profile</span>
                </NavLink>
              </NavItem>
              <NavItem style={{backgroundColor:activeTab === '3' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '3' ? '2px solid #D32028' : ''}}
                  className={activeTab === '3' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                     
                    toggle('3');
                  }}
                >
                  <span  className={activeTab === '3' ? 'tabsHeadingClicked' : 'tabsHeading'}>Pre-Screen</span>
                </NavLink>
              </NavItem>
              <NavItem style={{backgroundColor:activeTab === '4' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '4' ? '2px solid #D32028' : ''}}
                  className={activeTab === '4' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                     
                    toggle('4');
                  }}
                >
                  <span  className={activeTab === '4' ? 'tabsHeadingClicked' : 'tabsHeading'}>Credit Profile</span>
                </NavLink>
              </NavItem>
              <NavItem style={{backgroundColor:activeTab === '5' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '5' ? '2px solid #D32028' : ''}}
                  className={activeTab === '5' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('5');
                  }}
                >
                  <span  className={activeTab === '5' ? 'tabsHeadingClicked' : 'tabsHeading'}>Income Profile (PDF)</span>
                </NavLink>
              </NavItem>
              <NavItem style={{backgroundColor:activeTab === '6' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '6' ? '2px solid #D32028' : ''}}
                  className={activeTab === '6' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('6');
                  }}
                >
                  <span  className={activeTab === '6' ? 'tabsHeadingClicked' : 'tabsHeading'}>Income Profile (AA)</span>
                </NavLink>
              </NavItem>
              <NavItem style={{backgroundColor:activeTab === '7' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '7' ? '2px solid #D32028' : ''}}
                  className={activeTab === '7' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('7');
                  }}
                >
                  <span  className={activeTab === '7' ? 'tabsHeadingClicked' : 'tabsHeading'}>Rules</span>
                </NavLink>
              </NavItem>
          
             
            </Nav>
           
            <TabContent activeTab={activeTab}>
              <TabPane tabId="0">
                  <Row>
                    <Col  sm="12">
                      <LoanDetails profileDetails={profileDetails}/>
                    </Col>
                  </Row>
                </TabPane>
              <TabPane tabId="1">
                <Row>
                  <Col  sm="12">
                    <Files appId={currapplicationId} userId={curruserId} fileList={uploadedFilesDetails} profileDetails={profileDetails}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12" >
                   
                   <KycProfile kycDetails={ckycDetails} bankVerificationDetails={bankSelfieDetails} panDetails={panDetails} digilockerDetails={digiLockerDetails} profileDetails={profileDetails} appId={currapplicationId}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12" >
                   
                   <PreScreen userId={curruserId} applicationId={currapplicationId}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                   <CreditProfile cibilReport={cibilReport} profileDetails={profileDetails}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row style={{ width: 'auto' }}>
                  <Col sm="12">
                    <IncomeProfile profileDetails={profileDetails} foirRefDetails={foirRefData} incomeProfileDetails={incomeProfileData} userId={curruserId} appId={currapplicationId}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row style={{ width: 'auto' }}>
                  <Col sm="12">
                    <AccountAggregator profileDetails={profileDetails} foirRefDetails={foirRefData} accountAggregatorDetails={accountAggregatorDetails} userId={curruserId} appId={currapplicationId}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row style={{ width: 'auto' }}>
                  <Col sm="12">
                  <ReactFlowProvider>
            <LayoutFlow userId={curruserId} appId={currapplicationId}/>
          </ReactFlowProvider>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
             </div>
          </div>
  </Card>
  </>
  )
}
