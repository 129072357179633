import { Row, Col } from 'reactstrap';
// import BreadCrumbs from '../../layouts/breadcrumbs/BreadCrumbs';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { useEffect, useState} from 'react';
import TopCards from '../../components/Institute Dashboard/TopCards';
import SalesOverview from '../../components/Institute Dashboard/SalesOverview';
import VisitSeparation from '../../components/Institute Dashboard/VisitSeparation';
import Blog2 from '../../components/Institute Dashboard/Blog2';
import axiosInstance from '../../helpers/axios';
import {Input} from "reactstrap"
import jwt_decode from 'jwt-decode';
import search from "../../assets/images/search.png"
import clear from "../../assets/images/clear.png";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import WebsiteVisit from '../../components/Institute Dashboard/WebsiteVisit';
// import ProjectMonth from '../../components/dashboard/analytical/ProjectMonth';
// import Activity from '../../components/dashboard/analytical/Activity';
import Twitter from '../../components/Institute Dashboard/Twitter';
import Facebook from '../../components/Institute Dashboard/Facebook';
import Gmail from '../../components/Institute Dashboard/Gmail';
// import RecentComments from '../../components/dashboard/analytical/RecentComments';
// import RecentChat from '../../components/dashboard/analytical/RecentChat';

const Demographical = () => {
   const [isOpen,setIsOpen]=useState(true)
   const [id,setId]=useState(null)
   const [instiName,setInstituteName]=useState([]);
   const [showDropdown, setShowDropdown] = useState(false);
   const [searchText, setSearchText] = useState("");
   const[instituteDetails,setinstituteDetails]=useState([]);
   const [searchClicked,setSearchClicked] = useState(false)
   const [totalSales, setTotalSales] = useState(0);
   const [thisMonthSales, setThisMonthSales] = useState(0);
   const [thisWeekSales, setThisWeekSales] = useState(0);
   const[ months,setMonths] =useState([])
   const[disbursementAmounts,setDisbursementAmounts]=useState([])
   const[ovdBackets,setOvdBackets]=useState([])
   const[ovdPercentage,setOvdPercentage]=useState([])
   const[ovdCount,setOvdCount]=useState([])
   const [isLoading, setIsLoading] = useState(false); 
   const [applmonth, setApplMonth] = useState([]); 
   const [applDisb, setApplDisb] = useState([]); 
   const [applCount, setApplCount] = useState([]); 
   const [applRate, setApplRate] = useState([]); 
   const [brandName, setBrandName] = useState(''); 
   console.log(applmonth,"totalSales")

   
   const user = sessionStorage.getItem('user');
 const decode=jwt_decode(user)


   const getInsituteInfo=(event)=>{ 
    const urlGetInstitute=`/institute/info/name?name=${event}`;
      
    if(searchText)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data.data)
        setId(res?.data?.data[0]?.id)
        setInstituteName(res?.data.data)
        setShowDropdown(true);
        
      });}}

      const handleInputChange = (event) => { 
        getInsituteInfo(event.target.value);
      };



      const handleSelect = (brandName) => {
        setBrandName(brandName)
        setSearchText(brandName);
        setShowDropdown(false);
      };


      console.log(searchText,'insnns')

      console.log(id,'insnns')

      
      useEffect((id)=>{
        
        setIsLoading(true)
        

        const instId = id ? id : "";
        
        const queryParams = new URLSearchParams({ instituteId: instId }).toString();
        const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = today.getDate().toString().padStart(2, '0'); // Ensure day is 2 digits
        return `${year}-${month}-${day}`;
      };
          const endDate = getCurrentDate();


        const urlGetInstituteInfo=`/analytics/Institute-dashboard?${queryParams}`
        const response1 = `/analytics/month-disburements?instituteId=${instId}`
        const response2=`/analytics/get-institute-Ovd?endDate=${endDate}&instituteId=${instId}`
        const response3 = `/analytics/get-application?instituteId=${instId}`
        {
          axiosInstance.get(urlGetInstituteInfo,{
            headers:{
              Authorization:`Bearer ${user}`
            }
          }).then((res) => {
            console.log("Info",res?.data?.dashboard?.instituteDetails?.instituteDetails)
            setinstituteDetails(res?.data?.dashboard?.instituteDetails?.instituteDetails)
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
            
          });}


          {
            axiosInstance.get(response1,{
              headers:{
                Authorization:`Bearer ${user}`
              }
            }).then((res) => {
              console.log("ressssss",res)
              console.log("Info1",res?.data?.data?.monthwise)
              const lastSixMonths = res?.data?.data?.monthwise.slice(-12);
              console.log("Info1",lastSixMonths)
              const monthLable=lastSixMonths.map((item) => item.month);
              console.log("Infomonth",monthLable)
              setMonths(lastSixMonths.map((item) => item.month))
             const disbursementAmounts = lastSixMonths.map((item) => Math.round(item.disbursement_amount));
             setDisbursementAmounts(lastSixMonths.map((item) => Math.round(item.disbursement_amount)))
              console.log("Infomonth",disbursementAmounts)
              setTotalSales(disbursementAmounts.reduce((a, b) => a + b, 0));
              setThisMonthSales(disbursementAmounts[disbursementAmounts.length - 1]); 
              setThisWeekSales(disbursementAmounts[disbursementAmounts.length - 2] / 4); 
              console.log("Infomonth",disbursementAmounts)
              const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
              }, 300);

              
            });}

            {
              axiosInstance.get(response3,{
                headers:{
                  Authorization:`Bearer ${user}`
                }
              }).then((res) => {
                console.log("ovdInfo2",res?.data?.data?.application?.application)
                const lastSixMonths = res?.data?.data?.application?.application.slice(-12);
               setApplMonth(lastSixMonths.map((item) => item.month))
               setApplDisb(lastSixMonths.map((item) => Math.round(item.disbursed_applications)))
               setApplCount(lastSixMonths.map((item) => Math.round(item.total_applications)))
               setApplRate(lastSixMonths.map((item) => Math.round(item.Approval_Rate)))
                const loadingTimeout = setTimeout(() => {
                  setIsLoading(false);
                }, 300);

              });}

              {
                axiosInstance.get(response2,{
                  headers:{
                    Authorization:`Bearer ${user}`
                  }
                }).then((res) => {
                  console.log("ovdInfo",res)
                  setOvdBackets(res?.data?.data?.OvdInstitute?.institutewisebuckets.map((item)=>item.bucketings))
                  setOvdPercentage(res?.data?.data?.OvdInstitute?.institutewisebuckets.map((item)=>item.backets_sum_amount))
                  setOvdCount(res?.data?.data?.OvdInstitute?.institutewisebuckets.map((item)=>item.bucket_count))

                  const loadingTimeout = setTimeout(() => {
                    setIsLoading(false);
                  }, 300);
  
                });}


    

        },[user])


        console.log("ovdb",ovdBackets)

      const instituteInfo=()=>{ 
        setIsLoading(true)

        setSearchClicked(!searchClicked);

            const urlGetInstituteInfo=`/analytics/Institute-dashboard?instituteId=${id}`
            {
              axiosInstance.get(urlGetInstituteInfo,{
                headers:{
                  Authorization:`Bearer ${user}`
                }
              }).then((res) => {
                console.log("Info",res?.data?.dashboard?.instituteDetails?.instituteDetails)
                setinstituteDetails(res?.data?.dashboard?.instituteDetails?.instituteDetails)
                const loadingTimeout = setTimeout(() => {
                  setIsLoading(false);
                }, 300);
              });}


      }




      const instiDisbursement=(id)=>{
        setIsLoading(true)

    
        const response1 = `/analytics/month-disburements?instituteId=${id}`

        {
          axiosInstance.get(response1,{
            headers:{
              Authorization:`Bearer ${user}`
            }
          }).then((res) => {
            console.log("ressssss",res)
              console.log("Info1",res?.data?.data?.monthwise)
              const lastSixMonths = res?.data?.data?.monthwise.slice(-12);
              console.log("Info1",lastSixMonths)
              const monthLable=lastSixMonths.map((item) => item.month);
              console.log("Infomonth",monthLable)
              setMonths(lastSixMonths.map((item) => item.month))
             const disbursementAmounts = lastSixMonths.map((item) => Math.round(item.disbursement_amount));
             setDisbursementAmounts(lastSixMonths.map((item) => Math.round(item.disbursement_amount)))
              console.log("Infomonth",disbursementAmounts)
              setTotalSales(disbursementAmounts.reduce((a, b) => a + b, 0));
              setThisMonthSales(disbursementAmounts[disbursementAmounts.length - 1]); 
              setThisWeekSales(disbursementAmounts[disbursementAmounts.length - 2] / 4); 
              console.log("Infomonth",disbursementAmounts)
              const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
              }, 300);
            
          });}


      }


     const instiBackets=(id)=>{

      setIsLoading(true)
      const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = today.getDate().toString().padStart(2, '0'); // Ensure day is 2 digits
        return `${year}-${month}-${day}`;
      };
      const endDate = getCurrentDate();
         const response2=`/analytics/get-institute-Ovd?endDate=${endDate}&instituteId=${id}`
      {
        axiosInstance.get(response2,{
          headers:{
            Authorization:`Bearer ${user}`
          }
        }).then((res) => {
          console.log("ovdInfo",res?.data?.data?.OvdInstitute?.institutewisebuckets?.map((item)=>item))
          setOvdBackets(res?.data?.data?.OvdInstitute?.institutewisebuckets.map((item)=>item.bucketings))
          setOvdPercentage(res?.data?.data?.OvdInstitute?.institutewisebuckets.map((item)=>item.backets_sum_amount))
          setOvdCount(res?.data?.data?.OvdInstitute?.institutewisebuckets.map((item)=>item.bucket_count))
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);

        });}
     }

  const applicationVisist=(id)=>{
    setIsLoading(true)
      const response3 = `/analytics/get-application?instituteId=${id}`
    axiosInstance.get(response3,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res) => {
      console.log("ovdInfo2",res?.data?.data?.application?.application)
      const lastSixMonths = res?.data?.data?.application?.application.slice(-12);
     setApplMonth(lastSixMonths.map((item) => item.month))
     setApplDisb(lastSixMonths.map((item) => Math.round(item.disbursed_applications)))
     setApplCount(lastSixMonths.map((item) => Math.round(item.total_applications)))
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);

    });
  

  }
  const clearDateFilter = () => {
    window.location.reload()}


    


console.log("monthasdadasdsa",months)

  return (
    <div style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'#f9fafa',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'78%':'98%', overflow:"auto",marginLeft:isOpen?"21%":'0%',backgroundColor:'#f9fafa'}}>
                <Topbar/>
       <div style={{display:'flex',justifyContent:'space-between'}}>
       <h1 style={{fontFamily:'Inter-Medium',fontSize:'25px',fontWeight:'500',padding:'10px',color:'#101828'}}>{searchText?searchText:'All Institute'} Dashboard</h1>
       </div>
    <>
      {/* <BreadCrumbs /> */}

      <div  style={{display:'flex'}}>

      <div style={{ position: "relative",  marginBottom:'20px'}}>

      <Input  onChange={(event) => {
                handleInputChange(event)              
                setInstituteName(event.target.value);
                console.log(event.target.value,'eventvalue')
                setSearchText(event.target.value);
                if (event.target.value === '') {
                  setSearchText("")
                  setInstituteName("")
                  clearDateFilter(); 
                }
              }} 
              type="text"
              placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
              value={searchText}
              className="placeholder-style"
              style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'70%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
              }}
            />

{showDropdown && Array.isArray(instiName) && instiName.length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "45px",
            width: "70%",
            backgroundColor: "white",
            border: "1px solid #cccccc",
            borderRadius: "8px",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1000,
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          {instiName?.map((item, index) => (
            <li
              key={index}
              onClick={() => handleSelect(item?.brandName)
               

              } // Replace `result.name` with the correct field
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #f0f0f0",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#f7f7f7")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "white")
              }
            >
              {item?.brandName} {/* Replace with correct field */}
            </li>
          ))}
        </ul>
      )}

</div>
            <div>
            {/* {searchClicked === true ? (
        <button style={{backgroundColor:'#D22129',display:'flex',alignItems:'center',width:'130px',height:'34px',justifyContent:'space-around',borderRadius:'5px',cursor:'pointer',paddingLeft:'10px',marginTop:'10px'}} onClick={clearDateFilter}>
                  <img src={clear} style={{height:'18px'}}/>
                  <div style={{color:'white',display:'flex',alignItems:'center'}}>Clear Filter</div>
                </button>
      ):( */}
        <button style={{backgroundColor:'#D22129',display:'flex',alignItems:'center',width:'130px',height:'34px',justifyContent:'space-around',borderRadius:'5px',cursor:'pointer',paddingLeft:'10px',marginTop:'10px',color:'white'}}
            
        onClick={() => {
          instituteInfo(id);
          instiDisbursement(id);
          instiBackets(id);
          applicationVisist(id);
        }}
            >
              Search Results
            </button>
            {/* )} */}
            </div>

            </div>

            {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                  <CircularProgress />
                </Box>
                ): ( 
                 
                <><TopCards instituteDetails={instituteDetails} ovdCount={ovdCount} ovdPercentage={ovdPercentage}  /><Row style={{ marginTop: '20px' }}>
                <Col lg="8">
                  <SalesOverview totalSales={totalSales} thisMonthSales={thisMonthSales} thisWeekSales={thisWeekSales} months={months} disbursementAmounts={disbursementAmounts} />
                </Col>
                {/* <Col lg="3">
                  <Blog2 />
                  </Col> */}
                <Col lg="4">
                  <VisitSeparation ovdBackets={ovdBackets} ovdCount={ovdCount} ovdPercentage={ovdPercentage} />
                </Col>

              
              </Row>
              
                 <Row>
                  <Col lg="4">
                    <Gmail instituteDetails={instituteDetails}  />          
                    <Facebook instituteDetails={instituteDetails}  />
                    <Twitter instituteDetails={instituteDetails} />
                  </Col>
                  <Col lg="8">
                    <WebsiteVisit applCount={applCount}   applmonth={applmonth}  applDisb={applDisb} applRate={applRate} />
                  </Col>
                </Row>
              
              
              
              </>)}
    
      {/* <Row>
        <Col lg="4">
          <Gmail />          
          <Facebook />
          <Twitter />
        </Col>
        <Col lg="8">
          <WebsiteVisit />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <ProjectMonth />
        </Col>
        <Col lg="6">
          <Activity />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <RecentComments />
        </Col>
        <Col lg="6">
          <RecentChat />
        </Col>
      </Row> */}
    </>

    </div>
    </div>
  );
};

export default Demographical;
