import { Button, Card, CardBody } from 'reactstrap';
import NTCicon from '../../assets/images/institute Dashboard Icons/NTCimages.png'

const Twitter = ({instituteDetails}) => {
  return (
    <Card   style={{marginTop:'20px' ,backgroundColor:'#4b8bf3',color:'white',borderRadius:'8px',border:'1px solid #d4d6d5'}}>
      <CardBody>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div className="stats">
          <h1 className="text-dark-white">{instituteDetails?.map((item) =>( item?.count_score? <>{(Math.round(item?.count_score) )}%</>: '-%'))}</h1>
            <h6 className="text-dark-white mb-0">NTC percentage</h6>
            {/* <Button color='light' className="rounded-pill mt-3">Check list</Button> */}
          </div>
          <div style={{alignContent:'right'}}>
          <img 
                src={NTCicon} 
                alt="Credit Score" 
                className="display-3 op-3 text-dark" 
                style={{ width: '50px', height: '50px' }} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Twitter;
