import React, { useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import close from "../../assets/images/close.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import BankImg from "../../assets/images/bankImg.png"
import { userActions } from '../../actions/user.actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input, FormText,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import axiosInstance from '../../helpers/axios';

//import { useUserAuth } from '../../views/auth/AuthContext.js';


export default function AddBank(InstituteId) {

    const editstate = useSelector((state) => state.Editform?.res);
    const savedstate = useSelector((state)=> state.Updateeditform?.res)
    console.log("before dispatch",editstate)
    const [formOpen, setFormOpen] = useState(false);
    
    const dispatch = useDispatch();

  const location = useLocation();

  //gst
  const [gstApplicableCheck, setGSTApplicableCheck] = useState(false);
  const [gstNumber, setGSTNumber] = useState('');
  const [updateGstNumber, setUpdateGSTNumber] = useState('');
  //add bank details
  const [bankDetails,setBankDetails] = useState([])
  const [insAccountHolderName, setAccountHolderName] = useState('');
  const [insAccountNumber, setAccountNumber] = useState('');
  const [insIFSCCode, setIFSCCode] = useState('');
  const [insBankName, setBankName] = useState('');
  const [insBranchName, setBranchName] = useState('');
  const [cancelledCheque, setCancelledCheque] = useState('');
  const [insAccountType, setAccountType] = useState('');
  const [insRazorpayFundAccountId, setRazorpayFundAccountId] = useState('');
  const [insRazorpayContactId, setRazorpayContactId] = useState('');
  //const { user } = useUserAuth();
  const [instituteBranches,setInstituteBranches] = useState([])
  const [selectedBranchId, setSelectedBranchId] = useState('');

  const handleBranchChange = (e) => {
    const branchId = e.target.value;
    const selectedBranch = instituteBranches.find(branch => branch.branchId === branchId);

    setSelectedBranchId(branchId);
  };

  
  const getInstituteBranchesListApi = async () => { 
    axiosInstance.get(`/institute-branches/info/instituteId?instituteId=${InstituteId?.InstituteId?.id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then( (res)=> {
        
        const branchesList = res?.data?.data?.branches
        console.log("branches list ",res?.data?.data?.branches)
        setInstituteBranches(branchesList)
      })
      .catch(function (error) {
        console.log(error);
      });
    }
 
  
  const user = sessionStorage.getItem("user")
  
  const getBankInfo = `/bank-info/id?id=${InstituteId?.InstituteId?.id}`;

  const callBankDetails=()=>{
    // if (
    //   !insAccountHolderName ||
    //   !insAccountNumber ||
    //   !insIFSCCode ||
    //   !insBankName ||
    //   !insBranchName ||
    //   !cancelledCheque ||
    //   !insAccountType ||
    //   !insRazorpayFundAccountId ||
    //   !insRazorpayContactId
    // ) {
    //   alert("All fields are mandatory. Please fill in all required fields.");
    //   return;
    // }
    if(cancelledCheque){
      const file=new FormData();
    file.append('file',cancelledCheque)
    file.append('type',"cancelled_cheque")
    axiosInstance.post(`/file/upload`,file)
    .then((res)=>{
    
    const saveBankDetailsUrl=`https://institute-apis.feemonk.com/profile/bank-profile`

    const saveSqlBankDetailsUrl=`/bank-info/create`

    const obj4= {
      instituteId: InstituteId?.InstituteId?.id,
      accountHolderName: insAccountHolderName?insAccountHolderName:document.getElementById('accountHolderName').value,
      bankName: insBankName?insBankName:document.getElementById('accountNumber').value,
      branch: insBranchName?insBranchName:document.getElementById('branchName').value,
      accountType: insAccountType?insAccountType:document.getElementById('accountType').value,
      accountNumber: insAccountNumber?insAccountNumber:document.getElementById('accountNumber').value,
      ifscCode: insIFSCCode?insIFSCCode:document.getElementById('ifscCode').value,
      cancelledCheque: res.data.data.s3Url,
      razorpayFundAccountId: insRazorpayFundAccountId?insRazorpayFundAccountId:document.getElementById('razorpayFundAccountId').value,
      razorpayContactId: insRazorpayContactId?insRazorpayContactId:document.getElementById('razorpayContactId').value,
      branchId:selectedBranchId

    }
    dispatch(userActions.callBankdetails(saveSqlBankDetailsUrl,obj4,user));
    
      const obj3=new FormData();  
      
        obj3.append('account_holder_name',insAccountHolderName);
        obj3.append( 'account_number',insAccountNumber);
        obj3.append('ifsc_code',insIFSCCode)
        obj3.append('bank_name',insBankName)
        obj3.append('branch_name',insBranchName)
        obj3.append('file',cancelledCheque)

        dispatch(userActions.callbankdetails(saveBankDetailsUrl,obj3,user));

    })
    .catch((error) => {
      console.error(error);
    });
  
    }else{
      const saveBankDetailsUrl=`https://institute-apis.feemonk.com/profile/bank-profile`

    const saveSqlBankDetailsUrl=`/bank-info/create`

    const obj4= {
      instituteId: InstituteId?.InstituteId?.id,
      accountHolderName: insAccountHolderName?insAccountHolderName:document.getElementById('accountHolderName').value,
      bankName: insBankName?insBankName:document.getElementById('accountNumber').value,
      branch: insBranchName?insBranchName:document.getElementById('branchName').value,
      accountType: insAccountType?insAccountType:document.getElementById('accountType').value,
      accountNumber: insAccountNumber?insAccountNumber:document.getElementById('accountNumber').value,
      ifscCode: insIFSCCode?insIFSCCode:document.getElementById('ifscCode').value,
      razorpayFundAccountId: insRazorpayFundAccountId?insRazorpayFundAccountId:document.getElementById('razorpayFundAccountId').value,
      razorpayContactId: insRazorpayContactId?insRazorpayContactId:document.getElementById('razorpayContactId').value,
      branchId:selectedBranchId

    }
    dispatch(userActions.callBankdetails(saveSqlBankDetailsUrl,obj4,user));
    
      const obj3=new FormData();  
      
        obj3.append('account_holder_name',insAccountHolderName);
        obj3.append( 'account_number',insAccountNumber);
        obj3.append('ifsc_code',insIFSCCode)
        obj3.append('bank_name',insBankName)
        obj3.append('branch_name',insBranchName)
        obj3.append('file',cancelledCheque)

        dispatch(userActions.callbankdetails(saveBankDetailsUrl,obj3,user));

  
    }
  }
  
  const popUpForm = () => {
    getInstituteBranchesListApi()
   
    axiosInstance.get(getBankInfo)
    .then((res) => {
        console.log("bank",res?.data?.data)
        setBankDetails(res?.data?.data)
    })
    .catch((error) => {
      console.error(error);
    });
    setFormOpen(true);
  }
  console.log("bank",bankDetails)

  return (
    <div>
        <img style={{height:'18px',width:'18px'}}  onClick={() => popUpForm()} src={BankImg} />
      {formOpen && (
      <>
     <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal 
      style={{
        position: 'fixed',
        top: '10%',
      left: '20%',
        
        backgroundColor: 'white',maxWidth: '100%'}} size="lg" className='edit-form ' isOpen={formOpen}>
          <div style={{maxHeight:'600px',overflowY:'auto'}}>
          <div style={{borderTopLeftRadius:'10px',borderTopRightRadius:"10px",display:"flex",flexDirection:'row',padding:"20px",justifyContent:"space-between",backgroundColor:"#E9E9E9",height:"50px",alignItems:"center"}}><p className='heading'>Add Bank Details</p>
          <img style={{height:'1.5em',width:'1.5em'}}  className="image1" onClick={()=>setFormOpen(!formOpen)} src={close}/>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            <Row>
              
              <Col >
                <Row>
                  <div title="Add Bank Details">
                    <Form >
                        <div style={{display:'block'}}>
                      <div style={{display:'flex',justifyContent: 'space-between',}}>
                      <FormGroup>
                        <Label htmlFor="accountHolderName"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}} >Account Holder</span> <span style={{color:'red'}}>*</span></Label>
                        <Input
                        style={{width:'20em',marginRight:'10px'}}
                        defaultValue={bankDetails ? bankDetails.accountHolderName : ''}
                          type="text"
                          name="accountHolderName"
                          id="accountHolderName"
                          placeholder="account holder name"
                          onChange={(e) => setAccountHolderName(e.target.value)}
                        />
                        
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="accountNumber"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}} >Account Number</span><span style={{color:'red'}}>*</span></Label>
                        <Input
                         defaultValue={bankDetails ? bankDetails.accountNumber  : ''}
                         style={{width:'20em'}}
                          type="number"
                          name="accountNumber"
                          id="accountNumber"
                          placeholder="Account Number"
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                      </FormGroup>
                    
                       <FormGroup>
                        <Label htmlFor="ifscCode"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}  >IFSC code</span><span style={{color:'red'}}>*</span></Label>
                        <Input
                           defaultValue={bankDetails ? bankDetails.ifscCode || '' : ''}
                           style={{width:'20em'}}
                          type="text"
                          name="ifscCode"
                          id="ifscCode"
                          placeholder="IFSC code"
                          onChange={(e) => setIFSCCode(e.target.value)}
                        />
                        
                      </FormGroup>
                      </div>
                      </div>
                      <div style={{display:'block'}}>
                        <div style={{display:'flex',justifyContent: 'space-between',}}>
                      <FormGroup>
                        <Label htmlFor="bankName"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Bank Name</span><span style={{color:'red'}}>*</span></Label>

                        <Input
                           style={{width:'20em'}}
                           defaultValue={bankDetails ? bankDetails.bankName || '' : ''}
                          type="text"
                          name="bankName"
                          id="bankName"
                          placeholder="Bank Name"
                          onChange={(e) => setBankName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="branchName"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}} >Branch Name</span><span style={{color:'red'}}>*</span></Label>
                       
                        <Input
                         defaultValue={bankDetails ? bankDetails.branch || '' : ''}
                          style={{width:"20em"}}
                        
                          name="branchName"
                          id="branchName"
                          placeholder=" Branch Name"
                          onChange={(e) => setBranchName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="razorpayContactId"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>RazorPay Contact Id</span><span style={{color:'red'}}>*</span></Label>

                        <Input
                         defaultValue={bankDetails ? bankDetails.razorpayContactId || '' : ''}
                          style={{width:"20em"}}
                          type="text"
                          name="razorpayContactId"
                          id="razorpayContactId"
                          placeholder=" RazorPay Contact Id"
                          onChange={(e) => setRazorpayContactId(e.target.value)}
                        />
                      </FormGroup>
                      </div>
                      </div>
                      <div style={{display:'block'}}>
                        <div style={{display:'flex',justifyContent: 'space-between',}}>
                        <FormGroup>
                        <Label htmlFor="razorpayFundAccountId"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>RazorPay Fund Account Id</span><span style={{color:'red'}}>*</span></Label>

                        <Input
                        defaultValue={bankDetails ? bankDetails.razorpayFundAccountId || '' : ''}
                          style={{width:"20em"}}
                          type="text"
                          name="razorpayFundAccountId"
                          id="razorpayFundAccountId"
                          placeholder=" RazorPay Fund Account Id"
                          onChange={(e) => setRazorpayFundAccountId(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="accountType"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}} >Account Type</span><span style={{color:'red'}}>*</span></Label>

                        <Input 
                        defaultValue={bankDetails ? bankDetails.accountType || '' : ''}
                          style={{width:"20em"}}
                          type="text"
                          name="accountType"
                          id="accountType"
                          placeholder=" Current Account or Savings Account"
                          onChange={(e) => setAccountType(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="logo"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Upload Cancelled Cheque</span></Label>

                        <Input
                          style={{width:"20em"}}
                          type="file"
                          name="cancelledCheque"
                          id="cancelledCheque"
                          
                          onChange={(e) => setCancelledCheque(e.target.files[0])}
                        />
                      </FormGroup>
                        </div>
                      </div>
                      <div>
                      <FormGroup>
      <Label htmlFor="instituteBranch">
        <span style={{ fontFamily: 'Inter-Medium', fontSize: '16px', fontWeight: '600' }}>Institute Branch</span>
        <span style={{ color: 'red' }}>*</span>
      </Label>
      <Input
        type="select"
        name="instituteBranch"
        id="instituteBranch"
        value={selectedBranchId}
        onChange={handleBranchChange}
        style={{ width: '20em' }}
      >
        <option value="" disabled>Select Institute Branch</option>
        {instituteBranches.map(branch => (
          <option key={branch.branchId} value={branch.branchId}>
            {branch.branchName}
          </option>
        ))}
      </Input>
      </FormGroup>
                        
                      </div>
                     
                      <Button style={{marginLeft:'40%',width:'10em',backgroundColor:'#D32028',marginTop:'50px',border:'none'}}
                        onClick={() => callBankDetails()}
                        color="primary"
                      >
                     <span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'500', textTransform: 'capitalize',color:'white'}}>Save Details</span>
                      </Button>
               
                    </Form>

                  </div>
                </Row>
              </Col>
            </Row>
            </FormGroup>
          </Form>
        </ModalBody>
       
          </div>
          
        
      </Modal>
      </>
       )}
    </div>
  );
}


