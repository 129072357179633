import { combineReducers } from "redux";
import { authentication,authenticationOtp,sendOtp } from "./authentication.reducer";
import {Inprogress} from "./inprogress.user.reducer";
import { Insubmission } from "./insubmission.reducer";
import {Indisbursed} from "./indisbursed.user.reducer";
import {Pendingindisburse} from "./pendingindisbursment.user.reducer";
import { Insanction } from "./insanction.user.reducer";
import { Inrejected } from "./inrejected.user.reducer";
import {Inreview} from "./inreview.user.reducer";
import { Indropped } from "./indropped.user.reducer";
import {Readytodisburse} from "./readytodisburse.user.reducer";
import { Onhold } from "./onhold.user.reducer";
import {Datefilter} from "./datefilter.user.reducer";
import { Handleuser } from "./handleuser.user.reducer";
import { Handlecoappuser } from "./handlecoapp.user.reducer";

import {Editform} from "./editformdetails.reducer";
import {Disbursedstatuschange} from "./disbursedstatuschange.user.reducer"
import { Droppedstatuschange } from "./droppedstatuschange.user.reducer";
import {Updatestatus} from "./updatestatus.user.reducer";
import {Reviewstatuschange} from "./reviewstatuschange.user.reducer";
import {Updateeditform} from './updateeditformdetails.user.reducer';
import {Handleapprove} from "./handleapprovalform.user.reducer";
import {Handleapproveapi} from "./handleapproveapi.user.reducer";
import {ColenderFundSource} from "./fetchcolenderfundsource.reducer";
import {Fundcode} from "./fetchfundcode.reducer";
import {Handleapproveapifund} from "./fetchhandleapproveapifund.reducer";
import {Getaadharfront} from "./getaadharfront.user.reducer";
import {Getaadharback} from "./getaadharback.user.reducer";
import {Pandetails} from "./fetchpan.user.reducer";
import {Addcoapplicant} from "./addcoapplicant.reducer";
import {Bankselfiedetails} from "./fetchbankselfie.user.reducer";
import {Applicationdetails} from "./fetchapplicationdetails.user.reducer";
import {Emidetails} from "./fetchemiurl.user.reducer"
import {GETINSTITUTE,CREATEPRODUCT,GETPRODUCTS} from './products.reducer'
import {POSTACTIVITY} from './postActivity.reducer'
import {getNotifications,deleteReminder} from './notifications.reducer'
import {Loans} from './fetchloans.user.reducer'
import {Repayments} from './fetchrepayments.user.reducer'
import {Bankdetails} from './updatingbankdetails.reducer'
import {BankDetails} from './updatebankdetails.reducer'
import {InstitueDetails} from './updateinstitutedetails.reducer'
import {callBankdetails} from './callbankdetails.reducer'
import {callingBankdetails} from './callingbankdetails.reducer'
import {callRegdetails} from './callregistration.reducer'
import {createInst} from './createinstitute.reducer'
import { UserSearch } from "./usersearch.reducer";
import { UserSearchDefault } from "./usersearchdefault.reducer"
import { UserSearchIns } from "./usersearchins.reducer";
import { LoanDetails } from "./loandetails.reducer";
import { LoansCount } from "./fetchloancount.reducer";
import { Calldetails } from "./fetchcalldetails.user.reducer";
import { generateManualPaymentsReceipt } from "./generateManualPaymentsReceipt.reducer";
import { createReminder,getReminder } from "./reminder.reducer";
import { getEmiHistory } from "./emiHistory.reducer";
import { Reports } from "./getreports.reducer";
import { Institutes } from "./fetchinstitute.reducer";
import {ACTIVITY} from "./activity.reducer";
import {uploadFile} from "./uploadFile.reducer";
import { Products } from "./fetchproducts.reducer";
import { Filteredusers } from "./fetchfilteredusers.reducer";
import { createApplication } from "./createApplication.reducer";
import { createBijayApplication } from "./createBijayApplication.reducer";
import {CKYC,DIGILOCKER,PAN,BANKSELFIE,PROFILE,UPLOADEDFILES,CIBIL,POSTINCOMEPDF,GETINCOMEPROFILE,POSTFOIR,GETFOIRDETAILS,PANPRO,CIBILSOFT} from "./individualProfile.reducer";


const rootReducer = combineReducers({

  authentication: authentication,
  authenticationOtp: authenticationOtp,
  sendOtp: sendOtp,
  Inprogress : Inprogress,
  Insubmission: Insubmission,
  Indisbursed: Indisbursed,
  Pendingindisburse: Pendingindisburse,
  Insanction: Insanction,
  Inrejected: Inrejected,
  Inreview: Inreview,
  Indropped: Indropped,
  Readytodisburse:Readytodisburse,
  Onhold:Onhold,
  Datefilter:Datefilter,
  Handleuser: Handleuser,
  Handlecoappuser: Handlecoappuser,
  createApplication:createApplication,
  createBijayApplication: createBijayApplication,
  Editform: Editform,
  Disbursedstatuschange:Disbursedstatuschange,
  Droppedstatuschange:  Droppedstatuschange,
  Updatestatus:Updatestatus,
  Reviewstatuschange:Reviewstatuschange,
  Updateeditform:Updateeditform,
  Handleapprove:Handleapprove,
  Handleapproveapi:Handleapproveapi,
  ColenderFundSource:ColenderFundSource,
  Fundcode:Fundcode,
  Handleapproveapifund:Handleapproveapifund,
  Getaadharfront:Getaadharfront,
  Getaadharback:Getaadharback,
  Pandetails:Pandetails,
  Addcoapplicant:Addcoapplicant,
  Bankselfiedetails:Bankselfiedetails,
  Applicationdetails:Applicationdetails,
  Emidetails:Emidetails,
  CKYC : CKYC,
  DIGILOCKER : DIGILOCKER,
  PAN : PAN,
  PANPRO:PANPRO,
  PROFILE:PROFILE,
  UPLOADEDFILES:UPLOADEDFILES,
  BANKSELFIE : BANKSELFIE,
  CIBIL:CIBIL,
  CIBILSOFT:CIBILSOFT,
  ACTIVITY:ACTIVITY,
  POSTACTIVITY:POSTACTIVITY,

  Loans:Loans,
  createReminder:createReminder,
  Repayments:Repayments,
  generateManualPaymentsReceipt:generateManualPaymentsReceipt,
  POSTINCOMEPDF:POSTINCOMEPDF,
  GETINCOMEPROFILE:GETINCOMEPROFILE,
  POSTFOIR:POSTFOIR,
  GETFOIRDETAILS:GETFOIRDETAILS,
  GETINSTITUTE:GETINSTITUTE,
  CREATEPRODUCT:CREATEPRODUCT,
  GETPRODUCTS:GETPRODUCTS,
  Bankdetails:Bankdetails,
  BankDetails:BankDetails,
  InstitueDetails:InstitueDetails,
  callBankdetails:callBankdetails,
  callingBankdetails:callingBankdetails,
  callRegdetails:callRegdetails,
  createInst:createInst,
  UserSearch:UserSearch,
  UserSearchDefault:UserSearchDefault,
  UserSearchIns:UserSearchIns,
  LoanDetails:LoanDetails,
  LoansCount:LoansCount,

  Calldetails:Calldetails,

  getNotifcations:getNotifications,
  getEmiHistory:getEmiHistory,
  uploadFile:uploadFile,
  getReminder,
  Reports:Reports,

  Institutes:Institutes,


  deleteReminder:deleteReminder,
  Products:Products,
  Filteredusers:Filteredusers

 
});

export default rootReducer;