import { Button, Card, CardBody } from 'reactstrap';
import credit from '../../assets/images/institute Dashboard Icons/image.png'

const Gmail = ({instituteDetails}) => {
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }
  return (
    <Card style={{marginTop:'5px',backgroundColor:'#44dc9c',color:'white',borderRadius:'8px',border:'1px solid #d4d6d5'}} >
      <CardBody>
        <div style={{display:'flex', justifyContent:'space-between'}} >
          <div >
            <h1 className="text-dark-white">{instituteDetails?.map((item) =>( item?.AVG_cibil? <>{(Math.round(item?.AVG_cibil) )}</>: '-'))}</h1>
            <h6 className="text-dark-white mb-0">Avg Cibil</h6>
             {/* <Button color='light' className="rounded-pill mt-3">Check list</Button> */}
           
          </div>
          <div style={{alignContent:'right'}}>
          <img 
  src={credit} 
  alt="Credit Score" 
  className="display-3 op-3 text-dark" 
  style={{ width: '50px', height: '50px' }} 
/>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Gmail;
