
import { useState, useEffect,useCallback  } from 'react';
import activedot from "../../assets/images/activeDot.png"
import closeddot from "../../assets/images/closedDot.png"
import initiateddot from "../../assets/images/initiatedDot.png"

import axiosInstance from '../../helpers/axios';

import "./Emis.css"



const EmiDetails = ({item}) =>{
    const [repaymentTable,setRepaymentTable] = useState([])
    const user = sessionStorage.getItem("user")
    console.log("items",item)

    useEffect(()=>{
        const url = `/loan-repayment/emi-repayments?emiId=${item?.emiId}`

        axiosInstance.get(url,{
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }).then((res)=>{
          setRepaymentTable(res?.data?.data)
        })
    
    },[])


return(
    <>

                    <div className='tables1' >
                    <table hover  style={{width:'100%'}}>
                    <thead >
                    <tr style={{height:'30px',backgroundColor:'#F9FAFB'}}>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'20px',borderTopLeftRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Date Created</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Amount Requested</span></th>
                    {/* <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',width:'10em' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>InstName</span></th> */}
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085' ,paddingLeft:'10px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Amount Paid.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Payment Mode</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',paddingLeft:'10px' ,borderTopRightRadius:"8px" }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Status</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',width:'10em'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap',width:'10em'}}>Reason for Fail.</span></th>
                    
                    </tr>
                    </thead>
                    <tbody>
                      {repaymentTable.map((item,index)=>(
                        <>
                         <tr >
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.createdAt.substr(0, 10)}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	₹{item?.amount}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	₹{item?.amount}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}> 
                          {item.paymentMode ? (
                            item.paymentMode === 1? (
                              <div title='E Mandate' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>EM</div>
                            ) : item.paymentMode === 2 ? (
                              <div title='UPI' style={{fontSize:'12px',backgroundColor:'#F8A31F',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>U</div>
                            ) :item.paymentMode === 3 ? (
                              <div title='Manual' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>M</div>
                            ) :item.paymentMode === 4 ? (

                              <div title='Manual' style={{fontSize:'12px',backgroundColor:'#ee957d',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>DM</div>
                            ):item.paymentMode === 5 ? (

                              <div title='Manual' style={{fontSize:'12px',backgroundColor:'#f9ea81',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>CP</div>
                            ):item.paymentMode === 6 ? (

                              <div title='Manual' style={{fontSize:'12px',backgroundColor:'#c4f981',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>CM</div>
                            ):item.paymentMode === 7 ? (

                              <div title='Manual' style={{fontSize:'12px',backgroundColor:'#81dff9 ',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',color:'black'}}>Bbps</div>
                            ):
                             (
                              item.paymentMode
                            )
                          ) : (
                            "-"
                          )}
                          </td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}>
                      {item.status?
                            item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'1em',padding:'5px',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}><img style={{marginRight:'5px'}} src={initiateddot}/>Initiated</span>
                            :item.status==2?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'1em',padding:'5px',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}><img style={{marginRight:'5px'}} src={initiateddot}/>Created</span>
                            :item.status==3?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'1em',padding:'5px',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}><img style={{marginRight:'5px'}} src={activedot}/>Successful</span>
                            :item.status==4?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'1em',padding:'5px',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}><img style={{marginRight:'5px'}} src={closeddot}/>Failed</span>
                            :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'1em',padding:'5px',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}><img style={{marginRight:'5px'}} src={closeddot}/>Int.Failed</span>
                            :item.status
                            :'-'}
                      </td>
                      <td title={item?.failureReason} style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',width:'10em' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'10em'}}>	{item?.failureReason}</span></td>

                      </tr>
                      {index !== repaymentTable.length - 1 && (
        <tr>
          <td colSpan="12" style={{ borderTop: '1px solid #ccc' }}></td>
        </tr>
      )}    
                        </>
                      ))}
                     
                    </tbody>
                   </table>
                    </div>

    </>
)
}

export default EmiDetails