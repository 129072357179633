import React, { useState } from 'react';
import resend from "../../../assets/images/resend.png"
import axios from 'axios';
 
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Spinner,
  FormGroup,
} from 'reactstrap';
import jwt_decode from 'jwt-decode'
import "./SendSms.css"
import close from "../../../assets/images/close.png";

import message from "../../../assets/images/message.png"
import axiosInstance from '../../../helpers/axios';
//import { useUserAuth } from '../../views/auth/AuthContext.js';


export default function SendSms(ApplicantId) {

  //const { user } = useUserAuth();
  const user = sessionStorage.getItem("user")
  const [smsModal,setSMSModal]=useState(false)
const decode=jwt_decode(user)
const handleSendSms=()=>{
    
    const urlBankStatement=`/account-aggregator/triggerSms`
  const data={
    userId:ApplicantId.ApplicantId.userId,
    applicationId:ApplicantId.ApplicantId.applicationId
  }
  
    axiosInstance.post(urlBankStatement,data,
        {
      headers:
      {
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
      if(res.data.message)
      {
       alert("SMS has been sent to Applicant Phone Number");
      }
      
    })
    setSMSModal(!smsModal)
  }

  return (
  <>
     {smsModal && (
     <>
     <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
     <Modal style={{
      
      height:'200px',
      top: '25%',
   
      
      
    }} className="sms"isOpen={smsModal}>
      
     <img style={{marginRight:"10px",marginLeft:"370px",height:'18px',width:'18px',marginTop:'10px',cursor:"pointer"}}   onClick={()=>setSMSModal(!smsModal)} src={close}/>
     <div><img className="image" src={message} alt="message" /></div>
            <div>
              <p style={{marginLeft:"13px",marginBottom:"10px"}}>On Clicking <strong> Resend Consent </strong> an SMS will be sent to the <span style={{color:'black',textDecoration:"underline"}}>Applicant registered mobile number<br/></span>
              <span style={{marginLeft:"70px"}}>Requesting the Bank Statement</span> 
               </p>
              </div>
           
              <div >
              <button style={{marginTop:"20px",marginLeft:'100px',width:"200px",marginBottom:"30px",backgroundColor:"#D32028",color:"white",border:"none",height:'30px',borderRadius:"5px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} color="primary" onClick={()=>handleSendSms()} type="button">Request Bank Statement</button>
              </div>
        
      </Modal>
    
    </>
    )}
    <div onClick={()=>setSMSModal(!smsModal)} >
     <div>
     <img  style={{cursor:'pointer',width:'1.1vw'}} src={resend} alt="resend consent"/>
       </div>
    </div>
    </>
  );
}

