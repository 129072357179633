import React, { useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import close from "../../assets/images/close.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import edit from "../../assets/images/edit.png"
import { userActions } from '../../actions/user.actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input, FormText,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import axiosInstance from '../../helpers/axios';
import { Box } from '@mui/material';
import './index.css'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MuiButton from '@mui/material/Button';

export default function ManageInstitutes(InstituteId) {
    const editstate = useSelector((state) => state.Editform?.res);
    const savedstate = useSelector((state)=> state.Updateeditform?.res)
    console.log("before dispatch",editstate)
    const [formOpen, setFormOpen] = useState(false);
    const [updateInstituteName,setUpdateInstituteName]=useState('');
    const [updateInstituteCode,setUpdateInstituteCode]=useState('');
    const [updateInstituteEmail,setUpdateInstituteEmail]=useState('');
    const [gstApplicableCheck, setGSTApplicableCheck] = useState(false);
    const [updateInstituteContact,setUpdateInstituteContact]=useState('');
    const [updateInstituteLogo,setUpdateInstituteLogo]=useState('');
    const [updateInstituteAddress,setUpdateInstituteAddress]=useState('');
    const [updateInstituteState,setUpdateInstituteState]=useState('');
    const [updateInstituteCity,setUpdateInstituteCity]=useState('');
    const [updateInstituteGst,setUpdateInstituteGst]=useState('');
    const [updateInstitutePincode,setUpdateInstitutePincode]=useState('');
    const [selectedInstitute,setSelectedInstitute]=useState(null)
    const [updateGstNumber, setUpdateGSTNumber] = useState('');
    const [savedFileEntries, setSavedFileEntries] = useState([{}])
    const [fileEntries, setFileEntries] = useState([{ fileName: '', file: null, url:null }])
    const [viewFile, setViewFile] = useState(false)
    const [fileUrl, setFileUrl] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
      if (InstituteId?.InstituteId?.mouFiles) {
        try {
          if((InstituteId?.InstituteId?.mouFiles==='[]')){
            setSavedFileEntries([{}])
          }
          else{
            setSavedFileEntries(JSON.parse(InstituteId.InstituteId.mouFiles));
          }
        } catch (error) {
          console.error("Error parsing mouFiles", error);
        }
      }
    }, [InstituteId]);

  const location = useLocation();


  //const { user } = useUserAuth();
  
  const user = sessionStorage.getItem("user")
 
const updateDetailsOfInstitute=()=>{
  const totalFiles = [...savedFileEntries, ...fileEntries];
  const mouFiles = totalFiles.filter(obj => 
      obj.fileName || obj.url || obj.file
  );

  if(updateInstituteLogo)
  {

    const file=new FormData();
   file.append('file',updateInstituteLogo)
   file.append('type',"logo")
   axiosInstance.post(`/file/upload`,file)
   .then((res)=>{
   const updateInstituteDetailsUrl=`/institute/info/edit`

   const updateObj={
    
          id: InstituteId?.InstituteId?.id,
          code: updateInstituteCode?updateInstituteCode:document.getElementById("insCode").value,
          name: updateInstituteName?updateInstituteName:document.getElementById("insName").value,
          email: updateInstituteEmail?updateInstituteEmail:document.getElementById("insEmail").value,
          contact: updateInstituteContact?updateInstituteContact:document.getElementById("insContact").value,
          address: updateInstituteAddress?updateInstituteAddress:document.getElementById("insAddress").value,
          city: updateInstituteCity?updateInstituteCity:document.getElementById("insCity").value,
          gstNumber: updateGstNumber ? updateGstNumber : (document.getElementById("insGst") ? document.getElementById("insGst").value : null),
          // gstNumber: updateGstNumber?updateGstNumber:document.getElementById("insGst").value,

          state: updateInstituteState?updateInstituteState:document.getElementById("insState").value,
          pincode: updateInstitutePincode?updateInstitutePincode:document.getElementById("insPincode").value,
          logo: res?.data?.data?.s3Url,
          mouFiles: JSON.stringify(mouFiles)
      
   }
   dispatch(userActions.updateInstitutedetails(updateInstituteDetailsUrl,updateObj,user));
 })
  }
  else
  {
     const updateInstituteDetailsUrl=`/institute/info/edit`

      const updateObj={
          id: InstituteId?.InstituteId?.id,
          code: updateInstituteCode?updateInstituteCode:document.getElementById("insCode").value,
          name: updateInstituteName?updateInstituteName:document.getElementById("insName").value,
          email: updateInstituteEmail?updateInstituteEmail:document.getElementById("insEmail").value,
          contact: updateInstituteContact?updateInstituteContact:document.getElementById("insContact").value,
          address: updateInstituteAddress?updateInstituteAddress:document.getElementById("insAddress").value,
          city: updateInstituteCity?updateInstituteCity:document.getElementById("insCity").value,
          gstNumber: updateGstNumber ? updateGstNumber : (document.getElementById("insGst") ? document.getElementById("insGst").value : null),
          gstApplicable: updateGstNumber ||document.getElementById("insGst").value?true:false,
          state: updateInstituteState?updateInstituteState:document.getElementById("insState").value,
          pincode: updateInstitutePincode?updateInstitutePincode:document.getElementById("insPincode").value,
          mouFiles: JSON.stringify(mouFiles)
          
      }
      dispatch(userActions.updateInstitutedetails(updateInstituteDetailsUrl,updateObj,user));

   
  }
}
  
  const popUpForm = () => {
    setFormOpen(true);
  }

  const handleViewFile = (s3Url) => {
    const extractedString = s3Url?.substring(s3Url.indexOf("MOU"));
    const url = `/file/url-content?path=${extractedString}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setFileUrl(res?.data?.data)
          })
        .catch(e => {
          alert(e)
        })
    setViewFile(!viewFile)
  }

  const getS3FileUrl = async (newFile) => {
    const file=new FormData();
    file.append('file',newFile)
    file.append('type',"MOU")
    try {
        const response = await axiosInstance.post(`/file/upload`,file)
        const s3Url = await response.data.data.s3Url
        return s3Url

        
    } catch (error) {
        alert(`${error}, Please Reupload MOU`)
    }
  }

  const handleDeleteFile = (index) => {
    const updatedFiles = savedFileEntries.filter((item, i) => i !== index)
    if(updatedFiles?.length === 0){
      setSavedFileEntries([{}])
      return
    }
    setSavedFileEntries(updatedFiles)
  }

const handleFileNameChange = (index, value) => {
  setFileEntries((prevEntries) =>
    prevEntries.map((entry, i) =>
      i === index ? { ...entry, fileName: value } : entry
    )
  );
};

const handleAddMoreFiles = () => {
  setFileEntries([...fileEntries, { fileName: '', file: null, url:null }]);

}

const handleRemoveFiles =(index) => {
  setFileEntries((prevEntries) => prevEntries.filter((_, i) => i !== index));
}

const handleFileChange = async (index, file) => {
  const url =  await getS3FileUrl(file)
  setFileEntries((prevEntries) =>
    prevEntries.map((entry, i) =>
      i === index ? { ...entry, file, url } : entry
    )
  );
};

const hanldeClearFile = () => {
  setFileEntries([{}])
}

  return (
    <div>
     <img style={{height:'16px',width:'16px'}} onClick={() => popUpForm()} src={edit}/>
      {formOpen && (
      <>
     <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal 
      style={{
         
        top: '10%',
       
        backgroundColor: 'white',maxWidth: '100%'}} size="lg" className='edit-form ' isOpen={formOpen}>
          <div style={{maxHeight:'600px',overflowY:'auto'}}>
          <div style={{borderTopLeftRadius:'10px',borderTopRightRadius:"10px",display:"flex",flexDirection:'row',padding:"20px",justifyContent:"space-between",backgroundColor:"#E9E9E9",height:"50px",alignItems:"center"}}><p className='heading'>Manage Institute</p>
          <img style={{height:'1.5em',width:'1.5em'}}  className="image1" onClick={()=>setFormOpen(!formOpen)} src={close}/>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            <div>
                      <div style={{display:'flex',justifyContent: 'space-between',}}>
                        <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Name</p>
                            <Input style={{width:'20em',marginRight:'1em'}} id="insName" placeholder="Update Institute Name" defaultValue={InstituteId?.InstituteId?.brandName || ''} onChange={(e)=>setUpdateInstituteName(e.target.value)}/>
                        </div>
                        
                        <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Code</p>
                            <Input style={{width:'20em',marginRight:'1em'}} id="insCode" placeholder="Update Institute Name" defaultValue={InstituteId?.InstituteId?.code || ''} onChange={(e)=>setUpdateInstituteCode(e.target.value)}/>
                        </div>
                        <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Email</p>
                            <Input style={{width:'20em'}} id="insEmail" placeholder="Update Institute Email" defaultValue={InstituteId?.InstituteId?.email || ''} onChange={(e)=>setUpdateInstituteEmail(e.target.value)}/>
                        </div>
                       
                         
                            
                      </div>
                      <div style={{display:'flex',justifyContent: 'space-between',marginTop:'2em'}}>
                      <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Contact</p>
                            <Input style={{width:'20em'}} id="insContact" placeholder="Update Institute Contact" defaultValue={InstituteId?.InstituteId?.phone || ''} onChange={(e)=>setUpdateInstituteContact(e.target.value)}/>
                        </div>
                        
                        <div style={{display:'block'}}>
                        <p style={{fontWeight:'600'}}> Institute Address</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insAddress" placeholder="Update Institute Address" defaultValue={InstituteId?.InstituteId?.address || ''} onChange={(e)=>setUpdateInstituteAddress(e.target.value)}/>
                        </div>
                        <div style={{display:'block'}}>
                        <p style={{fontWeight:'600'}}> Institute City</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insCity" placeholder="Update Institute City" defaultValue={InstituteId?.InstituteId?.city || ''} onChange={(e)=>setUpdateInstituteCity(e.target.value)}/>
                        </div>
                       
                         
                            
                      </div>
                      <div style={{display:'flex',justifyContent: 'space-between',marginTop:'2em'}}>
                      <div style={{display:'block'}}>
                      <p style={{fontWeight:'600'}}> Institute State</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insState" placeholder="Update Institute State" defaultValue={InstituteId?.InstituteId?.state || ''} onChange={(e)=>setUpdateInstituteState(e.target.value)}/>
                        </div>
                        
                        <div style={{display:'block'}}>
                        <p style={{fontWeight:'600'}}> Institute Pincode</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insPincode" placeholder="Update Institute Pincode" defaultValue={InstituteId?.InstituteId?.pincode || ''} onChange={(e)=>setUpdateInstitutePincode(e.target.value)}/>
                        </div>
                       
                        <div style={{display:'block'}}>
                         
                            <p style={{fontWeight:'600'}}> Institute Logo</p>
                            <Input type="file"style={{width:'20em'}} placeholder="Update Institute Logo" id="logo"  defaultValue={selectedInstitute?.logo} onChange={(e)=>setUpdateInstituteLogo(e.target.files[0])}/>
                            {/* {InstituteId?.logo?<img style={{width:'10em',height:'10em',}} src={InstituteId?.logo} alt="logo"/>:null} */}
                            
                        </div>
                        
                      </div>
                      <div>
                        
                        {
                          <>
                          <Label htmlFor="logo"><span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>MOU</span></Label>
                          <MuiButton variant='outlined' sx={{textTransform:'none', ml:3}} size='small' color='secondary' onClick={hanldeClearFile}>Clear</MuiButton>
                          <div>
                            {fileEntries?.map((entry, index) => {
                              return (
                                <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                                  <Input
                                    type="text"
                                    placeholder="Enter File Name"
                                    value={entry.fileName}
                                    onChange={(e) => handleFileNameChange(index, e.target.value)}
                                    style={{ maxWidth: 230, marginRight: '10px' }}
                                  />
                                  <Input 
                                    type="file"
                                    name="file1"
                                    id="logo"
                                    placeholder="Institute Contact here"
                                    onChange={(e) => handleFileChange(index, e.target.files[0])}
                                    style={{ marginLeft:'5px',width: 300,}}
                                    
                                  />
                                  {index !==0 && <RemoveCircleOutlineIcon sx={{cursor:'pointer'}} onClick={() => handleRemoveFiles(index)}  fontSize='small'/>}
                                  <AddCircleIcon sx={{cursor:'pointer'}} onClick={handleAddMoreFiles} fontSize='small'/>
                                </Box>
                              )
                            })}
                          </div>
                        {
                          Object.keys(savedFileEntries[0]).length > 0 &&
                          <>
                          {
                          savedFileEntries?.map((entry, index)=> {
                            return(
                              <Box className='file-card'>
                              <p>{`Uploaded Files - ${entry?.fileName}`}</p>
                              <VisibilityOutlinedIcon  onClick={() => handleViewFile(entry?.url)} sx={{cursor:'pointer'}}/>
                              <DeleteIcon fontSize='small' onClick={() => handleDeleteFile(index)} sx={{cursor:'pointer', m:0}}/>
                              {
                                viewFile && (
                                    <Modal isOpen={viewFile}  size="xl" style={{height:'100%'}}>  
                                      <ModalHeader toggle={() => handleViewFile(entry?.url)}>{entry?.fileName}</ModalHeader>
                                      <ModalBody >
                                      <iframe
                                          src={fileUrl}
                                          width="100%"
                                          height="600vh"
                                          style={{ border: 'none' }}
                                        ></iframe>
                                      </ModalBody>
                                    </Modal>
                                )
                              }
                              </Box>
                            )
                          })
                        }
                          </>
                        }
                        
                        </>
                        }
                        
                      </div>
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div>
                          <p style={{marginTop: '1em',fontWeight:'600'}}><span >GST Number</span></p>
                          
                        <Input id="insGst" defaultValue={InstituteId?.InstituteId?.gstNumber || ''} onChange={(e)=>setUpdateGSTNumber(e.target.value)} type="text" placeholder="GST Number"/> 
                          </div>
                         {InstituteId?.InstituteId?.logo ?  <img style={{width:'6em',marginRight:'10em'}} alt='logo' id="image" src ={InstituteId.InstituteId.logo}/> : null}
                        </div>
                    </div>
                    <Button style={{marginLeft:'40%',width:'10em',backgroundColor:'#D32028',border:'none'}}
                        onClick={() => updateDetailsOfInstitute()}
                      >
                     <span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'500', textTransform: 'capitalize',color:'white'}}>Update Details</span>
                      </Button>
            </FormGroup>
          </Form>
        </ModalBody>
       
          </div>
          
        
      </Modal>
      </>
       )}
    </div>
  );
}
