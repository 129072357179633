import jwt_decode from 'jwt-decode';
import Sidebar from "../../Sidebar";
import Topbar from "../../Topbar"
import chevronright from "../../../assets/images/chevron-right.png"
import loanactive from "../../../assets/images/loanactive.png"
import USER from "../../../assets/images/user1.jpg"
import truecaller from "../../../assets/images/truecaller.png"
import whatsapp from "../../../assets/images/whatsapp.png"
import gmail from "../../../assets/images/gmail.png"
import documentIcon from "../../../assets/images/document1.png"
import emihistory from "../../../assets/images/emihistory.png"
import info from "../../../assets/images/info.png"
import repayments_loan from "../../../assets/images/repayment_loan.png"
import share_loan from "../../../assets/images/share_loan.png"
import filetext from "../../../assets/images/filetext.png"
import dot from "../../../assets/images/dot.png"
import initiateddot from "../../../assets/images/initiatedDot.png"
import pendingdot from "../../../assets/images/pendingDot.png"
import activedot from "../../../assets/images/activeDot.png"
import closeddot from "../../../assets/images/closedDot.png"
import PaymentLink from "./PaymentLink";
import 'moment-timezone';
import moment from 'moment';
import createpayment from '../../../assets/images/createmanual.png'
import reminder from "../../../assets/images/reminder.png"
import callhistory from "../../../assets/images/callhistory.png"
import addcall from "../../../assets/images/addcall.png"
import close from "../../../assets/images/close.png"
import AddReminder from "./AddReminder"
import React,{ useState, useEffect } from 'react';
import ManualPayment from './ManualPayment'
import { useSelector, useDispatch } from "react-redux";
import {  ModalHeader, ModalBody, Button, Label, InputGroupAddon, InputGroupText, InputGroup ,Input,ModalFooter} from 'reactstrap';
import { parse, differenceInCalendarDays } from 'date-fns';


import AddCall from "./AddCall";
import copy from "../../../assets/images/copy.png"

import {
      Form,FormGroup,
      Modal,
  } from 'reactstrap'
import { userActions } from "../../../actions/user.actions";
import axiosInstance from '../../../helpers/axios';

const loanStatusOptions = {
  1: 'Active',
  2: 'Closed',
  4: 'WrittenOff',
  8: 'Legal'
};







function LoanProfile(){

    const user=sessionStorage.getItem('user')
    const decode=jwt_decode(user)
    const userId=decode.user_id
    const loanstate = useSelector((state) => state.LoanDetails?.res);
    const callstate = useSelector((state) => state.Calldetails?.res);
    const emiHistoryState = useSelector((state) => state?.getEmiHistory?.res?.data?.data);
    const getReminderState=useSelector((state)=>state?.getReminder?.res?.data?.data)
    const LoanId = sessionStorage.getItem("loanid")
    const legacyLoanId = sessionStorage.getItem("legacyLoanId")
    const dispatch = useDispatch();
    const [loanDetails, setLoanDetails] = useState([]);
    const [emiHistoryData, setEmiHistory] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [totalEmiAmount, setTotalEmiAmount] = useState(0);
    const [interest, setInterest] = useState(0);
    const [overdueCharges, setOverdueCharges] = useState(0);
    const [chequeBounceCharges, setChequeBounceCharges] = useState(0);
    const [selectedId, setSelectedId] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [amount,setAmount] = useState(null)
    const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
    const [paymentLink,setPaymentLink] = useState(null)
    const [remarks,setRemarks] = useState(null)
    const [emiAmount,setEmiAmount] = useState(null)
    const [emiId,setEmiid] = useState(null)
    const [loanId, setloanId] = useState(null);

const urlUsers = `/loan-repayment/profile?loanId=${LoanId}`;

const callDetailsUrl = `/call-history/details?loanId=${LoanId}`

const emihistoryUrl=`/emis/for-loan?loanId=${LoanId}`
const urlGetReminder=`/call-history/reminder-details?userId=${userId}`

const customerDashboardLoginUrl = `/login/admin`;



const [selectedStatus, setSelectedStatus] = useState('');


const handleChange = (e) => {
  setSelectedStatus(e.target.value);
};







const handleCheckboxClick = (array,index) => {

    setSelectedItems([]);
    setTotalEmiAmount(0)
    setOverdueCharges(0)
    setInterest(0)
    setChequeBounceCharges(0)
    
    if(document.getElementById(`${index}`).checked==false)
    {

        for(let i=0;i<emiHistoryData?.length;i++)
        {
            document.getElementById(`${i}`).checked=false
            setSelectedItems([]);
            setTotalEmiAmount(0)
            setOverdueCharges(0)
            setChequeBounceCharges(0)
            setInterest(0)
        }
    }
    else
    {

        for(let i=index;i>=0;i--)
        {   
    
            if(array[i].status!=2 && array[i].status!=4 && array[i].status!=7)
            {
                
                document.getElementById(`${i}`).checked=true
                setSelectedItems((old)=>[...old,array[i].emiId]);
                setTotalEmiAmount((old)=>old+array[i].remainingAmountToBePaid)
                setOverdueCharges((old)=>old+array[i].overdueCharges)
                setInterest((old)=>old+array[i].interestAmount)
                setChequeBounceCharges((old)=>old+array[i].bounceCharges)
            }
        }
    }
    

     
    }
    // const newSelectedId = selectedItems.map((item) => item.emiId);
    // setSelectedId(newSelectedId);


    const [isEmiModalOpen, setIsEmiModalOpen] = useState(false);
    const statusPausedValue = loanstate?.status === 7 ? 1 : 7;
  


    const toggleEmiStatus = () => {
      setIsEmiModalOpen(!isEmiModalOpen);
     
    };
  
   
  
  
  
    const handlePausedClick = (statusPausedValue) => {
      console.log(selectedStatus,'Yes button clicked');
      
      const requestBody = {
        loanId:LoanId,
        newStatus: statusPausedValue
      };
   console.log(requestBody,'requestBody')
        
      axiosInstance.post(`/loans/update-paused-status`,requestBody,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res) => {
        console.log(res?.data, 'Response data');
        window.location.reload();
      }).catch((error) => {
        console.error('Error:', error.response ? error.response.data : error.message);
      });
    }
   





 
 

  const [reminders,setReminders]=React.useState(null)
  const [openmodal, setOpneModal] = useState(false);
  const toggled = () => setOpneModal(!openmodal);
  const [isModalOpencheck, setIsModalOpencheck] = useState(false);
 


  const toggleModalcheck = () => {
    setIsModalOpencheck(!isModalOpencheck);
  };

  const isStatusDisabled = (statusKey) => {
    const statusValue = parseInt(statusKey);
   
    if (loanstate?.status !== 8) {
      return statusValue !== 8; 
    }
    return statusValue === 8; 
  };
 

  const handleYesClick = () => {
    console.log(selectedStatus,'Yes button clicked');
    
   
    const requestBody = {
      loanId:LoanId,
      newStatus: parseInt(selectedStatus, 10)
    };
 console.log(requestBody,'requestBody')
     
    axiosInstance.post(`/loans/update-loan-status`,requestBody,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res) => {
      console.log(res?.data, 'Response data');
      window.location.reload();
    }).catch((error) => {
      console.error('Error:', error.response ? error.response.data : error.message);
    });
  }
   
  
const [remainingAmount,setRemainingAmount] = useState(0)
const [remainingInterest,setRemainingInterest] = useState(0)
const [remainingPenalty,setRemainingPenalty] = useState(0)
const [remainingBounceCharges,setRemainingBounceCharges] = useState(0)


useEffect(() => {
    if(loanstate){
    setLoanDetails(loanstate)
     
    }else{
        dispatch(userActions.fetchLoanDetails(urlUsers, user),[])
    }
    if(callstate){
        setTableData(callstate)
         
    }else{
            dispatch(userActions.fetchCallDetails(callDetailsUrl, user),[])
    }

    if(getReminderState)
    {
        setReminders(getReminderState)
    }
    else
    {
        dispatch(userActions.getReminder(urlGetReminder))
    }
    
    
  if(emiHistoryState)
  {
    console.log("emihistory",emiHistoryState)
    const totalRemainingAmount = emiHistoryState?.reduce(
      (acc, item) => acc + (parseFloat(item?.remainingAmountToBePaid) || 0),
      0
    );  
    setRemainingAmount(totalRemainingAmount)
    const totalRemainingInterest = emiHistoryState?.reduce(
      (acc, item) => {
        if (![2, 4, 7].includes(item?.status)) {
          return acc + (parseFloat(item?.interestAmount) || 0);
        }
        return acc; 
      },
      0 
    ); 
    setRemainingInterest(totalRemainingInterest)
    const totalRemainingBounceCharges = emiHistoryState?.reduce(
      (acc, item) => {
        if (![2, 4, 7].includes(item?.status)) {
          return acc + (parseFloat(item?.bounceCharges) || 0);
        }
        return acc; 
      },
      0 
    ); 
    setRemainingBounceCharges(totalRemainingBounceCharges)
    const totalRemainingPenalty = emiHistoryState?.reduce(
      (acc, item) => {
        if (![2, 4, 7].includes(item?.status)) {
          return acc + (parseFloat(item?.overdueCharges) || 0);
        }
        return acc; 
      },
      0 
    ); 

    setRemainingPenalty(totalRemainingPenalty)
     console.log("emihistory",totalRemainingAmount)
    setEmiHistory(emiHistoryState)
  }
  else
  {
    dispatch(userActions.getEmiHistory(emihistoryUrl,user))
  }
  }, [loanstate,urlUsers,callstate,emiHistoryState,getReminderState]);
  console.log("loandetails",emiHistoryState)
    

   
   const EmiStatus = {
    Unpaid: 1,
    Paid: 2,
    PartiallyPaid: 3,
    OverduePayment: 4,
    Overdue: 5
}
let totalCount = 0;
let countStatus1 = 0;
let countStatus2 = 0;
if (emiHistoryData) {
    emiHistoryData.forEach((item) => {
      totalCount++;
      if (item.status === 1 ) {
        countStatus1++;
      } else if (item.status === 2 || item.status === 4) {
        countStatus2++;
      }
    });
  }
  const [popUp, setpopUp] = useState(false);
   const handleMobileDetails=()=>{
    setpopUp(!popUp);
    
  }
  const toggleModal = (item) => {
    setIsModalOpen(!isModalOpen);
    setAmount(item?.emiAmount)
    setEmiid(item?.emiId)

  };
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }

  const  togglePaymentLinkModal = ()=>{
    setShowPaymentLinkModal(!showPaymentLinkModal)
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(paymentLink);
    alert('Payment link copied to clipboard!');
  };
  const generatelink = ()=>{
    if(remarks === null){
      alert("Please Enter Remarks")
    }else{
      const data = {
        loanId:LoanId,
        amount:amount,
        reason:1,
        emiIds:[emiId],
        remarks:remarks,
      };
      const createUrl = `/loan-repayment/payment-link`;
  
      axiosInstance.post(createUrl,data,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
        console.log("paymentlink",res?.data?.data)
        setPaymentLink(res?.data?.data)
        setShowPaymentLinkModal(true);
      })
    }
    
  }
  const [upiMandateModal, setUpiMandateModal] = useState(false);
  const [MandateModal, setMandateModal] = useState(false);


  const toggleMandateDetails = () => setMandateModal(!MandateModal);
  const [isOpen,setIsOpen]=useState(true)

  const [applicantChecked, setApplicantChecked] = useState(false);
  const [coApplicantChecked, setCoApplicantChecked] = useState(false);

  const handleApplicantCheckboxChange = () => {
    setApplicantChecked(true);
    setCoApplicantChecked(false);
  };

  const handleCoApplicantCheckboxChange = () => {
    setApplicantChecked(false);
    setCoApplicantChecked(true);
  };
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const updateMandate =()=>{
    const data = {
      loanId:LoanId,
      mandatePrimary:applicantChecked ? "1":"2",
    
    };
    const updateUrl = `/loans/update-mandate`;
  
      axiosInstance.post(updateUrl,data,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
        console.log("paymentlink",res?.data?.message)
        if(res?.data?.message === "Successful"){
          alert("updated successfully")
          window.location.reload()
        }
    
      }).catch((error)=>{
        console.log("error",error)
        alert(error?.response?.data?.message)
      })
  }

  const openCustomerDashboard = (mobile) => {
    dispatch(
      userActions.openCustomerDashboard(customerDashboardLoginUrl, user, mobile)
    );
  };


  const handleUser = () => {
   console.log("loandetails",loanDetails)

    const Id = loanDetails.userId;
    sessionStorage.setItem('repaymentsUserId',Id)
    sessionStorage.setItem('repaymentsAppId',loanDetails.applicationId)
   
    const urlProfile = `/summary/user?id=${Id}&applicationId=${loanDetails.applicationId}&status=Disbursed`;
    const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${loanDetails.applicationId}&status=Disbursed`;
  const digilockerUrl=`/digilocker/details?applicationId=${loanDetails.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${loanDetails.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${loanDetails.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${loanDetails.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${loanDetails.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${loanDetails.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user));
      const getFoirRefUrl=`/admin/application/additional-info?applicationId=${loanDetails.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
    dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));


    window.open(`/status/disbursed/profile`,"_blank")

    
    
  };
  console.log(decode?.role,)   

    return(
        <div style={{  backgroundColor:"white"}}>
        <div style={{ display:'flex',flexDirection:'row',width:"100%",height:'100%',backgroundColor:"white"}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'78%':'98%', overflow:"auto",marginLeft:isOpen?"21%":'0%'}}>
                <Topbar/>
                <div style={{marginTop:'30px'}}>
                    <p><img src={loanactive}/><span style={{marginLeft:'10px',color:'#667085',fontFamily:'Inter-Medium',fontSize:'15px'}}>Loans</span><span style={{marginLeft:'10px'}}><img src={chevronright}/></span><span style={{marginLeft:'10px',color:'#D32028',fontWeight:'600',fontSize:'14px'}}>{legacyLoanId}</span></p>
                </div>
                <div>
                    <div style={{marginTop:'30px',display:'flex',justifyContent:'space-between'}}>
                        <div style={{borderRadius:'10px',borderStyle:'solid',width:'60%',boxShadow:'0px 0px 3px 0px #00000026',borderColor:'#DBDBDB',borderWidth:'1px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingTop:'10px',paddingLeft:'15px',paddingRight:'10px'}}>
                        <div style={{ display: "flex" }}>
                    <img
                      src={loanDetails?.selfieUrl ?? USER}
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "50%",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "20px",
                      }}
                    > <div style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                       }}>
                      <p
                        style={{
                          margin: "0",
                          color: "#D32028",
                          fontWeight: "600",
                          fontSize: "20px",cursor:'pointer'
                        }}  onClick={() => handleUser()}
                      >
                        {loanDetails.firstName + " " + loanDetails.lastName}
                      </p>

                      
                      <Modal isOpen={isEmiModalOpen} toggle={toggleEmiStatus}>
                                <ModalHeader toggle={toggleEmiStatus} style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left' }}>
                                  <h5 className="w-100 text-left">Update Loan Status</h5></ModalHeader>
                                  
                                  <div style={{paddingLeft:'30px'}}>
                                  { loanstate?.status === 7 ?  "Do you want to move the status to Active?" : "Do you want to move the status to Pause?"}

                                    <div style={{marginTop:'20px'}}>
                                      
                                        <label for="remarks">Notes:</label>
                                        <input type="text" id="remarks" name="remarks" placeholder=" Notes" />

                                     </div>

                                  </div>
                               
                            <div style={{ display: 'flex', justifyContent:'center', marginTop:'20px'}}>
                              <div style={{marginRight:'20px'}}>
                              <Button onClick={() => handlePausedClick(statusPausedValue)} style={{ width: '100%', borderRadius: '1em', backgroundColor: '#D32028', color: 'white', marginBottom: '1em' }}>
                               Confirm
                              </Button>
                              </div>
                              <div  style={{marginLeft:'20px'}}>
                              <Button onClick={toggleEmiStatus} style={{ width: '100%', borderRadius: '1em', backgroundColor: '#D32028', color: 'white', marginBottom: '1em' }}>
                                Cancel
                              </Button>
                              </div>
                            </div>
                         
                                </Modal>

                      




                      {(decode.role === "finance" ||  decode.role === "admin")&&(
                      <span
                        onClick={toggleEmiStatus}
                        className="customerDashboardButton"
                        style={{
                          margin: "0px",
                        }}
                      >
                        [ {loanstate?.status===7?"UnPause":"Pause"}  ]
                      </span>  )}</div>
                      <span
                           onClick={() => {
                            openCustomerDashboard(loanDetails?.mobile);
                          }}
                        className="customerDashboardButton"
                        style={{
                          margin: "0px",
                        }}
                      >
                        [ Preview Customer Dashboard ]
                      </span>

                       <div   style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                               }}>
                      <span
                      
                       
                        style={{
                          fontWeight: "normal",
                          color: "#FFA500",
                          textDecoration: "underline",
                          marginLeft: "10px",
                          margin: "0px",
                          marginRight: "20px", 
                        }}
                      >
                        [ {loanstate?.status === 1 ? 'Active' :
                          loanstate?.status === 2 ? 'Closed' :
                          loanstate?.status === 3 ? 'Overdue' :
                          loanstate?.status === 4 ? 'WrittenOff' :
                          loanstate?.status === 5 ? 'MandateActive' :
                          loanstate?.status === 6 ? 'Refund' :
                          loanstate?.status === 7 ? 'Paused' :
                          loanstate?.status === 8 ? 'Legal' :
                                        'Unknown'}]
                      </span>



                     
      
                      {(decode.role === "finance" ||  decode.role === "admin")&&(
                                <Button
                                  onClick={toggled}
                                  style={{
                                    backgroundColor: '#d32028',
                                    color: 'white', 
                                    padding: '8px 16px',
                                    whiteSpace: 'nowrap',    
                                    marginBottom:'5px'
                                  }}
                                  disabled={loanstate?.status === 2  || loanstate?.status === 6 }
                                >
                                  {loanstate?.status === 8 ? "Remove legal" : "Move to Legal"}
                                </Button> 
                                )}
                              <Modal isOpen={openmodal} toggle={toggled}>
                              <ModalHeader toggle={toggled} style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left' }}>
                                  <h5 className="w-100 text-left">Update Loan Status</h5>
                                </ModalHeader>
                              <ModalBody>
                                {/* Display Current Status */}
                                <div style={{ fontSize: '16px', marginBottom: '15px' }}>
                                  <strong>Current Status: </strong>
                                  {loanstate?.status === 1 ? 'Active' :
                                    loanstate?.status === 2 ? 'Closed' :
                                    loanstate?.status === 3 ? 'Overdue' :
                                    loanstate?.status === 4 ? 'WrittenOff' :
                                    loanstate?.status === 5 ? 'MandateActive' :
                                    loanstate?.status === 6 ? 'Refund' :
                                    loanstate?.status === 7 ? 'Paused' :
                                    loanstate?.status === 8 ? 'Legal' :
                                    loanstate?.status}
                                  </div>

                                {/* Loan Status Dropdown */}
                                <div>
                                  <label htmlFor="loanStatus">New Loan Status: </label>
                                  <select id="loanStatus" value={selectedStatus} onChange={handleChange} style={{ marginLeft: '10px' }}>
                                    <option value="" disabled>Select Loan Status</option>
                                    {Object.entries(loanStatusOptions).map(([key, value]) => (
                                      <option key={key} value={key} disabled={isStatusDisabled(key)}>
                                        {value}
                                      </option>
                                    ))}
                                  </select>

                                  <div style={{marginTop:'20px'}}>
                                      
                                      <label for="remarks">Notes:</label>
                                      <input type="text" id="remarks" name="remarks" placeholder=" Notes" />

                                   </div>

                                </div>

                                
                              </ModalBody>

                              <ModalFooter className="justify-content-center">
                                        
                                <Button color="primary" onClick={handleYesClick} disabled={loanstate?.status === 2  || loanstate?.status === 6 || !selectedStatus} className="mx-2">
                                  Submit
                                </Button>
                              </ModalFooter>
                            </Modal> 
                          
                    </div>



                    </div>
                  </div>
                            <div style={{display:'flex',alignItems:'center'}}>
                            {popUp && (
        <>
         <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
        <Modal style={{
      width:"500px",
      left:"0%",
      top:"30%",width:'400px',borderRadius:"10px"}} isOpen={popUp}>
       
          <Form>
            <FormGroup>
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'20px',paddingLeft:'20px'}}>
              <p style={{display:'flex',alignItems:'center'}}><span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'500'}}>Mobile :</span><span style={{fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'500',color:'black',marginLeft:'20px'}}>{loanDetails.mobile}</span> </p>
              <p style={{marginRight:'20px',cursor:"pointer",marginBottom:'30px'}}><img onClick={() => setpopUp(!popUp)} src={close} alt="close"/></p>
              </div>
            </FormGroup>
          </Form>
       
        
      </Modal>
        </>
      )}

      <Modal isOpen={MandateModal}>
        <ModalHeader toggle={toggleMandateDetails}>Mandate Details</ModalHeader>
        <ModalBody>
          {loanDetails?.mandateDetails?.type === "Digio" ? (
            <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%'}}>
        <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Appl.</span>
                    
                  <span 
                        
                        style={{
                          
                          textDecoration: 'none',
                          color: '#232323',
                          fontSize: '13px',
                          fontFamily: 'Inter-Medium',
                          lineHeight:"30px",
                          display: 'block',
                          width: '100%',
                          wordBreak: 'break-word'
                        }}>{loanDetails.firstName + " " + loanDetails.lastName}</span> 
                  </p>
        </div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Type</span>
                    
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.type}</span> 
                  </p>
        </div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                    
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.accountNumber}</span> 
                  </p>
        </div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Ifsc</span>
                    
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.ifsc}</span> 
                  </p>
        </div>
        <div style={{width:'100%',height:'60px', marginBottom: '10px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>vpa</span>
                    
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.vpa}</span> 
                  </p>
        </div>
        <div style={{width:'100%',height:'60px', marginBottom: '10px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>umrn</span>
                    
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.umrn}</span> 
                  </p>
        </div>
       
        </div>
          ):(
            <>

          
        <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%',backgroundColor: loanDetails?.mandatePrimary === 1 ? '#FFEDEE' : '#DBDBDB' ,borderStyle:'solid',borderWidth:'1px',borderRadius:'10px',borderColor:loanDetails?.mandatePrimary === 1 ? '#D32028' : '#DBDBDB'}}>
         <div style={{height:'60px',padding:'20px',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}> 
                  <p style={{color:'#000000',fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'18px',paddingTop:'15px'}}>
                  Applicant
                  </p>
                {loanDetails?.mandatePrimary === 1 ? <p style={{backgroundColor:'#ECFDF3',borderRadius:'18px',paddingLeft:'10px',paddingRight:'10px',height:'30px',display:'flex',alignItems:'center',marginTop:'15px'}}><img src={activedot}/><span style={{color:'#12B76A',marginLeft:'5px'}}>Primary</span></p>:null}  
        </div>
        <div style={{ borderTop:loanDetails?.mandatePrimary === 1? '1px solid #D32028':'1px solid gray', width: '100%'}}></div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:loanDetails?.mandatePrimary === 1 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Appl.</span>
                    
                  <span 
                        
                        style={{
                          
                          textDecoration: 'none',
                          color: '#000000',
                          fontSize: '13px',
                          fontFamily: 'Inter-Medium',
                          lineHeight:"30px",
                          display: 'block',
                          width: '100%',
                          wordBreak: 'break-word'
                        }}>{loanDetails.firstName + " " + loanDetails.lastName}</span> 
                  </p>
        </div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:loanDetails?.mandatePrimary === 1 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Type</span>
                    
                  <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.type}</span> 
                  </p>
        </div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:loanDetails?.mandatePrimary === 1 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                    
                  <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.accountNumber}</span> 
                  </p>
        </div>
        <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:loanDetails?.mandatePrimary === 1 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Ifsc</span>
                    
                  <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.ifsc}</span> 
                  </p>
        </div>
        <div style={{width:'100%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:loanDetails?.mandatePrimary === 1 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>customerId</span>
                    
                  <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.customerId}</span> 
                  </p>
        </div>
        <div style={{width:'100%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                  <p style={{display:'flex',flexDirection:"column"}}>
                    <span style={{color:loanDetails?.mandatePrimary === 1 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>tokenId</span>
                  <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.mandateDetails?.tokenId}</span> 
                  </p>
        </div>
        </div>
         {loanDetails?.coapplicantMandateDetails ? 
           <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%',backgroundColor: loanDetails?.mandatePrimary === 2 ? '#FFEDEE' : '#DBDBDB',borderStyle:'solid',borderWidth:'1px',borderRadius:'10px',borderColor:loanDetails?.mandatePrimary === 2 ? '#D32028' : '#DBDBDB',marginTop:'20px' }}>
           <div style={{height:'60px',padding:'20px',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}> 
                     <p style={{color:'#000000',fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'18px',paddingTop:'15px'}}>
                    Co-Applicant
                     </p>
                   {loanDetails?.mandatePrimary === 2 ? <p style={{backgroundColor:'#ECFDF3',borderRadius:'18px',paddingLeft:'10px',paddingRight:'10px',height:'30px',display:'flex',alignItems:'center',marginTop:'15px'}}><img src={activedot}/><span style={{color:'#12B76A',marginLeft:'5px'}}>Primary</span></p>:null}  
           </div>
           <div style={{ borderTop: loanDetails?.mandatePrimary === 2? '1px solid #D32028':'1px solid gray', width: '100%'}}></div>
           <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                     <p style={{display:'flex',flexDirection:"column"}}>
                       <span style={{color:loanDetails?.mandatePrimary === 2 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Appl.</span>
                       
                     <span 
                           
                           style={{
                             
                             textDecoration: 'none',
                             color: '#000000',
                             fontSize: '13px',
                             fontFamily: 'Inter-Medium',
                             lineHeight:"30px",
                             display: 'block',
                             width: '100%',
                             wordBreak: 'break-word'
                           }}>{loanDetails.firstName + " " + loanDetails.lastName}</span> 
                     </p>
           </div>
           <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                     <p style={{display:'flex',flexDirection:"column"}}>
                       <span style={{color:loanDetails?.mandatePrimary === 2 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Type</span>
                       
                     <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.coapplicantMandateDetails?.type}</span> 
                     </p>
           </div>
           <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                     <p style={{display:'flex',flexDirection:"column"}}>
                       <span style={{color:loanDetails?.mandatePrimary === 2 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                       
                     <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.coapplicantMandateDetails?.accountNumber}</span> 
                     </p>
           </div>
           <div style={{width:'50%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                     <p style={{display:'flex',flexDirection:"column"}}>
                       <span style={{color:loanDetails?.mandatePrimary === 2 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Ifsc</span>
                       
                     <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.coapplicantMandateDetails?.ifsc}</span> 
                     </p>
           </div>
           <div style={{width:'100%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                     <p style={{display:'flex',flexDirection:"column"}}>
                       <span style={{color:loanDetails?.mandatePrimary === 2 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>customerId</span>
                       
                     <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.coapplicantMandateDetails?.customerId}</span> 
                     </p>
           </div>
           <div style={{width:'100%',height:'60px', marginBottom: '10px',padding:'20px'}}> 
                     <p style={{display:'flex',flexDirection:"column"}}>
                       <span style={{color:loanDetails?.mandatePrimary === 2 ?'#D32028':'#667085',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>tokenId</span>
                       
                     <span style={{color:"#000000",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{loanDetails?.coapplicantMandateDetails?.tokenId}</span> 
                     </p>
           </div>
           </div>:null
         }
      
            </>
        
       
          )}
          {loanDetails?.coapplicantMandateDetails ? 
          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <button style={{marginTop:'25px',borderRadius:'8px',backgroundColor:'#D32028',color:'white',fontFamily:'Inter',paddingLeft:'10px',paddingRight:'10px'}} onClick={toggle}>Update Primary Mandate</button>
        </div>:null
          }
          
          
        
        </ModalBody>
      </Modal>
      <Modal isOpen={modal}  centered>
        <ModalHeader toggle={toggle}>Choose Primary Mandate</ModalHeader>
        <ModalBody>
        <div style={{width:'50%',marginLeft:'-48px'}}> 
            <p style={{display:'flex',alignItems:'center',justifyContent:'center',lineHeight:"15px"}}>
              <Input type="checkbox"  checked={applicantChecked} onChange={handleApplicantCheckboxChange}/>
              <Label check style={{marginLeft:'10px',marginTop:"5px"}} >
               Applicant 
              </Label>
              </p>
        </div>
        <div style={{width:'50%',marginLeft:'-35px',marginTop:'20px',marginBottom:'20px'}}> 
            <p style={{display:'flex',alignItems:'center',justifyContent:'center',lineHeight:"15px"}}>
              <Input type="checkbox" checked={coApplicantChecked} onChange={handleCoApplicantCheckboxChange}/>
              <Label check style={{marginLeft:'10px',marginTop:"5px"}}>
                Co-Applicant
              </Label>
              </p>
        </div>
        </ModalBody>
        <ModalFooter style={{justifyContent:'space-between'}}>
          <Button onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button onClick={updateMandate} style={{backgroundColor:'#D32028',border:'none'}}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
        <img src={truecaller} alt='mobile' style={{cursor:'pointer'}} onClick={()=>handleMobileDetails()}/>
                                
                                <a href={`https://wa.me/+91${loanDetails.mobile}`} target="_blank" rel="noreferrer">
                                <img src={whatsapp} style={{ paddingLeft: '15px' }} />
                                </a>
                                <a href={`mailto:${loanDetails.email}`} target="_blank" rel="noreferrer">
                                    <img src={gmail} style={{ paddingLeft: '15px' }} />
                                </a>
                            </div>
                        </div>
                        <div style={{ borderTop: '1px solid #DBDBDB', width: '100%'}}></div>
                        <div style={{paddingTop:'20px',paddingBottom:'40px',paddingLeft:'20px',paddingRight:'20px'}}>
                        <div style={{display:'flex',marginTop:'20px'}}>
                            
                            <div style={{width:'50%',paddingLeft:'25px'}}>
                                <p style={{lineHeight:'1em',color:'#667085',fontFamily:'Inter-Medium',fontSize:'15px'}}>Work Type</p>
                                <p style={{lineHeight:'1em',color:'#232323',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.workType}</p>
                            </div>
                            <div style={{width:'50%',paddingLeft:'25px'}}>
                                <p style={{lineHeight:'1em',color:'#667085',fontFamily:'Inter-Medium',fontSize:'15px'}}>Office/Business Name</p>
                                <p style={{lineHeight:'1em',color:'#232323',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.businessName ? loanDetails.businessName : "-----"}</p>
                            </div>
                        </div>
                        <div style={{display:'flex',marginTop:'30px'}}>
                            <div style={{width:'50%',paddingLeft:'25px'}}>
                                <p style={{lineHeight:'1em',color:'#667085',fontFamily:'Inter-Medium',fontSize:'15px'}}>City</p>
                                <p style={{lineHeight:'1em',color:'#232323',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.city}</p>
                            </div>
                            <div style={{width:'50%',paddingLeft:'25px'}}>
                                <p style={{lineHeight:'1em',color:'#667085',fontFamily:'Inter-Medium',fontSize:'15px'}}>State</p>
                                <p style={{lineHeight:'1em',color:'#232323',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.state}</p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div style={{borderRadius:'10px',borderStyle:'solid',backgroundColor:'#ECF2FF',width:'38%',boxShadow:'0px 0px 3px 0px #00000026',borderColor:'#AEBBD7',borderWidth:'1px'}}>
                            <div style={{display:'flex',alignItems:'center',paddingTop:'15px',paddingLeft:'15px'}}>
                                <p><img src={documentIcon} style={{height:'25px'}}/><span style={{marginLeft:'10px',fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'600'}}>Documents</span></p>
                            </div>
                            <div style={{ borderTop: '1px solid #DBDBDB', width: '100%'}}></div>
                            <div  style={{paddingLeft:'20px'}}>
                            <ul>
                            <li style={{ paddingBottom: '10px', color: '#416CC7'  , fontFamily: 'Inter-Medium',cursor:'pointer' }}>
                            <div onClick={toggleMandateDetails}>Mandate Details</div>
                            </li> 
                            <div style={{ borderTop: '1px dashed #DBDBDB', width: '90%'}}></div>
                            <li style={{ paddingBottom: '10px', color:loanDetails?.agreementUrl ? '#416CC7' :' #DBDBDB' , fontFamily: 'Inter-Medium',cursor: loanDetails?.agreementUrl ? 'pointer' : 'not-allowed' }}>
                            <a disabled={loanDetails?.agreementUrl?false:true} href={loanDetails?.agreementUrl} target="_blank" rel="noreferrer">Loan Agreements</a>
                            </li> 
                                <div style={{ borderTop: '1px dashed #DBDBDB', width: '90%'}}></div>
                                <li style={{ paddingBottom: '10px', color:loanDetails?.sanctionLetterUrl ? '#416CC7' :' #DBDBDB' , fontFamily: 'Inter-Medium',cursor: loanDetails?.sanctionLetterUrl ? 'pointer' : 'not-allowed' }}>
                            <a disabled={loanDetails?.sanctionLetterUrl?false:true} href={loanDetails?.sanctionLetterUrl} target="_blank" rel="noreferrer">Sanction Letter</a>
                            </li> 
                                 
                                 <div style={{ borderTop: '1px dashed #DBDBDB', width: '90%'}}></div>
                                 <li style={{ paddingBottom: '10px', color:loanDetails?.noDueCertificateUrl ? '#416CC7' :' #DBDBDB' , fontFamily: 'Inter-Medium',cursor: loanDetails?.noDueCertificateUrl ? 'pointer' : 'not-allowed' }}>
                            <a disabled={loanDetails?.noDueCertificateUrl?false:true} href={loanDetails?.noDueCertificateUrl} target="_blank" rel="noreferrer">No Dues Certificate</a>
                            </li> 
                            <div style={{ borderTop: '1px dashed #DBDBDB', width: '90%'}}></div>
                            <li style={{ paddingBottom: '10px', color:loanDetails?.accountStatementUrl ? '#416CC7' :' #DBDBDB' , fontFamily: 'Inter-Medium',cursor: loanDetails?.accountStatementUrl ? 'pointer' : 'not-allowed' }}>
                            <a disabled={loanDetails?.accountStatementUrl?false:true} href={loanDetails?.accountStatementUrl} target="_blank" rel="noreferrer">Account Statement</a>
                            </li> 
                               
                                
                                <div style={{ borderTop: '1px dashed #DBDBDB', width: '90%'}}></div>
                                <li style={{ paddingBottom: '10px', color:loanDetails?.cancellationPolicyUrl ? '#416CC7' :' #DBDBDB' , fontFamily: 'Inter-Medium',cursor: loanDetails?.cancellationPolicyUrl ? 'pointer' : 'not-allowed' }}>
                            <a disabled={loanDetails?.cancellationPolicyUrl?false:true} href={loanDetails?.cancellationPolicyUrl} target="_blank" rel="noreferrer">Course Cancellation Policy</a>
                            </li> 
                             
                            </ul>
                            </div>
                         
                        </div>
                    </div>
                </div>
                <div style={{paddingLeft:'3%',paddingTop:'3%',marginTop:'20px',width:'100%',backgroundColor:'#F8F8F8',boxShadow:'0px 4px 6px 0px #00000026',borderStyle:'solid',borderRadius:'10px',borderColor:'#F8F8F8'}}>
                    <div style={{display:'flex',width:'100%'}}>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Institute Name</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.instituteName}</p>
                        </div>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Student Name</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.studentName ? loanDetails.studentName : "----"}</p>
                        </div>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Course Name</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>{loanDetails.courseName}</p>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px dashed #DBDBDB', width: '95%'}}></div>
                    <div style={{display:'flex',width:'100%',marginTop:'25px'}}>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Loan Amount</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>₹{formatNumberWithCommas(loanDetails.loanAmount) || 0}</p>
                        </div>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Disbursed Amount</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>₹{formatNumberWithCommas(loanDetails.disbursedAmount) || 0}</p>
                        </div>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>EMI</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>₹{formatNumberWithCommas(loanDetails.emi) || 0}</p>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px dashed #DBDBDB', width: '95%'}}></div>
                    <div style={{display:'flex',width:'100%',marginTop:'25px'}}>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Total Paid Amount</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>₹{formatNumberWithCommas(loanDetails.paidAmount) || 0}</p>
                        </div>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Total Outstanding</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>₹{formatNumberWithCommas(loanDetails.outstandingAmount) || 0}</p>
                        </div>
                        <div style={{width:'35%'}}>
                            <p style={{lineHeight:'1em',color:'#D32028',fontFamily:'Inter-Medium',fontSize:'15px'}}>Total Overdue</p>
                            <p style={{lineHeight:'2em',color:'#000000',fontFamily:'Inter-Medium',fontSize:'16px'}}>₹{formatNumberWithCommas(loanDetails.overdue) || 0}</p>
                        </div>
                    </div>
                    
                </div>
                <div>
  
     
    </div>




                <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>Payment Link</ModalHeader>
        <ModalBody >
        <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>LoanId</p>
        <InputGroup>
            <Input value={LoanId} disabled/>
          </InputGroup>
          
        <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'10px'}}>Amount</p>
        <InputGroup>
            <Input value={amount}/>
          </InputGroup>
          
          <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'10px'}}>Remarks</p>
        <InputGroup>
            <Input onChange={(e)=>setRemarks(e.target.value)}/>
          </InputGroup>
         
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <FormGroup check style={{marginTop:'10px'}}>
            <Label check >
               SMS
            </Label>
            <Input type="checkbox" style={{marginTop:'12px'}}/>{' '}
          </FormGroup>
          <FormGroup check style={{marginTop:'10px'}}>
            <Label check  >
               WhatsApp
            </Label>
            <Input type="checkbox" style={{marginTop:'12px'}}/>{' '}
          </FormGroup>
          <FormGroup check style={{marginTop:'10px'}}>
            <Label check  >
               Email
            </Label>
            <Input type="checkbox" style={{marginTop:'12px'}}/>{' '}
          </FormGroup>
          </div>
          
          
        </ModalBody>
        <Button style={{backgroundColor:'#D32028',border:'none',width:'25%',display:'flex',justifyContent:'center',marginLeft:'35%',marginBottom:'15px',marginTop:'10px',whiteSpace:'nowrap'}} onClick={generatelink}>
            Generate Link
          </Button>
        </Modal>
        <Modal isOpen={showPaymentLinkModal} toggle={togglePaymentLinkModal} backdrop="static" style={{top:'10%'}}>
        <ModalHeader toggle={togglePaymentLinkModal}>Payment Link</ModalHeader>
        <ModalBody>
          <p style={{fontFamily:'Inter-Medium'}}> {paymentLink}{" "}
          <span onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
              <img src={copy} style={{height:'20px',width:'20px',marginLeft:'50px'}} title='copy to clipboard'/>
            </span>
          </p>
        </ModalBody>
      </Modal>              
               <div style={{borderRadius:'10px',borderStyle:'solid', boxShadow:'0px 0px 3px 0px #00000026',borderColor:'#DBDBDB',borderWidth:'1px',marginTop:'2em'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingTop:'10px',paddingLeft:'15px',paddingRight:'10px'}}>
                            <div style={{display:'flex'}}>
                                <img src={emihistory} style={{height:'40px',width:'40px',borderRadius:'50%'}}/>
                                <p style={{paddingLeft:'20px',fontWeight:'600',fontSize:'20px',fontFamily:'Outfit'}}>EMI History <span style={{color:'#5F5F5F',marginLeft:'1em',fontFamily:'Outfit'}}>  {countStatus2}/{totalCount} EMIs Completed</span></p>
                            </div> 
                            
                            <div style={{display:'flex',alignItems:'center'}}>
                            
                            <Button color="primary" onClick={toggleModalcheck}>
                                     Balance Check
                              </Button>
     
                            <Modal isOpen={isModalOpencheck} toggle={toggleModalcheck}>
                            <ModalHeader toggle={toggleModalcheck}>Modal Title</ModalHeader>
                              <ModalBody>
                                 This is the content of the modal.
                            </ModalBody>
                          <ModalFooter>
                          <Button color="secondary" onClick={toggleModalcheck}>
                             Close
                         </Button>
                          </ModalFooter>
                          </Modal>

                              <PaymentLink selectedItems={selectedItems} totalEmiAmount={totalEmiAmount} overdueCharges={overdueCharges} chequeBounceCharges={chequeBounceCharges} loanDetails={loanDetails} interest={interest} style={{marginRight:'10px'}}/>
                              <ManualPayment totalEmiAmount={remainingAmount} selectedItems={selectedItems} overdueCharges={remainingPenalty} chequeBounceCharges={remainingBounceCharges} remainingInterest={remainingInterest} userId={loanDetails?.userId}/>

                              

                               
                            </div>
                        </div>
                        <div style={{ borderTop: '1px solid #DBDBDB', width: '100%'}}></div>
                        <div style={{paddingTop:'20px',paddingBottom:'40px', }}>
                            <ul style={{borderBottom:'2px solid #D0D0D0',listStyle:'none',display:'flex',flexDirection: 'row',justifyContent:'space-between'}}>
                                <li style={{width:'1em'}}></li>
                                <li style={{width:'1em',fontSize:'1vw',lineHeight:'1.4em',fontFamily:'Outfit',fontWeight:'bold'}}>EMI No.</li>
                                <li style={{textAlign:'center' ,fontFamily:'Outfit',fontSize:'1vw',width:'6em',lineHeight:'1.4em',fontWeight:'bold'}}>Due date</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>EMI</li>
                                <li style={{width:'4em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Principle</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Interest</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Bounce Charges</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Penalty</li>
                                <li style={{fontFamily:'Outfit',fontSize:'1vw',width:'6em',lineHeight:'1.4em',fontWeight:'bold',textAlign:'center'}}>Paid date</li>
                                <li style={{width:'3em',textAlign:'center',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Dpds</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Paid Amount</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Paid Principle</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Paid Interest</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Paid Bounces</li>
                                <li style={{width:'3em',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Paid Penalty</li>
                                <li style={{width:'5em',textAlign:'center',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Status</li>
                                {/* <li style={{width:'7em',textAlign:'center',lineHeight:'1.4em',fontFamily:'Outfit',fontSize:'1vw',fontWeight:'bold'}}>Actions</li> */}
                            </ul>
                            {emiHistoryData?.map((item,idx)=>{
                                const dueDate = item?.dueDate;
                                const emiRepaidDate = 
                                item?.emiRepaidDate && item?.emiRepaidDate !== "Invalid date" 
                                  ? item?.emiRepaidDate 
                                  : item?.emiRepaidDate === "Invalid date"
                                    ? new Date().toLocaleDateString("en-GB")
                                    : '-';
        
                                // Convert strings to Date objects
                                const dueDateObj = new Date(dueDate.split('/').reverse().join('-'));
                                const emiRepaidDateObj = new Date(emiRepaidDate.split('/').reverse().join('-'));
        
                                // Calculate the difference in time (in milliseconds)
                                const differenceInTime = emiRepaidDateObj - dueDateObj;
        
                                // Convert milliseconds to days
                                const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
                                const result = differenceInDays > 0 ? differenceInDays: '-';
                                return(
                            <ul style={{listStyle:'none',display:'flex',flexDirection: 'row',justifyContent:'space-between'}}>
                               <li style={{width:'1em', marginTop:'-10px'}}>
                                  <input 
                                    id={idx} 
                                    disabled={item?.status == 2 || item?.status == 4 || item?.status == 7} 
                                    type="checkbox" 
                                    onClick={() => handleCheckboxClick(emiHistoryData, idx)}  
                                  />
                                </li>
                                <li style={{width:'1em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>{idx + 1}</li>
                                <li style={{width:'6em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {item.dueDate ? item.dueDate.substr(0,10) : "--"}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.emiAmount !== null && item.emiAmount !== undefined ? item.emiAmount : "--")}
                                </li>
                                <li style={{width:'4em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.principleAmount !== null && item.principleAmount !== undefined ? item.principleAmount : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.interestAmount !== null && item.interestAmount !== undefined ? item.interestAmount : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.bounceCharges !== null && item.bounceCharges !== undefined ? item.bounceCharges : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.overdueCharges !== null && item.overdueCharges !== undefined ? item.overdueCharges : "--")}
                                </li>
                                <li style={{width:'6em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {item.emiRepaidDate ? item.emiRepaidDate === "Invalid date"?"--":item.emiRepaidDate.substr(0,10) : "--"}
                                </li>
                                <li style={{ width: '3em', lineHeight: '1.4em', fontSize: '13px', fontFamily: 'Outfit' }}> {(result)}</li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.repaidEmiAmount !== null && item.repaidEmiAmount !== undefined ? item.repaidEmiAmount : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.paidPrinciple !== null && item.paidPrinciple !== undefined ? item.paidPrinciple : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.paidInterest !== null && item.paidInterest !== undefined ? item.paidInterest : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.paidBounceCharges !== null && item.paidBounceCharges !== undefined ? item.paidBounceCharges : "--")}
                                </li>
                                <li style={{width:'3em', lineHeight:'1.4em', fontSize:'13px', fontFamily:'Outfit'}}>
                                  {formatNumberWithCommas(item.paidOverdue !== null && item.paidOverdue !== undefined ? item.paidOverdue : "--")}
                                </li>

                                <li style={{width:'5em',lineHeight:'1.4em',fontSize:'13px',fontFamily:'Outfit'}}>
                                {item.status?
                            item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={initiateddot}/>UnPaid</span>
                            :item.status==3?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px',whiteSpace:'nowrap'}}><img  src={pendingdot}/>PartlyPd.</span>
                            :item.status==2?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={activedot}/>Paid</span>
                            :item.status==4?<span style={{backgroundColor:'#8ab7ff',color:'black',borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px',whiteSpace:'nowrap'}}>OvdPd.</span>
                            :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={closeddot}/>Ovd</span>
                            :item.status==6?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={pendingdot}/>Paused</span>
                            :item.status==7?<span style={{backgroundColor:'#EDC7E2',color:'black', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px',whiteSpace:'nowrap'}}><img  src={pendingdot}/>Mand.In Prog</span>
                            :item.status
                            :'-'}
                                  </li>
                                {/* <li style={{display:'flex',justifyContent:'center',width:'7em',lineHeight:'1.4em',fontSize:'13px',fontFamily:'Outfit'}}>
                                    {/* <span style={{cursor:'pointer',}}><img style={{marginRight:'5px'}} src={filetext}/></span> */}
                                    {/* <span style={{cursor:'pointer',}}><img style={{marginRight:'5px'}} src={share_loan}/></span>
                                    <span
                                    style={{ cursor: item.status !== 2 ? 'pointer' : 'not-allowed' }}
                                    onClick={item.status !== 2 ? () => toggleModal(item): null}
                                    >
                                    <img style={{ marginRight: '5px' }} src={repayments_loan} />
                                    </span>
                                    <span style={{cursor:'pointer',}}><img style={{marginRight:'5px'}} src={info}/></span>
                                </li> */} 
                            </ul>
                            )
                            }
                            )}
                         
                        </div>
                        </div>
                        <div style={{borderStyle:'solid',borderWidth:'1px',marginTop:'30px',borderRadius:'10px',borderColor:'#DBDBDB',boxShadow:'0px 0px 3px 0px #00000026'}}>
                    <div style={{display:'flex',justifyContent:'space-between',paddingRight:'20px'}}>
                    <div style={{display:'flex',marginLeft:'10px',alignItems:'center',marginTop:'10px'}}>
                   <p style={{display:'flex',alignItems:'center',marginTop:'5px',paddingLeft:'10px'}}><img style={{height:'30px',width:'30px'}} src={callhistory}/>
                   <span style={{fontFamily:'Inter-Medium',fontSize:'14px',marginLeft:'10px',fontWeight:'700'}}>Call History</span>
                   </p> 
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                       
                    <AddCall/>
                </div>
                    </div>
                    <div style={{ borderTop: '1px solid #DBDBDB', width: '100%'}}></div>
                <table striped  style={{ width: '100%'}}>
                <thead>
                    <tr style={{backgroundColor:'#F9FAFB',height:'50px'}}>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'13px',color:'#667085',paddingLeft:'20px'  }}>Date and Time</th>
                    <th style={{textAlign:'left' ,fontFamily:'Inter-Medium',fontSize:'13px' ,color:'#667085'}}>Contact Name </th>
                    <th style={{textAlign:'left' ,fontFamily:'Inter-Medium',fontSize:'13px' ,color:'#667085'}}>Contact Number</th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'13px' ,color:'#667085' }}>Status</th>
                    <th style={{textAlign:'left' ,fontFamily:'Inter-Medium',fontSize:'13px' ,color:'#667085'}}>Call Remarks</th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'13px',color:'#667085' }}>Actions</th>
                    </tr>
                </thead>
                <td colSpan="16">
                     <hr style={{ margin: 0, borderTop: '1px solid #DBDBDB' }} />
                 </td>
                <tbody>
                    {tableData?.map((row,index) => 
                    (
                        
                        <>
                        
                    <tr key={row.id} style={{height:'60px'}}>
                        <td style={{ color:'#667085',fontFamily:'Inter-Medium',fontSize:'13px',paddingLeft:'20px' }}>{row?.date}</td>
                        <td style={{ color:'#667085',fontFamily:'Inter-Medium',fontSize:'13px'}}>{row?.name}</td>
                        <td style={{ color:'#667085',fontFamily:'Inter-Medium',fontSize:'13px' }}>{row.contact}</td>
                        <td style={{ color:'#667085',fontFamily:'Inter-Medium',fontSize:'13px' }}>{row.status}</td>
                        <td style={{ color:'#667085',fontFamily:'Inter-Medium',fontSize:'13px' }}>{row.remarks}</td>
                        <td ><AddReminder getReminderState={reminders} item={row}/></td>
                    </tr>
                    {index !== tableData.length - 1 && (
                            <tr>
                            <td colSpan="16">
                                <hr style={{ margin: 0, borderTop: '1px solid #ccc' }} />
                            </td>
                            </tr>
                        )}          </>
                    ))}
                </tbody>
                </table>
                </div>
            </div>
            

        </div>
        
                        </div>
    )
}
export default LoanProfile