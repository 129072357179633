import { Button, Card, CardBody } from 'reactstrap';
import loanicon from '../../assets/images/institute Dashboard Icons/loan icon.png'

const Facebook = ({instituteDetails}) => {
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }
  return (
    <Card  style={{marginTop:'20px',backgroundColor:'#7454f3',color:'white',borderRadius:'8px',border:'1px solid #d4d6d5'}}>
      <CardBody>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div className="stats">
          <h1 className="text-dark-white">{instituteDetails?.map((item) =>( item?.AVG_loanAmount? `₹${formatNumberWithCommas(Math.round(item?.AVG_loanAmount))}`: '-'))}</h1>
            <h6 className="text-dark-white mb-0">Avg Loanamount</h6>
            {/* <Button color='light' className="rounded-pill mt-3">Check list</Button> */}
          </div>
          <div style={{alignContent:'right'}}>
          <img 
                src={loanicon} 
                alt="Credit Score" 
                className="display-3 op-3 text-dark" 
                style={{ width: '50px', height: '50px' }} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Facebook;
